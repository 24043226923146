import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const menuData = {
  Snacks: [
    "Porotta",
    "Idli",
    "Dosa",
    "Chappathi",
    "Appam",
    "Idiyappam",
    "Wheat Porotta",
    "Puttu",
    "Khuboos",
    "Ari Pathiri",
    "Rumali Roti",
    "Nool Porotta",
  ],
  Chicken: [
    "Chicken Curry (FULL)",
    "Chicken Kolhapuri (FULL)",
    "Chicken Roast (FULL)",
    "Chicken Masala",
    "Chicken Perattu (FULL)",
    "Chicken Manchurian",
    "Pepper Chicken",
    "Butter Chicken",
    "Chilli Chicken (FULL)",
    "Ginger Chicken (FULL)",
    "Chicken Fry (FULL)",
    "Chicken Fry (HALF)",
    "Chicken 65",
  ],
  Beef: [
    "Beef Fry",
    "Beef Chilli",
    "Beef Roast",
    "Beef Dry Fry",
    "Beef Curry",
  ],
  Specials: [
    "Chicken Lollipop",
    "Dragon Chicken",
    "Schezwan Chicken",
    "Honey Glazed Chicken",
    "Lemon Chicken",
    "Chicken Winglet",
    "Hunan Chicken",
    "Chicken Lekhakha",
    "Chilli Garlic Fried Rice",
    "Veg Ball Manchurian",
  ],
  Rice: [
    "Chicken Biriyani",
    "Beef Biriyani",
    "Veg Biriyani",
    "Egg Biriyani",
    "Veg Fried Rice",
    "Chicken Fried Rice",
    "Egg Fried Rice",
    "Beef Fried Rice",
    "Mushroom Fried Rice",
    "Mutton Biriyani",
    "Mixed Fried Rice",
  ],
  "Veg Curry": [
    "Tomato Fry",
    "Green Peas",
    "Gobi Manchurian",
    "Chilli Gobi",
    "Paneer Butter Masala",
    "Paneer Chilli",
    "Mushroom Masala",
    "Mushroom Chilli",
    "Mushroom Butter Masala",
  ],
  Shawarma: [
    "Arabic Shawarma Roll",
    "Special Shawarma Plate",
    "Peri Peri Shawarma",
    "Rumali Shawarma",
    "Rumali Shawarma Plate",
    "Al Faham (QTR)",
    "Al Faham Pepper (FULL)",
    "Al Faham Pepper (HALF)",
    "Al Faham Pepper (QTR)",
    "Peri Peri Al Faham (FULL)",
    "Peri Peri Al Faham (QTR)",
    "Al Faham Honey (FULL)",
    "Al Faham Honey (HALF)",
    "Al Faham Honey (QTR)",
    "Masala Al Faham (FULL)",
    "Masala Al Faham (HALF)",
    "Masala Al Faham (QTR)",
  ],
};


const DynamicFoodSelector = ({ onTypeChange, onNameChange }) => {
  const [selectedType, setSelectedType] = useState("");
  const [names, setNames] = useState([]);

  useEffect(() => {
    if (selectedType) {
      setNames(menuData[selectedType] || []);
    } else {
      setNames([]);
    }
  }, [selectedType]);

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSelectedType(type);
    onTypeChange(type);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    onNameChange(name);
  };

  return (
    <>
      <Form.Group controlId="type">
        <Form.Label>Type</Form.Label>
        <Form.Control
          as="select"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <option value="">Select Type</option>
          {Object.keys(menuData).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          as="select"
          onChange={handleNameChange}
          disabled={!selectedType}
        >
          <option value="">Select Name</option>
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </>
  );
};

export default DynamicFoodSelector;
