import React from 'react'

function DateSort({style,onClick}) {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick}>
            <path d="M13.3332 9.33333H15.3332L12.6665 12L9.99984 9.33333H11.9998V0H13.3332V9.33333ZM5.33317 1.33333C2.75984 1.33333 0.666504 3.42 0.666504 6C0.666504 8.58 2.75317 10.6667 5.33317 10.6667C7.9065 10.6667 9.99984 8.58 9.99984 6C9.99984 3.42 7.91317 1.33333 5.33317 1.33333ZM5.33317 2.76667C7.11317 2.76667 8.5665 4.21333 8.5665 6C8.5665 7.78667 7.11984 9.23333 5.33317 9.23333C3.5465 9.23333 2.09984 7.78667 2.09984 6C2.09984 4.21333 3.5465 2.76667 5.33317 2.76667ZM4.6665 4V6.46L6.79317 7.68667L7.29317 6.82L5.6665 5.88V4" fill="#2F2C5D" />
        </svg>

    )
}

export default DateSort