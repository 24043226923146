import React, { useEffect, useState } from 'react'
import { Colors } from '../../Colors';

const SelectorItems = ['Last Day', 'LastWeek', 'Last Month'];


export default function Selector({ inlineStyle, selectorValue, active, setActive }) {
    const styles = {
        container: {
            display: "flex",
            justifyContent: 'space-between',
            flex: 1,
            border: `2px solid ${Colors.Primary}`,
            borderRadius: '0.5rem',
            height: '3rem'
        },
        itemStyle: {
            padding: '0.5rem',
            flex: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
        },
        itemText: {
            fontSize: "70%",
        }
    }

    const clickHandler = (index) => {

        for (var i = 0; i < SelectorItems.length; i++) {
            if (i === index) {
                setActive(i)
            }
        }
    }
   
    useEffect(() => {
        selectorValue(active)
    }, [active])
    return (
        <div style={{ ...styles.container, ...inlineStyle }} >
            {SelectorItems.map((item, index) => (
                <div
                    key={index}
                    style={{ ...styles.itemStyle, ...{ background: active === index ? Colors.LinearGradient : "" } }}
                    onClick={() => clickHandler(index)}>
                    <span style={styles.itemText}>
                        {item}
                    </span>
                </div>
            ))}  

        </div>
    )
}
