import React from 'react'

function Appstore() {
    return (
        <svg width="137" height="40" viewBox="0 0 137 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:"pointer"}}>
            <path d="M131.661 40H5.26403C2.63772 40 0.5 37.872 0.5 35.267V4.726C0.5 2.12 2.63772 0 5.26403 0H131.661C134.287 0 136.5 2.12 136.5 4.726V35.267C136.5 37.872 134.287 40 131.661 40Z" fill="#A6A6A6" />
            <path d="M135.525 35.2679C135.525 37.3839 133.798 39.0979 131.662 39.0979H5.26403C3.12933 39.0979 1.39659 37.3839 1.39659 35.2679V4.72489C1.39659 2.60989 3.12933 0.889893 5.26403 0.889893H131.661C133.798 0.889893 135.524 2.60989 135.524 4.72489L135.525 35.2679Z" fill="black" />
            <path d="M30.8512 19.784C30.822 16.561 33.5097 14.993 33.6326 14.92C32.1104 12.717 29.7511 12.416 28.922 12.392C26.9404 12.185 25.0183 13.569 24.0089 13.569C22.9793 13.569 21.4249 12.412 19.7495 12.446C17.5937 12.479 15.5769 13.718 14.4707 15.642C12.1879 19.565 13.8905 25.33 16.0775 28.501C17.1716 30.054 18.45 31.788 20.1233 31.727C21.7603 31.66 22.3718 30.691 24.3473 30.691C26.3047 30.691 26.879 31.727 28.5855 31.688C30.3424 31.66 31.4486 30.128 32.5043 28.561C33.7686 26.781 34.2764 25.028 34.2965 24.938C34.2552 24.924 30.8844 23.647 30.8512 19.784Z" fill="white" />
            <path d="M27.6275 10.306C28.5079 9.21304 29.1104 7.72604 28.9431 6.21704C27.6688 6.27304 26.0751 7.09204 25.1573 8.16104C24.3453 9.10304 23.62 10.647 23.8074 12.099C25.2389 12.205 26.7087 11.382 27.6275 10.306Z" fill="white" />
            <path d="M54.5424 31.504H52.2546L51.0013 27.595H46.6453L45.4515 31.504H43.2242L47.5399 18.196H50.2055L54.5424 31.504ZM50.6236 25.955L49.4902 22.48C49.3703 22.125 49.1457 21.289 48.8143 19.973H48.774C48.642 20.539 48.4294 21.375 48.1373 22.48L47.0241 25.955H50.6236Z" fill="white" />
            <path d="M65.641 26.5879C65.641 28.2199 65.1967 29.5099 64.3082 30.4569C63.5123 31.2999 62.5241 31.7209 61.3444 31.7209C60.071 31.7209 59.1563 31.2669 58.5992 30.3589H58.5589V35.4139H56.4111V25.0669C56.4111 24.0409 56.3839 22.9879 56.3315 21.9079H58.2204L58.3403 23.4289H58.3806C59.0969 22.2829 60.1839 21.7109 61.6426 21.7109C62.783 21.7109 63.735 22.1579 64.4966 23.0529C65.2602 23.9489 65.641 25.1269 65.641 26.5879ZM63.4529 26.6659C63.4529 25.7319 63.2413 24.9619 62.8162 24.3559C62.3518 23.7239 61.7282 23.4079 60.9465 23.4079C60.4166 23.4079 59.935 23.5839 59.5049 23.9309C59.0737 24.2809 58.7916 24.7379 58.6596 25.3039C58.5932 25.5679 58.5599 25.7839 58.5599 25.9539V27.5539C58.5599 28.2519 58.7755 28.8409 59.2067 29.3219C59.6378 29.8029 60.198 30.0429 60.887 30.0429C61.696 30.0429 62.3256 29.7329 62.7759 29.1149C63.2272 28.4959 63.4529 27.6799 63.4529 26.6659Z" fill="white" />
            <path d="M76.7597 26.5879C76.7597 28.2199 76.3155 29.5099 75.4259 30.4569C74.6311 31.2999 73.6428 31.7209 72.4631 31.7209C71.1898 31.7209 70.2751 31.2669 69.719 30.3589H69.6787V35.4139H67.5309V25.0669C67.5309 24.0409 67.5037 22.9879 67.4513 21.9079H69.3402L69.4601 23.4289H69.5004C70.2156 22.2829 71.3026 21.7109 72.7623 21.7109C73.9017 21.7109 74.8537 22.1579 75.6173 23.0529C76.3779 23.9489 76.7597 25.1269 76.7597 26.5879ZM74.5717 26.6659C74.5717 25.7319 74.3591 24.9619 73.934 24.3559C73.4695 23.7239 72.848 23.4079 72.0652 23.4079C71.5343 23.4079 71.0538 23.5839 70.6226 23.9309C70.1914 24.2809 69.9104 24.7379 69.7784 25.3039C69.7129 25.5679 69.6787 25.7839 69.6787 25.9539V27.5539C69.6787 28.2519 69.8943 28.8409 70.3234 29.3219C70.7546 29.8019 71.3147 30.0429 72.0058 30.0429C72.8147 30.0429 73.4444 29.7329 73.8947 29.1149C74.346 28.4959 74.5717 27.6799 74.5717 26.6659Z" fill="white" />
            <path d="M89.1912 27.772C89.1912 28.904 88.7952 29.825 88.0004 30.536C87.127 31.313 85.911 31.701 84.3485 31.701C82.9059 31.701 81.7494 31.425 80.874 30.872L81.3717 29.095C82.3146 29.661 83.3492 29.945 84.4765 29.945C85.2854 29.945 85.9151 29.763 86.3674 29.401C86.8177 29.039 87.0423 28.553 87.0423 27.947C87.0423 27.407 86.857 26.952 86.4853 26.583C86.1155 26.214 85.498 25.871 84.6357 25.554C82.2884 24.685 81.1158 23.412 81.1158 21.738C81.1158 20.644 81.5268 19.747 82.3498 19.049C83.1699 18.35 84.2639 18.001 85.632 18.001C86.8519 18.001 87.8654 18.212 88.6744 18.633L88.1374 20.371C87.3818 19.963 86.5276 19.759 85.5715 19.759C84.816 19.759 84.2256 19.944 83.8025 20.312C83.4449 20.641 83.2656 21.042 83.2656 21.517C83.2656 22.043 83.4701 22.478 83.8811 22.82C84.2387 23.136 84.8885 23.478 85.8314 23.847C86.9849 24.308 87.8322 24.847 88.3772 25.465C88.9202 26.081 89.1912 26.852 89.1912 27.772Z" fill="white" />
            <path d="M96.2924 23.5081H93.925V28.1671C93.925 29.3521 94.342 29.9441 95.1782 29.9441C95.562 29.9441 95.8803 29.9111 96.1322 29.8451L96.1916 31.4641C95.7685 31.6211 95.2114 31.7001 94.5213 31.7001C93.6731 31.7001 93.0102 31.4431 92.5317 30.9301C92.0552 30.4161 91.8154 29.5541 91.8154 28.3431V23.5061H90.4051V21.9061H91.8154V20.1491L93.925 19.5171V21.9061H96.2924V23.5081Z" fill="white" />
            <path d="M106.974 26.6269C106.974 28.1019 106.549 29.3129 105.701 30.2599C104.811 31.2349 103.63 31.7209 102.158 31.7209C100.74 31.7209 99.6108 31.2539 98.7686 30.3199C97.9264 29.3859 97.5053 28.2069 97.5053 26.7859C97.5053 25.2989 97.9385 24.0809 98.8079 23.1339C99.6752 22.1859 100.846 21.7119 102.318 21.7119C103.736 21.7119 104.877 22.1789 105.739 23.1139C106.563 24.0209 106.974 25.1919 106.974 26.6269ZM104.746 26.6959C104.746 25.8109 104.555 25.0519 104.169 24.4189C103.719 23.6529 103.075 23.2709 102.241 23.2709C101.378 23.2709 100.722 23.6539 100.272 24.4189C99.8858 25.0529 99.6954 25.8239 99.6954 26.7359C99.6954 27.6209 99.8858 28.3799 100.272 29.0119C100.736 29.7779 101.385 30.1599 102.222 30.1599C103.042 30.1599 103.686 29.7699 104.15 28.9919C104.546 28.3469 104.746 27.5799 104.746 26.6959Z" fill="white" />
            <path d="M113.955 23.7829C113.743 23.7439 113.516 23.7239 113.278 23.7239C112.523 23.7239 111.938 24.0069 111.527 24.5739C111.17 25.0739 110.99 25.7059 110.99 26.4689V31.5039H108.844L108.864 24.9299C108.864 23.8239 108.837 22.8169 108.783 21.9089H110.654L110.733 23.7449H110.792C111.019 23.1139 111.376 22.6059 111.866 22.2249C112.344 21.8819 112.861 21.7109 113.418 21.7109C113.617 21.7109 113.796 21.7249 113.955 21.7499V23.7829Z" fill="white" />
            <path d="M123.561 26.2519C123.561 26.6339 123.536 26.9559 123.482 27.2189H117.039C117.064 28.1669 117.375 28.8919 117.974 29.3919C118.517 29.8389 119.219 30.0629 120.081 30.0629C121.035 30.0629 121.906 29.9119 122.688 29.6089L123.025 31.0889C122.11 31.4849 121.03 31.6819 119.784 31.6819C118.285 31.6819 117.108 31.2439 116.252 30.3689C115.398 29.4939 114.97 28.3189 114.97 26.8449C114.97 25.3979 115.368 24.1929 116.164 23.2319C116.999 22.2059 118.126 21.6929 119.544 21.6929C120.938 21.6929 121.992 22.2059 122.709 23.2319C123.276 24.0469 123.561 25.0549 123.561 26.2519ZM121.513 25.6989C121.527 25.0669 121.387 24.5209 121.096 24.0599C120.724 23.4669 120.153 23.1709 119.384 23.1709C118.682 23.1709 118.111 23.4599 117.675 24.0399C117.317 24.5009 117.104 25.0539 117.039 25.6979H121.513V25.6989Z" fill="white" />
            <path d="M49.9133 10.0089C49.9133 11.1859 49.5577 12.0719 48.8475 12.6669C48.1897 13.2159 47.2548 13.4909 46.0439 13.4909C45.4435 13.4909 44.9297 13.4649 44.4995 13.4129V6.98195C45.0607 6.89195 45.6651 6.84595 46.3179 6.84595C47.4714 6.84595 48.3408 7.09495 48.9271 7.59295C49.5839 8.15595 49.9133 8.96095 49.9133 10.0089ZM48.8002 10.0379C48.8002 9.27495 48.5967 8.68995 48.1897 8.28195C47.7827 7.87495 47.1883 7.67095 46.4055 7.67095C46.0731 7.67095 45.79 7.69295 45.5553 7.73895V12.6279C45.6852 12.6479 45.923 12.6569 46.2685 12.6569C47.0765 12.6569 47.7001 12.4339 48.1393 11.9879C48.5785 11.5419 48.8002 10.8919 48.8002 10.0379Z" fill="white" />
            <path d="M55.8157 11.0368C55.8157 11.7618 55.6072 12.3558 55.1901 12.8218C54.7529 13.3008 54.1737 13.5398 53.4503 13.5398C52.7532 13.5398 52.1981 13.3108 51.7841 12.8508C51.3711 12.3918 51.1645 11.8128 51.1645 11.1148C51.1645 10.3848 51.3771 9.7858 51.8042 9.3208C52.2314 8.8558 52.8056 8.6228 53.5289 8.6228C54.226 8.6228 54.7862 8.8518 55.2103 9.3108C55.6132 9.7568 55.8157 10.3328 55.8157 11.0368ZM54.7207 11.0708C54.7207 10.6358 54.626 10.2628 54.4376 9.9518C54.216 9.5758 53.9007 9.3878 53.4906 9.3878C53.0665 9.3878 52.7441 9.5758 52.5225 9.9518C52.3331 10.2628 52.2394 10.6418 52.2394 11.0898C52.2394 11.5248 52.3341 11.8978 52.5225 12.2088C52.7512 12.5848 53.0695 12.7728 53.4806 12.7728C53.8835 12.7728 54.1999 12.5818 54.4275 12.1988C54.623 11.8818 54.7207 11.5058 54.7207 11.0708Z" fill="white" />
            <path d="M63.7299 8.71899L62.244 13.433H61.2769L60.6614 11.386C60.5052 10.875 60.3783 10.367 60.2796 9.86299H60.2604C60.1687 10.381 60.0418 10.888 59.8786 11.386L59.2248 13.433H58.2466L56.8493 8.71899H57.9343L58.4713 10.96C58.6012 11.49 58.708 11.995 58.7936 12.473H58.8128C58.8913 12.079 59.0213 11.577 59.2047 10.97L59.8786 8.71999H60.7389L61.3847 10.922C61.5408 11.459 61.6678 11.976 61.7655 12.474H61.7947C61.8662 11.989 61.974 11.472 62.1171 10.922L62.6933 8.71999H63.7299V8.71899Z" fill="white" />
            <path d="M69.2032 13.4329H68.1474V10.7329C68.1474 9.90087 67.8291 9.48487 67.1904 9.48487C66.8771 9.48487 66.6242 9.59887 66.4278 9.82787C66.2333 10.0569 66.1346 10.3269 66.1346 10.6359V13.4319H65.0788V10.0659C65.0788 9.65187 65.0658 9.20287 65.0406 8.71687H65.9684L66.0178 9.45387H66.047C66.1699 9.22487 66.3532 9.03587 66.594 8.88487C66.8801 8.70887 67.2005 8.61987 67.551 8.61987C67.9943 8.61987 68.363 8.76187 68.6562 9.04687C69.0208 9.39587 69.2032 9.91687 69.2032 10.6089V13.4329Z" fill="white" />
            <path d="M72.1146 13.4329H71.0598V6.55591H72.1146V13.4329Z" fill="white" />
            <path d="M78.3303 11.0368C78.3303 11.7618 78.1217 12.3558 77.7047 12.8218C77.2675 13.3008 76.6872 13.5398 75.9649 13.5398C75.2668 13.5398 74.7117 13.3108 74.2986 12.8508C73.8856 12.3918 73.6791 11.8128 73.6791 11.1148C73.6791 10.3848 73.8916 9.7858 74.3188 9.3208C74.7459 8.8558 75.3201 8.6228 76.0425 8.6228C76.7406 8.6228 77.2997 8.8518 77.7248 9.3108C78.1278 9.7568 78.3303 10.3328 78.3303 11.0368ZM77.2342 11.0708C77.2342 10.6358 77.1395 10.2628 76.9511 9.9518C76.7305 9.5758 76.4142 9.3878 76.0052 9.3878C75.5801 9.3878 75.2577 9.5758 75.0371 9.9518C74.8477 10.2628 74.754 10.6418 74.754 11.0898C74.754 11.5248 74.8487 11.8978 75.0371 12.2088C75.2657 12.5848 75.5841 12.7728 75.9951 12.7728C76.3981 12.7728 76.7134 12.5818 76.9411 12.1988C77.1375 11.8818 77.2342 11.5058 77.2342 11.0708Z" fill="white" />
            <path d="M83.4399 13.4328H82.4919L82.4133 12.8898H82.3841C82.0597 13.3228 81.5973 13.5398 80.9969 13.5398C80.5486 13.5398 80.1859 13.3968 79.9129 13.1128C79.6651 12.8548 79.5412 12.5338 79.5412 12.1528C79.5412 11.5768 79.783 11.1378 80.2695 10.8338C80.7551 10.5298 81.4381 10.3808 82.3176 10.3878V10.2998C82.3176 9.6788 81.9892 9.3688 81.3313 9.3688C80.8629 9.3688 80.4499 9.4858 80.0932 9.7178L79.8787 9.0298C80.3199 8.7588 80.8649 8.6228 81.5076 8.6228C82.7488 8.6228 83.3713 9.2728 83.3713 10.5728V12.3088C83.3713 12.7798 83.3945 13.1548 83.4399 13.4328ZM82.3438 11.8128V11.0858C81.1792 11.0658 80.597 11.3828 80.597 12.0358C80.597 12.2818 80.6634 12.4658 80.7994 12.5888C80.9354 12.7118 81.1087 12.7728 81.3152 12.7728C81.5469 12.7728 81.7635 12.6998 81.961 12.5548C82.1594 12.4088 82.2813 12.2238 82.3267 11.9968C82.3377 11.9458 82.3438 11.8838 82.3438 11.8128Z" fill="white" />
            <path d="M89.439 13.4329H88.5021L88.4527 12.6759H88.4235C88.1243 13.2519 87.6146 13.5399 86.8983 13.5399C86.3261 13.5399 85.8496 13.3169 85.4718 12.8709C85.094 12.4249 84.9056 11.8459 84.9056 11.1349C84.9056 10.3719 85.1101 9.75391 85.5212 9.28191C85.9191 8.84191 86.4067 8.62191 86.9869 8.62191C87.6246 8.62191 88.0709 8.83491 88.3248 9.26191H88.3449V6.55591H89.4017V12.1629C89.4017 12.6219 89.4138 13.0449 89.439 13.4329ZM88.3449 11.4449V10.6589C88.3449 10.5229 88.3348 10.4129 88.3157 10.3289C88.2563 10.0769 88.1283 9.86491 87.9339 9.69391C87.7375 9.52291 87.5007 9.43691 87.2277 9.43691C86.8338 9.43691 86.5255 9.59191 86.2989 9.90291C86.0742 10.2139 85.9604 10.6109 85.9604 11.0959C85.9604 11.5619 86.0682 11.9399 86.2848 12.2309C86.5135 12.5409 86.8217 12.6959 87.2076 12.6959C87.5541 12.6959 87.8311 12.5669 88.0417 12.3079C88.2452 12.0689 88.3449 11.7809 88.3449 11.4449Z" fill="white" />
            <path d="M98.4684 11.0368C98.4684 11.7618 98.2598 12.3558 97.8428 12.8218C97.4055 13.3008 96.8273 13.5398 96.103 13.5398C95.4068 13.5398 94.8518 13.3108 94.4367 12.8508C94.0237 12.3918 93.8172 11.8128 93.8172 11.1148C93.8172 10.3848 94.0297 9.7858 94.4569 9.3208C94.884 8.8558 95.4582 8.6228 96.1825 8.6228C96.8787 8.6228 97.4398 8.8518 97.8629 9.3108C98.2659 9.7568 98.4684 10.3328 98.4684 11.0368ZM97.3743 11.0708C97.3743 10.6358 97.2796 10.2628 97.0912 9.9518C96.8686 9.5758 96.5543 9.3878 96.1433 9.3878C95.7202 9.3878 95.3978 9.5758 95.1751 9.9518C94.9857 10.2628 94.8921 10.6418 94.8921 11.0898C94.8921 11.5248 94.9868 11.8978 95.1751 12.2088C95.4038 12.5848 95.7222 12.7728 96.1332 12.7728C96.5362 12.7728 96.8535 12.5818 97.0812 12.1988C97.2756 11.8818 97.3743 11.5058 97.3743 11.0708Z" fill="white" />
            <path d="M104.145 13.4329H103.09V10.7329C103.09 9.90087 102.772 9.48487 102.132 9.48487C101.819 9.48487 101.566 9.59887 101.371 9.82787C101.175 10.0569 101.078 10.3269 101.078 10.6359V13.4319H100.021V10.0659C100.021 9.65187 100.009 9.20287 99.9835 8.71687H100.91L100.96 9.45387H100.989C101.113 9.22487 101.296 9.03587 101.536 8.88487C101.823 8.70887 102.142 8.61987 102.494 8.61987C102.936 8.61987 103.305 8.76187 103.598 9.04687C103.964 9.39587 104.145 9.91687 104.145 10.6089V13.4329Z" fill="white" />
            <path d="M111.25 9.50392H110.088V11.7939C110.088 12.3759 110.294 12.6669 110.703 12.6669C110.893 12.6669 111.05 12.6509 111.174 12.6179L111.201 13.4129C110.992 13.4909 110.718 13.5299 110.381 13.5299C109.964 13.5299 109.639 13.4039 109.405 13.1519C109.169 12.8999 109.052 12.4759 109.052 11.8809V9.50392H108.358V8.71892H109.052V7.85492L110.087 7.54492V8.71792H111.249V9.50392H111.25Z" fill="white" />
            <path d="M116.839 13.4329H115.783V10.7529C115.783 9.90791 115.464 9.48491 114.827 9.48491C114.337 9.48491 114.003 9.72991 113.819 10.2199C113.788 10.3229 113.77 10.4489 113.77 10.5969V13.4319H112.715V6.55591H113.77V9.39691H113.79C114.122 8.87991 114.599 8.62191 115.217 8.62191C115.654 8.62191 116.015 8.76391 116.302 9.04891C116.66 9.40391 116.839 9.93191 116.839 10.6299V13.4329Z" fill="white" />
            <path d="M122.605 10.853C122.605 11.041 122.591 11.199 122.566 11.328H119.399C119.413 11.794 119.564 12.149 119.858 12.395C120.126 12.615 120.471 12.725 120.894 12.725C121.363 12.725 121.79 12.651 122.175 12.502L122.34 13.23C121.89 13.424 121.36 13.521 120.746 13.521C120.011 13.521 119.431 13.306 119.012 12.876C118.591 12.446 118.383 11.869 118.383 11.145C118.383 10.434 118.577 9.84201 118.969 9.37001C119.378 8.86601 119.931 8.61401 120.629 8.61401C121.312 8.61401 121.831 8.86601 122.182 9.37001C122.465 9.77001 122.605 10.265 122.605 10.853ZM121.597 10.582C121.605 10.271 121.536 10.003 121.393 9.77701C121.21 9.48601 120.931 9.34001 120.553 9.34001C120.208 9.34001 119.927 9.48201 119.713 9.76701C119.537 9.99401 119.434 10.265 119.399 10.582H121.597Z" fill="white" />
        </svg>

    )
}

export default Appstore