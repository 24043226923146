import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../Service/service";
import SkeletonLoader from "./SkeletonLoader";
const fetchMenu = async () => {
  try {
    const response = await fetch(`${BASE_URL}/menu`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const Menu = () => {
  const [menuData, setMenuData] = useState([]);
  const [selectedVeg, setSelectedVeg] = useState(false);
  const [selectedType, setSelectedType] = useState("Regular");
  const [selectedDay, setSelectedDay] = useState(getCurrentDay());
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  function getCurrentDay() {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date().getDay();
    return days[today];
  }

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchMenu();
      setMenuData(data);
      setError(null);
    } catch (error) {
      console.error("Error fetching menu data:", error);
      setError("Failed to load menu data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const filterMenu = () => {
    return menuData.filter(
      (item) =>
        item.vegtype === (selectedVeg ? "Veg" : "Non-veg") &&
        item.menutype === selectedType &&
        item.weektype === selectedDay
    );
  };

  const handleComingSoonPress = (mealType) => {
    alert(`The ${mealType} meal category will be available soon. Stay tuned!`);
  };

  const handleCardPress = (meal) => {
    setSelectedMeal(meal);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedMeal(null);
  };

  if (isLoading) {
    return <div style={styles.loadingContainer}>Loading menu...</div>;
  }

 
  if (isLoading) {
    return (
      <div style={styles.container}>
        <div style={styles.menuRow}>
          {[1, 2, 3, 4].map((index) => (
            <SkeletonLoader key={index} />
          ))}
        </div>
      </div>
    );
  }
  if (error) {
    return <div style={styles.errorContainer}>{error}</div>;
  }
  return (
    <div style={styles.container}>
      <div style={styles.buttonGroup}>
        <button
          onClick={() => setSelectedVeg(false)}
          style={{
            ...styles.button,
            ...(selectedVeg ? {} : styles.selectedButton),
          }}
        >
          Non-Veg
        </button>
        <button
          onClick={() => setSelectedVeg(true)}
          style={{
            ...styles.button,
            ...(selectedVeg ? styles.selectedButton : {}),
          }}
        >
          Veg
        </button>
      </div>

      <div style={styles.buttonGroup}>
        <button
          onClick={() => setSelectedType("Regular")}
          style={{
            ...styles.button,
            ...(selectedType === "Regular" ? styles.selectedButton : {}),
          }}
        >
          Regular
        </button>
        <button
          onClick={() => handleComingSoonPress("Basic")}
          style={styles.disabledButton}
        >
          Basic
        </button>
        <button
          onClick={() => handleComingSoonPress("Healthy")}
          style={styles.disabledButton}
        >
          Healthy
        </button>
      </div>

      <div style={styles.dayButtonContainer}>
        {[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].map((day) => (
          <button
            key={day}
            onClick={() => setSelectedDay(day)}
            style={{
              ...styles.dayButton,
              ...(selectedDay === day ? styles.selectedDayButton : {}),
            }}
          >
            {day}
          </button>
        ))}
      </div>

      <div style={styles.menuContainer}>
        {filterMenu().map((item) => (
          <div key={item.id} style={styles.menuRow}>
            {item.menu.map((meal, index) => (
              <div
                key={index}
                style={styles.cardContainer}
                onClick={() => handleCardPress(meal)}
              >
                <img src={meal.imageUrl} alt={meal.Type} style={styles.image} />
                <div style={styles.textContainer}>
                  <div style={styles.mealTypeRow}>
                    <h3 style={styles.mealTypeHead}>{meal.Type}</h3>
                    <span style={styles.mealPrice}>₹{meal.price}</span>
                  </div>
                  <p style={styles.mealType}>{meal.mealType}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  loadingContainer: {
    textAlign: 'center',
    padding: '32px',
    fontSize: '18px',
  },
  errorContainer: {
    textAlign: 'center',
    padding: '32px',
    color: '#ff4d4d',
    fontSize: '18px',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '20px',
  },
  button: {
    padding: '14px 28px',
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    flex: '1 1 auto',
    maxWidth: '200px',
    borderRadius: '12px',
    marginBottom :'16px'
  },
  selectedButton: {
    backgroundColor: '#FFD814',
    borderColor: '#FFD814',
    color: 'black',
  },
  disabledButton: {
    padding: '14px 28px',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    color: '#999',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'not-allowed',
    flex: '1 1 auto',
    maxWidth: '200px',
    borderRadius: '12px',
       marginBottom :'16px'
  },
  dayButtonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '24px',
  },
  dayButton: {
    padding: '16px 24px',
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    flex: '1 1 auto',
    maxWidth: '150px',
    textAlign: 'center',
    borderRadius: '12px',
  },
  selectedDayButton: {
    backgroundColor: '#FFD814',
    borderColor: '#FFD814',
    color: 'black',
  },
  menuContainer: {
    marginTop: '24px',
  },
  menuRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    // width: '400px',
    // height: '450px',
    transition: 'all 0.3s ease-in-out',
    border: '1px solid #e0e0e0',
  },
  image: {
    width: '100%',
    height: '300px',
    borderRadius: '15px',
    marginBottom: '20px',
    objectFit: 'cover',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mealTypeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  mealTypeHead: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#00008B',
    margin: 0,
  },
  mealType: {
    fontSize: '16px',
    color: '#00008B',
    margin: 0,
  },
  mealPrice: {
    color: '#00008B',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  '@media (max-width: 768px)': {
    button: {
      padding: '12px 24px',
      fontSize: '14px',
    },
    dayButton: {
      padding: '14px 20px',
      fontSize: '16px',
    },
    cardContainer: {
      width: '100%',
      height: 'auto',
      maxWidth: '400px',
    },
    image: {
      height: '250px',
    },
  },
};

export default Menu;