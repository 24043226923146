import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../Components/UTIL//Buttons/PrimaryButton";
import ImageEdit from "../../SVG/ImageEdit";
import SecondaryButton from "../../Components/UTIL//Buttons/SecondaryButton";
import Cancel from "../../SVG/Cancel";
import Save from "../../SVG/Save";
import Non_Veg from "../../SVG/Non_Veg";
import Regular from "../../SVG/Regular";
import SearchContainer from "../UTIL/SearchContainer";
// import './Menu.css'
import { Colors } from "../../Colors";
function MenuEdit() {
  const styles = {
    buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: "flex"
    },
    container: {
      padding: '2rem',
      color: Colors.deepBlue100
    },
    headerText: {
      fontWeight: "bold",
      fontSize: "24px",
      margin: '0px 0px 8px 0px'
    },
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      width: "50%",
      // margin: '4px 0px 4px 0px'
    },
    searchContainer: {
      margin: '4px 0px 4px 0px'
    },
    itemText: {
      fontWeight: '700',
    },
    itemTextContainer: {
      // margin: '4px 0px 4px 0px',
    },
    innerContainer: {
      display: "flex",
      flex: 1,
      margin: '16px 0px 8px 0px',
    },
    imageContainer: {
      border: '1px solid #dcd3d3',
      borderRadius: '1rem',
      height: '200px',
      flex: 1,
      display: "flex",
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    image: {
      height: '100%',
      width: '100%',
      borderRadius: '1rem',
    },
    imageEditContainer: {
      position: 'absolute',
      cursor: "pointer",
      margin:'4px'
    },
    detailsOuterContainer: {
      flex: 2.5,
    },
    detailsContainer: {
      marginRight: '24px'
    },
    saveCancelContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '16px 0px 8px 0px',
    },
    saveContainer: {
      marginLeft: '16px'
    },
    iconOuterContainer: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconContainer: {
      marginRight: '4px',
      width: '20px'
    }
  };


  const ref = useRef();
  const [image, setImage] = useState(null);
  const [lgShow, setLgShow] = useState(false);

  // const ChangeImage = (e) => {
  //   console.log(e);
  //   if (e.target.files && e.target.files[0]) {
  //     setImage(URL.createObjectURL(e.target.files[0]));
  //   }
  // };
  //   const handleClick = (e) => {
  //     ref.current.click()
  // }

  // const onImageChange = (event) => {
  //     if (event.target.files && event.target.files[0]) {
  //         setImage(URL.createObjectURL(event.target.files[0]));
  //     }
  // }
  const handleClick = (e) => {
    ref.current.click()
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  }
  // modal


  return (
    <div >

      <div style={styles.buttonContainer}>
        <PrimaryButton onClick={() => setLgShow(true)}
          text={"Edit Details"}
          inlineStyle={{ padding: "4px 16px 4px 16px", marginBottom: "16px" }}
        />
      </div>
      <div>
        <Modal
          closeButton
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="my-modal">

          <div style={styles.container}>
            <h2 style={styles.headerText}>Edit Breakfast</h2>
            <p>Currently Editing</p>
            <div style={styles.textContainer}>
              <div style={styles.iconOuterContainer}>
                <div>
                  <Non_Veg styles={styles.iconContainer} />
                </div>
                Non-Vegetarian
              </div>
              <div style={styles.iconOuterContainer}>
                <div>
                  <Regular styles={styles.iconContainer} />
                </div>
                Regular
              </div>
              <div>Wednesday</div>
            </div>
            <div style={styles.searchContainer}>
              <SearchContainer />
            </div>
            <div style={styles.innerContainer}>
              <div style={styles.detailsOuterContainer}>
                <div style={styles.itemTextContainer}>
                  <p style={styles.itemText}>Appam</p>
                  <p style={styles.itemText}>Chicken Stew</p>
                </div>
                <div style={styles.detailsContainer}>
                  <p >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id eros lacus. Praesent vel elit lacus. Morbi in elit dignissim, pharetra nisl sit amet, ultrices libero. Ut nunc nisl, volutpat nec interdum id, vulputate ac nibh. Fusce metus justo, pharetra quis mi ac, laoreet eleifend tellus. Proin egestas enim vulputate metus facilisis, at sollicitudin lectus scelerisque. Suspendisse facilisis tellus urna, vel euismod nisi congue non. Curabitur pretium luctus magna, non iaculis dolor sodales eu.
                  </p>
                </div>
              </div>
              <div style={styles.imageContainer}>
                <img src={image} style={styles.image} />
                <div style={styles.imageEditContainer} onClick={handleClick}>
                  <ImageEdit style={styles.imageEdit} />
                  <input type="file" ref={ref} style={{ display: 'none' }} onChange={onImageChange} />
                </div>
              </div>
            </div>

            <div style={styles.saveCancelContainer}>
              <div style={styles.cancelContainer}>
                <SecondaryButton text={"Cancel"} icon={<Cancel />} inlineStyle={{padding:'6px 16px 6px 16px'}} />
              </div>
              <div style={styles.saveContainer}>
                <PrimaryButton text={"Save"} icon={<Save />} inlineStyle={{padding:'8px 24px 8px 24px'}} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default MenuEdit;
