import React from 'react'
import { Colors } from '../../../Colors'


function InactiveButton({ text, icon, onClick }) {
    const styles = {
        container: {
            backgroundColor: Colors.grey95,
            padding: '0.5rem 2rem 0.5rem 2rem',
            borderRadius: '0.4rem',
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${Colors.grey84} `,

        },
        buttonText: {
            marginLeft: '0.2rem',
            fontWeight: 600,
            color: Colors.grey64
        }
    }
    return (
        <div style={styles.container} id="clickable" onClick={onClick}>
            {icon}
            <span style={styles.buttonText} >{text}</span>
        </div>
    )
}

export default InactiveButton