import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch, FaWhatsapp } from "react-icons/fa";
import { BASE_URL, APP_LINK } from "../../Service/service";
import { Colors } from "../../Colors";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAdmins, setShowAdmins] = useState(true);
  const [showUsers, setShowUsers] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users`);
        var sortedResponse = response.data;
        sortedResponse.sort((a, b) => a.id - b.id);
        setUsers(sortedResponse);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone_number.includes(searchTerm)) &&
      ((showAdmins && user.user_info === "Admin") ||
        (showUsers && user.user_info !== "Admin"))
  );
  const sendWhatsAppMessage = (user) => {
    const cleanNumber = user.phone_number.replace(/\D/g, "");
    const numberWithCountryCode = cleanNumber.startsWith("91")
      ? cleanNumber
      : `91${cleanNumber}`;

    // Professional and personalized message
    const message = `Dear ${user.first_name},

We hope you're enjoying Lalas Kitchen services! 🌟

We've made some exciting improvements to enhance your experience with our app. To ensure you get the best service, kindly update your My Lalas Kitchen app to the latest version.

*What's New:*
• Improved user interface
• Faster order processing
• Better performance

Update now:
${APP_LINK}

Thank you for being a valued customer!

Best regards,
Team Lalas Kitchen
Contact :+91 7994544423
`;

    const whatsappURL = `https://wa.me/${numberWithCountryCode}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  const renderUserTable = (users, title) => (
    <div style={sectionStyle}>
      <h2 style={sectionTitleStyle}>{title}</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Phone</th>
            <th style={tableHeaderStyle}>Route</th>
            <th style={tableHeaderStyle}>Email</th>
            <th style={tableHeaderStyle}>Created At</th>
            <th style={tableHeaderStyle}>House & Flat</th>
            <th style={tableHeaderStyle}>Road & Location</th>
            <th style={tableHeaderStyle}>Locality</th>
            <th style={tableHeaderStyle}>Version</th>
            <th style={tableHeaderStyle}>User Status</th>
            <th style={tableHeaderStyle}>Update Push</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td style={tableCellStyle}>{index + 1}</td>
              <td style={tableCellStyle}>{user.first_name}</td>
              <td style={tableCellStyle}>{user.phone_number}</td>
              <td style={tableCellStyle}>{user.route}</td>
              <td style={tableCellStyle}>{user.last_name}</td>
              <td style={tableCellStyle}>{formatDate(user.created_at)}</td>
              <td style={tableCellStyle}>{user.house_name}</td>
              <td style={tableCellStyle}>{user.road_and_location}</td>
              <td style={tableCellStyle}>{user.locality}</td>
              <td style={tableCellStyle}>
                {user.app_version ? user.app_version : "Old Ver."}
              </td>

              <td style={tableCellStyle}>
                <span
                  style={
                    user.loginstatus ? { color: "green" } : { color: "red" }
                  }
                >
                  {user.loginstatus ? "Logged In" : "Not Logged In"}
                </span>
              </td>
              <td style={tableCellStyle}>
                <button
                  onClick={() => sendWhatsAppMessage(user)}
                  style={whatsappButtonStyle}
                  title="Send WhatsApp Update Message"
                >
                  <FaWhatsapp style={{ marginRight: '5px' }} /> 
                
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div style={{ minHeight: "100vh", marginBottom: "32px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "0px", padding: "20px" }}>
        User Management
      </h1>
      <div style={headerContainerStyle}>
        <div style={searchContainerStyle}>
          <FaSearch style={searchIconStyle} />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={searchInputStyle}
          />
        </div>
        <div style={filterContainerStyle}>
          <label style={filterLabelStyle}>
            <input
              type="checkbox"
              checked={showAdmins}
              onChange={() => setShowAdmins(!showAdmins)}
            />
            Show Delivery Users
          </label>
          <label style={filterLabelStyle}>
            <input
              type="checkbox"
              checked={showUsers}
              onChange={() => setShowUsers(!showUsers)}
            />
            Show Users
          </label>
        </div>
      </div>
      {showAdmins &&
        renderUserTable(
          filteredUsers.filter((user) => user.user_info === "Admin"),
          "Delivery Users"
        )}
      {showUsers &&
        renderUserTable(
          filteredUsers.filter((user) => user.user_info !== "Admin"),
          "Regular Users"
        )}
    </div>
  );
};

const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 32px",
};

const searchContainerStyle = {
  display: "flex",
  alignItems: "center",
  width: "300px",
  background: Colors.LinearGradient,
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  padding: "5px 15px",
};

const searchIconStyle = {
  color: "white",
  marginRight: "10px",
};

const searchInputStyle = {
  flex: 1,
  border: "none",
  padding: "10px",
  fontSize: "16px",
  outline: "none",
  borderRadius: "6px",
};

const filterContainerStyle = {
  display: "flex",
  alignItems: "center",
};

const filterLabelStyle = {
  marginLeft: "20px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const sectionStyle = {
  marginBottom: "40px",
};

const sectionTitleStyle = {
  marginLeft: "32px",
};

const tableStyle = {
  borderCollapse: "collapse",
  width: "95%",
  margin: "0 auto",
};

const tableHeaderStyle = {
  border: "1px solid #dddddd",
  padding: "8px",
  background: Colors.LinearGradient,
  fontWeight: "bold",
};

const tableCellStyle = {
  border: "1px solid #dddddd",
  padding: "8px",
};
const whatsappButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 12px',
  backgroundColor: '#25D366',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'all 0.2s',
  fontWeight: 'bold',
  width: '100%',
  fontSize: '13px',
  ':hover': {
    backgroundColor: '#128C7E',
  }
};
export default UserManagement;
