import React from 'react'

function ImageEdit({ style, onClick }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick}>
            <rect width="40" height="40" rx="6" fill="#FFD814" />
            <path d="M31.0832 22.6264L30.0764 23.6332L28.0629 21.6196L29.0697 20.6129C29.1703 20.5122 29.271 20.4115 29.4724 20.4115C29.5731 20.4115 29.7744 20.5122 29.8751 20.6129L31.1839 21.9217C31.2845 22.123 31.2845 22.425 31.0832 22.6264ZM21.3176 28.2643V30.3785H23.4318L29.5731 24.2372L27.5595 22.2237L21.3176 28.2643ZM19.5155 24.1667L17.5423 21.7908L14.7737 25.3447H21.4384L23.9956 22.8781L22.2841 20.6028L19.5155 24.1667ZM19.3041 28.2643V27.4085L19.3544 27.3582H13.2635V13.2635H27.3582V19.6162L29.3717 17.6731V13.2635C29.3717 12.1561 28.4757 11.25 27.3582 11.25H13.2635C12.1561 11.25 11.25 12.1561 11.25 13.2635V27.3582C11.25 28.4757 12.1561 29.3717 13.2635 29.3717H19.3041V28.2643Z" fill="black" />
        </svg>

    )
}

export default ImageEdit