import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
// import PDFDownloadButtonSub from "./PDFDownloadButtonSub";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SubscriptionFilter = ({
  onFilterChange,
  onDateRangeChange,
  onSearchChange,
  currentFilter,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "end", label: "End" },
    { value: "refund", label: "Refund Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "all", label: "All" },
  ];

  const handleStatusChange = (status) => {
    onFilterChange(status);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDateRangeChange(start, end);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    onSearchChange(e.target.value);
  };

  return (
    <div style={cardStyle} className="mb-6">
      <div className="flex items-center space-x-2 mb-2">
        <h4 className="text-lg font-semibold text-gray-800">Filter by Order</h4>
      </div>
      <div className="flex flex-wrap gap-2 mb-4">
        {statusOptions.map((option) => (
          <button
            key={option.value}
            onClick={() => handleStatusChange(option.value)}
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "6px",
              fontSize: "0.875rem",
              fontWeight: "500",
              marginRight: "0.5rem",
              backgroundColor:
                currentFilter === option.value ? "#FAC105" : "grey",
              color: currentFilter === option.value ? "black" : "white",
              boxShadow:
                currentFilter === option.value
                  ? "0 4px 6px rgba(0, 0, 0, 0.1)"
                  : "none",
              cursor: "pointer",
            }}
          >
            {option.label}
          </button>
        ))}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by phone"
          className="p-2 border rounded"
          style={{
            padding: "0.5rem 1rem",
            marginRight: "0.5rem",
          }}
        />
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          isClearable={true}
          placeholderText="Select date range"
          className="p-2 border rounded"
        />
      </div>
    </div>
  );
};

const TokensDisplay = ({ tokens }) => {
  return (
    <div className="tokens-display">
      {tokens.map((token, index) => {
        const vegTypeStyle = {
          top: "5px",
          right: "5px",
          backgroundColor: token.vegtype === "Non-Veg" ? "#BF0000" : "#1e832a",
          color: token.vegtype === "Non-Veg" ? "white" : "white",
          padding: "2px 6px",
          borderRadius: "3px",
          fontSize: "0.8em",
          fontWeight: "bold",
          marginTop: "5px",
          marginBottom: "2px",
        };

        return (
          <div key={index} className="token-item" style={tokenItemStyle}>
            <div style={tokenNumberStyle}>Token {index + 1}</div>
            <div style={vegTypeStyle}>
              <strong>{`${token.vegtype}`} /</strong> {`${token.menutype}`}
            </div>
            {token.Breakfast === "Yes" && (
              <div>
                <strong>Brkfast : </strong> {token.Breakfast}
              </div>
            )}
            {token.Lunch === "Yes" && (
              <div>
                <strong>Lunch : </strong> {token.Lunch}
              </div>
            )}
            {token.Dinner === "Yes" && (
              <div>
                <strong>Dinner : </strong> {token.Dinner}
              </div>
            )}
            <div>
              <strong>Persons : </strong> {token.numberOfMeals}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getCustomDateInfo = (order) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const groupDates = (dates) => {
    return dates.map(formatDate);
  };

  if (Array.isArray(order.multiple_date)) {
    const groupedDates = groupDates(order.multiple_date);
    return (
      <div
        style={{
          overflowY: "auto",
          padding: "4px",
          borderRadius: "4px",
        }}
      >
        {groupedDates.map((date, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#f0f0f0",
              padding: "2px 6px",
              marginBottom: "2px",
              borderRadius: "4px",
            }}
          >
            {date}
          </div>
        ))}
      </div>
    );
  } else if (order.multiple_date === "No Data" || !order.multiple_date) {
    return (
      <span style={{ color: "#888888", fontStyle: "italic" }}>
        No
      </span>
    );
  } else {
    return formatDate(order.multiple_date);
  }
};

function Subscription() {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    return {
      date: `${day}/${month}/${year}`,
      time: `${hours}:${minutes} ${ampm}`,
    };
  };

  const [orders, setOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("active");
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [pauseData, setPauseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOrders = axios.get(`${BASE_URL}/createSetupMeals`);
    const fetchPauseData = axios.get(`${BASE_URL}/pause`);

    Promise.all([fetchOrders, fetchPauseData])
      .then(([ordersResponse, pauseResponse]) => {
        let filteredOrders = ordersResponse.data;

        // Filter by status
        if (currentFilter !== "all") {
          filteredOrders = filteredOrders.filter((order) => {
            const status = getSubscriptionStatus(order);
            if (currentFilter === "active") {
              return (
                (status === "Active" || order.subscription_status === "Pending") &&
                order.payment_status !== "2"
              );
            }
            if (currentFilter === "end") return status === "End";
            if (currentFilter === "refund") return status === "Refund Completed";
            if (currentFilter === "cancelled") return status === "Cancelled";
            return true;
          });
        }


        // Filter by date range
        if (dateRange.start && dateRange.end) {
          filteredOrders = filteredOrders.filter((order) => {
            const orderDate = new Date(order.subscription_details.currentTime);
            return orderDate >= dateRange.start && orderDate <= dateRange.end;
          });
        }

        // Filter by search term
        if (searchTerm) {
          filteredOrders = filteredOrders.filter((order) => {
            const name = order.userdata.first_name.toLowerCase();
            const phone = order.userdata.phone_number.toLowerCase();
            const search = searchTerm.toLowerCase();
            return name.includes(search) || phone.includes(search);
          });
        }

        // Sort by currentTime
        const sortedOrders = filteredOrders.sort((a, b) => {
          return (
            new Date(b.subscription_details.currentTime) -
            new Date(a.subscription_details.currentTime)
          );
        });

        setOrders(sortedOrders);
        setPauseData(pauseResponse.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentFilter, dateRange, searchTerm]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const getSubscriptionStatus = (order) => {
    if (order.order_status === 6) {
      return "Refund Completed";
    }
    if (order.order_status === 4) {
      return "Cancelled";
    }
    if (order.subscription_status === "Pending" && order.payment_status !== "2") {
      return "Active";
    }
    return order.subscription_status;
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  const handleDateRangeChange = (start, end) => {
    setDateRange({ start, end });
  };

  const getModificationInfo = (order) => {
    const matchingPauseData = pauseData.filter(
      (pause) => pause.uid === order.uid && pause.order_id === order.orderid
    );

    if (matchingPauseData.length === 0) {
      return "No";
    }

    return (
      <div className="modifications-display">
        {matchingPauseData.map((pause, index) => (
          <div
            key={index}
            className="modification-item"
            style={modificationItemStyle}
          >
            <div style={modificationNumberStyle}>{pause.pause_date}</div>
            <div>
              <strong>Brkfast:</strong> {pause.breakfast}
            </div>
            <div>
              <strong>Lunch:</strong> {pause.lunch}
            </div>
            <div>
              <strong>Dinner :</strong> {pause.dinner}
            </div>
            <div>
              <strong>Reason:</strong> {pause.pause_reason}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ 
      minHeight: "100vh", 
      padding: "0 15px", 
      overflowX: "hidden" 
    }}>
      <SubscriptionFilter
        onFilterChange={handleFilterChange}
        onDateRangeChange={handleDateRangeChange}
        onSearchChange={handleSearchChange}
        currentFilter={currentFilter}
      />
      <div 
        style={{ 
          overflowX: "auto", 
          width: "100%", 
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          borderRadius: "8px"
        }}
      >
        <table style={{
          ...tableStyle,
          minWidth: "1200px", // Ensures table doesn't break on smaller screens
        }}>
          <thead>
            <tr>
            <th style={{...headerStyle, minWidth: "100px"}}>Sl.No</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Name</th>
              <th style={{...headerStyle, minWidth: "80px"}}>Route</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Phone No</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Order ID</th>
              <th style={{...headerStyle, minWidth: "120px"}}>Order Placed</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Start Date</th>
              <th style={{...headerStyle, minWidth: "100px"}}>End Date</th>
              <th style={{...headerStyle, minWidth: "80px"}}>No.of days</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Custom Date</th>
              <th style={{...headerStyle, minWidth: "80px"}}>Status</th>
              <th style={{...headerStyle, minWidth: "120px"}}>Tokens</th>
              <th style={{...headerStyle, minWidth: "120px"}}>Modified</th>
              <th style={{...headerStyle, minWidth: "80px"}}>Delivery Charge</th>
              <th style={{...headerStyle, minWidth: "80px"}}>SubTotal</th>
              <th style={{...headerStyle, minWidth: "80px"}}>Discount</th>
              <th style={{...headerStyle, minWidth: "100px"}}>Total Paid</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order,index) => (
              <tr key={order.orderid} style={rowStyle(order)}>
              <td>{index+1}</td>
                <td>{order.userdata.first_name}</td>
                <td>{order.userdata.route}</td>
                <td>{order.userdata.phone_number}</td>
                <td>{order.orderid}</td>
                <td>
                  <div>
                    {formatDate(order.subscription_details.currentTime).date}
                  </div>
                  <div>
                    {formatDate(order.subscription_details.currentTime).time}
                  </div>
                </td>
                <td>{order.subscription_details.startDate}</td>
                <td>{order.subscription_details.endDate}</td>
                <td>{order.subscription_details.noOfDays}</td>
                <td>{getCustomDateInfo(order)}</td>
                <td>{getSubscriptionStatus(order)}</td>
                <td>
                  <TokensDisplay tokens={order.subscription_details.tokens} />
                </td>
                <td style={cellStyle}>{getModificationInfo(order)}</td>
                <td>₹ {order.deliverycharge}</td>
                <td>₹ {order.subtotal}</td>
                <td>₹ {order.discount}</td>
                <td>
                  ₹ {order.subscription_details.totalPricePaid}
                  <PaymentBadge paymentStatus={order.payment_status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  color: "black",
};

const cardStyle = {
  backgroundColor: "white",
  padding: "20px 20px 0px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
  border: "1px solid #D3D3D3",
  width: "fit-content",
};

const headerStyle = {
  background: Colors.LinearGradient,
  padding: "10px",
  textAlign: "center",
};

const tokenItemStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  borderRadius: "4px",
  marginBottom: "5px",
  position: "relative",
  minWidth: "100px",
  overflow: "auto", 
  display: "flex",
  flexDirection: "column",
  
};

const tokenNumberStyle = {
  top: "5px",
  right: "5px",
  backgroundColor: "#FAC105",
  color: "black",
  padding: "2px 6px",
  borderRadius: "3px",
  fontSize: "0.8em",
  fontWeight: "bold",
};
// const vegTypeStyle = {
//   top: "5px",
//   right: "5px",
//   backgroundColor: "#FAC105",
//   color: "black",
//   padding: "2px 6px",
//   borderRadius: "3px",
//   fontSize: "0.8em",
//   fontWeight: "bold",
//   marginTop: "5px",
//   marginBottom:"2px"
// };
const modificationItemStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  borderRadius: "4px",
  marginBottom: "5px",
  position: "relative",
  fontSize: "0.9em",
};

const modificationNumberStyle = {
  // position: "absolute",
  top: "5px",
  right: "5px",
  backgroundColor: "#FAC105",
  color: "black",
  padding: "2px 6px",
  borderRadius: "3px",
  fontSize: "0.8em",
  fontWeight: "bold",
  marginBottom: "2px",
};

const cellStyle = {
  padding: "10px",
  borderBottom: "1px solid #e0e0e0",
  maxWidth: "250px",
  overflow: "auto",
};

const PaymentBadge = ({ paymentStatus }) => {
  if (paymentStatus === "2") {
    return (
      <div
        style={{
          backgroundColor: "#DC2626",
          color: "white",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "0.75rem",
          fontWeight: "bold",
          display: "inline-block",
          // marginLeft: "8px",
        }}
      >
        Payment Failed
      </div>
    );
  }
  return null;
};

const rowStyle = (order) => {
  let backgroundColor = "white";
  let color = "black";

  if (order.payment_status === "2") {
    backgroundColor = "#FEF2F2";
    color = "#991B1B";
    return { backgroundColor, color };  
  }

  if (order.subscription_status !== "Active" && order.subscription_status !== "Pending") {
    backgroundColor = "#D3D3D3";
  }

  if (order.order_status === 6) {
    backgroundColor = "#D3D3D3";
    color = "blue";
  } else if (order.order_status === 4) {
    color = "red";
  } else if (
    (order.subscription_status === "Active" || order.subscription_status === "Pending") &&
    order.payment_status !== "2"
  ) {
    color = "#2F2C5D";
  }

  return {
    backgroundColor,
    color,
  };
};

export default Subscription;
