import React from "react";
import "./Plans.css";
import Regular from "../../../src/SVG/Regular";
import PrimaryButton from "../UTIL/Buttons/PrimaryButton";
import Non_Veg from "../../../src/SVG/Non_Veg";
import EditPlan from "./EditPlan";
const PlanCard = ({ text, icon }) => {
  const newLocal = "card_button";
  return (
    <div className="container">
      {/* Non-veg */}
      <div className="cont_1 ">
        <div>
          <img
            src={require("../../../src/assets/idiyappam.jpg")}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "10px 10px 0px 0px",
            }}
          />
        </div>

        <div className="cont_03">
          <div className="cont_01">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    alignItems: "center,",
                  }}
                >
                  {icon}
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    alignItems: "center",
                    marginLeft: "6px",
                  }}
                >
                  {text}
                </div>
              </div>

              <div>
                <Non_Veg />
              </div>
            </div>

            <p className="card_text">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
              iure, sunt facilis est ratione in quasi distinctio. Quo illo
              repudiandae incidunt est quod
            </p>
          </div>

          <div className="card_order">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                }}
              >
                Per Day
              </div>
              <div>120</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                }}
              >
                Per Day
              </div>
              <div>120</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                }}
              >
                Per Day
              </div>
              <div>120</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop:'1rem'
            }}
          >
            <EditPlan />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
