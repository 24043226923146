import React from "react";
import { useState, useEffect } from "react";
import { Colors } from "../../Colors";
import NavigationBar from "../../NavigationBar";
import Addons from "../Active orders/Addons";
import OrderHistory from "../OrderHistory/OrderHistory";

function AddonScreen() {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
    },
    innerContainer: {
      display: "flex",
      border: `1px solid ${Colors.yellow100}`,
      color: "#2F2C5D",
      borderRadius: "1rem",
    },
    itemStyle: {
      padding: "1rem 4rem 1rem 4rem",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemText: {
      fontSize: "70%",
      fontWeight: 700,
    },
  };

  const [active, setActive] = useState(0);
  const [borderRadius, setBorderRadius] = useState("");
  const SelectorItems = ["Addons", "Status"];
  const clickHandler = (index) => {
    for (var i = 0; i < SelectorItems.length; i++) {
      if (i === index) {
        setActive(i);
      }
    }
  };
  useEffect(() => {
    if (active === 0) {
      setBorderRadius("1rem 0 0 1rem");
    }
    if (active === 1) {
      setBorderRadius("0 1rem 1rem 0");
    }
  }, [active]);

  const content = () => {
    if (active === 0) {
      return (
        <div className="plancontainer">
          <Addons />
        </div>
      );
    }
    if (active === 1) {
      return (
        <div className="plancontainer" >
          <OrderHistory />
        </div>
      );
    }
  };

  return (
    <div>
      {/* <NavigationBar /> */}
      <h1
        style={{ textAlign: "center", marginBottom: "20px", padding: "20px" }}
      >
        Addons
      </h1>
      <div style={styles.container}>
        <div style={styles.innerContainer}>
          {SelectorItems.map((item, index) => (
            <div
              key={index}
              style={{
                ...styles.itemStyle,
                ...{
                  background: active === index ? Colors.LinearGradient : "",
                  borderRadius: borderRadius,
                },
              }}
              onClick={() => clickHandler(index)}
            >
              <span style={styles.itemText}>{item}</span>
            </div>
          ))}
        </div>
      </div>
      {content()}
    </div>
  );
}

export default AddonScreen;
