import React from 'react'

function UpdateInactive() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_337_205)">
                <circle cx="8.5" cy="8" r="8" fill="#FAFAFA" />
                <path d="M4.10815 3.83088C5.19765 2.67105 6.70328 1.99152 8.2938 1.94179C9.88432 1.89206 11.4295 2.47619 12.5893 3.56569L13.9586 2.10797L14.0765 5.87737L10.3071 5.99523L11.6764 4.53751C10.7743 3.69012 9.57254 3.23579 8.33547 3.27447C7.0984 3.31315 5.92736 3.84167 5.07997 4.74377C4.23258 5.64586 3.77825 6.84763 3.81693 8.08471C3.85561 9.32178 4.38413 10.4928 5.28623 11.3402C6.18832 12.1876 7.39009 12.6419 8.62716 12.6032C9.86424 12.5646 11.0353 12.036 11.8827 11.1339C12.5673 10.4051 12.968 9.49174 13.1041 8.56779L14.4611 8.52536C14.3219 9.80313 13.7947 11.0459 12.8545 12.0468C11.765 13.2067 10.2594 13.8862 8.66883 13.9359C7.07831 13.9857 5.53318 13.4015 4.37334 12.312C3.21351 11.2225 2.53398 9.7169 2.48425 8.12638C2.43451 6.53585 3.01865 4.99072 4.10815 3.83088Z" fill="#A3A3A3" />
            </g>
            <defs>
                <clipPath id="clip0_337_205">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default UpdateInactive