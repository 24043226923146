import React from 'react'
import CancelledOrExpired from '../../Tables/customer/CancelledOrExpired'
import Customer from '../CustomerMain/Customer'

function All() {
  const styles = {
    container: {
      padding: "2rem"
    },
    headerText: {
      fontSize: '24px',
      fontWeight: '700'
    },
  }
  return (
    <div>
      <Customer />
      <div style={styles.container}>
        <h2 style={styles.headerText}> Details of all users</h2>
        <CancelledOrExpired />
      </div>
    </div>
  )
}

export default All