import React, { useEffect, useState } from "react";
import { Cpu, Server, Clock, HardDrive, Activity, Network } from "lucide-react";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";

const SystemMonitorDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   fetch(`${BASE_URL}/system-info`)
  //     .then((response) => response.json())
  //     .then((fetchedData) => {
  //       // Convert GB to MB
  //       const convertedData = {
  //         ...fetchedData,
  //         totalMemory: (parseFloat(fetchedData.totalMemory) * 1024).toFixed(2) + " MB",
  //         freeMemory: (parseFloat(fetchedData.freeMemory) * 1024).toFixed(2) + " MB"
  //       };
  //       setData(convertedData);
  //     })
  //     .catch(setError)
  //     .finally(() => setLoading(false));
  // }, []);

  useEffect(() => {
    fetch(`${BASE_URL}/system-info`)
      .then((response) => response.json())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return null;

  const cardStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    padding: "16px",
    margin: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    minWidth: "250px",
    maxWidth: "300px",
  };

  const iconStyle = {
    marginBottom: "8px",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "8px",
  };

  const valueStyle = {
    fontSize: "14px",
    marginBottom: "4px",
  };

  return (
    <div>
        {/* <NavigationBar /> */}
      <div style={{  padding: "20px" }}>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          System Monitor Dashboard
        </h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div style={cardStyle}>
            <Server style={iconStyle} size={32} />
            <div style={titleStyle}>System Info</div>
            <div style={valueStyle}>Hostname: {data.hostname}</div>
            <div style={valueStyle}>Platform: {data.platform}</div>
            <div style={valueStyle}>Architecture: {data.architecture}</div>
            <div style={valueStyle}>Release: {data.release}</div>
          </div>
          <div style={cardStyle}>
            <Cpu style={iconStyle} size={32} />
            <div style={titleStyle}>CPU</div>
            <div style={valueStyle}>Cores: {data.cpuCores}</div>
            <div style={valueStyle}>Model: {data.cpuDetails[0].model}</div>
            <div style={valueStyle}>Load: {data.cpuLoad.join(", ")}</div>
            <div style={valueStyle}>Usage:</div>
            <div style={valueStyle}>- User: {data.cpuUsage.user}</div>
            <div style={valueStyle}>- System: {data.cpuUsage.system}</div>
          </div>
          <div style={cardStyle}>
            <HardDrive style={iconStyle} size={32} />
            <div style={titleStyle}>Memory</div>
            <div style={valueStyle}>Total: {data.totalMemory}</div>
            <div style={valueStyle}>Free: {data.freeMemory}</div>
            <div style={valueStyle}>Usage:</div>
            <div style={valueStyle}>- RSS: {data.memoryUsage.rss}</div>
            <div style={valueStyle}>
              - Heap Total: {data.memoryUsage.heapTotal}
            </div>
            <div style={valueStyle}>
              - Heap Used: {data.memoryUsage.heapUsed}
            </div>
            <div style={valueStyle}>
              - External: {data.memoryUsage.external}
            </div>
          </div>
          <div style={cardStyle}>
            <Clock style={iconStyle} size={32} />
            <div style={titleStyle}>Uptime</div>
            <div style={valueStyle}>{data.uptime}</div>
          </div>
          <div style={cardStyle}>
            <Network style={iconStyle} size={32} />
            <div style={titleStyle}>Network</div>
            {Object.entries(data.networkInterfaces).map(
              ([name, interfaces]) => (
                <div key={name}>
                  <div style={valueStyle}>{name}:</div>
                  {interfaces.map((int, index) => (
                    <div key={index} style={valueStyle}>
                      - {int.family}: {int.address}
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
          <div style={cardStyle}>
            <Activity style={iconStyle} size={32} />
            <div style={titleStyle}>User Info</div>
            <div style={valueStyle}>Username: {data.userInfo.username}</div>
            <div style={valueStyle}>
              Home Directory: {data.userInfo.homedir}
            </div>
            <div style={valueStyle}>Shell: {data.userInfo.shell}</div>
          </div>
        </div>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <p>Last Updated: {new Date(data.currentDate).toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

export default SystemMonitorDashboard;
