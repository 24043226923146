import React from 'react'

function ButtonRight({styles,onClick}) {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles} onClick={onClick}>
    <path d="M0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59L5.17009 6L0.590088 1.41ZM6.59009 1.41L8.00009 0L14.0001 6L8.00009 12L6.59009 10.59L11.1701 6L6.59009 1.41Z" fill="#2F2C5D"/>
    </svg>
    
    
  )
}


export default ButtonRight
