import React, { useState, useEffect } from "react";
import { Search, Trash2, X } from "lucide-react";
import { BASE_URL } from "../../Service/service";

const CustomDialog = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '100%'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2 style={{ margin: 0 }}>{title}</h2>
          <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <X size={24} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchMessages(selectedUser);
    }
  }, [selectedUser]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchMessages = async (uid) => {
    try {
      const response = await fetch(`${BASE_URL}/messages/${uid}`);
      const data = await response.json();
      setMessages(data.reverse());
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const sendMessage = async () => {
    if (newMessage.trim() === "" || !selectedUser) return;

    try {
      const latestUserMessage = messages.find((msg) => !msg.is_from_admin);

      if (!latestUserMessage) {
        console.error("No user message to reply to");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/reply/${latestUserMessage.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: newMessage,
            message_status: 4,
          }),
        }
      );

      if (response.ok) {
        setNewMessage("");
        fetchMessages(selectedUser);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const deleteMessage = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/messages/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        fetchMessages(selectedUser);
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone_number.includes(searchQuery)
  );

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const ProfileDetails = ({ user }) => (
    <CustomDialog
      isOpen={showProfile}
      onClose={() => setShowProfile(false)}
      title={`${user.first_name} ${user.last_name}`}
    >
      <div style={{ display: 'grid', gap: '16px', padding: '16px 0' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Phone:</span>
          <span>{user.phone_number}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>House:</span>
          <span>{user.house_name}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Location:</span>
          <span>{user.road_and_location}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Locality:</span>
          <span>{user.locality}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Route:</span>
          <span>{user.route}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>User Type:</span>
          <span>{user.user_info}</span>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'center', gap: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>App Version:</span>
          <span>{user.app_version}</span>
        </div>
      </div>
    </CustomDialog>
  );

  return (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <div style={{ display: 'flex', height: '100vh', color: '#333', overflow: 'hidden' }}>
        {/* Left sidebar */}
        <div style={{ width: '25%', borderRight: '1px solid #e0e0e0', display: 'flex', flexDirection: 'column' }}>
          <div style={{ padding: '16px', backgroundColor: '#f8f8f8', borderBottom: '1px solid #e0e0e0' }}>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                placeholder="Search users..."
                style={{
                  width: '100%',
                  color: '#333',
                  padding: '8px 16px 8px 40px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  outline: 'none'
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Search size={20} style={{ position: 'absolute', left: '12px', top: '50%', transform: 'translateY(-50%)', color: '#666' }} />
            </div>
          </div>

          <div style={{ flex: 1, overflowY: 'auto' }}>
            {filteredUsers.map((user) => (
              <div
                key={user.uid}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '16px',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s',
                  backgroundColor: selectedUser === user.uid ? '#f0f0f0' : 'transparent'
                }}
                onClick={() => setSelectedUser(user.uid)}
              >
                <div 
                  style={{
                    width: '48px',
                    height: '48px',
                    backgroundColor: '#e3e3e3',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '12px',
                    fontSize: '20px',
                    color: '#666'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowProfile(true);
                  }}
                >
                  {user.first_name.charAt(0).toUpperCase()}
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>{user.first_name}</div>
                  <div style={{ fontSize: '14px', color: '#666' }}>{user.phone_number}</div>
                </div>
                {/* {user.loginstatus && <div style={{ width: '8px', height: '8px', backgroundColor: '#4CAF50', borderRadius: '50%' }} />} */}
                <div 
                  style={{
                    marginLeft: "12px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: '12px',
                    padding: '4px 8px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    minWidth: '24px',
                    textAlign: 'center',
                    transition: 'background-color 0.3s, color 0.3s'
                  }}
                >
                  { 0}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat area */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', marginBottom: '64px' }}>
          {selectedUser ? (
            <>
              <div style={{ padding: '16px', backgroundColor: '#f8f8f8', borderBottom: '1px solid #e0e0e0', display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                <div style={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#e3e3e3',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '12px',
                  color: '#666'
                }}>
                  {users.find((u) => u.uid === selectedUser)?.first_name.charAt(0).toUpperCase()}
                </div>
                <div>
                  <div style={{ fontWeight: '600' }}>
                    {users.find((u) => u.uid === selectedUser)?.first_name}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>
                    {users.find((u) => u.uid === selectedUser)?.phone_number}
                  </div>
                </div>
              </div>

              <div style={{ flex: 1, overflowY: 'auto', padding: '16px', display: 'flex', flexDirection: 'column' }}>
                {messages.map((message) => (
                  <div
                    key={message.id}
                    style={{
                      display: 'flex',
                      marginBottom: '16px',
                      justifyContent: message.is_from_admin ? 'flex-end' : 'flex-start'
                    }}
                  >
                    <div
                      style={{
                        maxWidth: '70%',
                        padding: '12px',
                        borderRadius: '8px',
                        backgroundColor: message.is_from_admin ? '#e3f2fd' : '#f0f0f0',
                        marginLeft: message.is_from_admin ? 'auto' : '0'
                      }}
                    >
                      <div style={message.message_status === 2 ? { color: '#ff0000', fontWeight: 'bold' } : {}}>
                        {message.message}
                      </div>
                      <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
                        {formatTime(message.created_at)}
                      </div>
                    </div>
                    {message.is_from_admin && (
                      <Trash2
                        size={20}
                        style={{ cursor: 'pointer', marginLeft: '8px', color: '#ff0000' }}
                        onClick={() => deleteMessage(message.id)}
                      />
                    )}
                  </div>
                ))}
              </div>

              <div style={{ padding: '16px', backgroundColor: '#f8f8f8', borderTop: '1px solid #e0e0e0', position: 'fixed', bottom: 0, width: '75%', zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                    style={{
                      flex: 1,
                      backgroundColor: '#fff',
                      color: '#333',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      border: '1px solid #e0e0e0',
                      marginRight: '12px',
                      outline: 'none'
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                  <button
                    onClick={sendMessage}
                    style={{
                      backgroundColor: '#0084ff',
                      color: '#fff',
                      padding: '12px 24px',
                      borderRadius: '8px',
                      border: 'none',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', color: '#666' }}>
              <p>Select a user to start chatting</p>
            </div>
          )}
        </div>
      </div>
      {selectedUser && (
        <ProfileDetails user={users.find((u) => u.uid === selectedUser)} />
      )}
    </div>
  );
};

export default ChatComponent;