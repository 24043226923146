import React, { useState } from 'react';
import "./Delivery.css"

const Delivery = () => {
  // Dummy data for orders and delivery boys
  const [orders] = useState([
    { id: 1, status: '' },
    { id: 2, status: '' },
    { id: 3, status: '' },
  ]);
  const [deliveryBoys] = useState([
    { id: 101, name: 'vijay' },
    { id: 102, name: 'jihana' },
    { id: 103, name: 'google' },
  ]);
  const [route] = useState([
    { id: 1, name: 'Route1' },
    { id: 2, name: 'Route2' },
    { id: 3, name: 'Route3' },
  ]);

  // State variables to manage selected order and delivery boy
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null);

  // Function to handle order assignment
  const assignOrder = () => {
    if (selectedOrder && selectedDeliveryBoy) {
      // Here you can implement the logic to assign the order to the selected delivery boy
      console.log(`Assigned order ${selectedOrder.id} to delivery boy ${selectedDeliveryBoy.id}`);
    } else {
      console.log('Please select an order and a delivery boy to assign the order.');
    }
  };

  // Function to handle delivery boy selection
  const selectDeliveryBoy = (deliveryBoy) => {
    setSelectedDeliveryBoy(deliveryBoy);
  };

  // Function to handle order selection
  const selectOrder = (order) => {
    setSelectedOrder(order);
  };

  return (
    <div className="table-container">
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: 26,
          marginBottom: 26,
        }}
      >
        <h2>Statement</h2>
      </div>


      <div className="rr">
        <table className="custom-table">
          <thead>
            <tr>
              <th style={tableHeaderStyle}>SL No</th>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>Loan Payment</th>
              <th style={tableHeaderStyle}>Principle Paid</th>
              <th style={tableHeaderStyle}>Interest Paid</th>
              <th style={tableHeaderStyle}>Interest balance</th>
              <th style={tableHeaderStyle}>Priciple Balance</th>
            </tr>
          </thead>

          <tbody>
            {/* {statementData.map((row, index) => (
              <tr key={index}>
                <td style={tableCellStyle}>{index+1}</td>
                <td style={tableCellStyle}>{row.date}</td>
                <td style={tableCellStyle}>{row.amountpaid}</td>
                <td style={tableCellStyle}>{Math.abs(row.currentprincipal)}</td>
                <td style={tableCellStyle}>{row.currentinterest}</td>
                <td style={tableCellStyle}>{row.interestbalance}</td>
                <td style={tableCellStyle}>{row.principlebalance}</td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};
 export  default Delivery ;

 
const tableHeaderStyle = {
  padding: "12px",
  backgroundColor: "#00afb9",
  color: "#fff",
  borderBottom: "1px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
};