import React from "react";
import "./Plans.css";
import { useState, useEffect } from "react";
import { Colors } from "../../Colors";
import PlanCard from "./PlanCard";
import Regular from "../../SVG/Regular";
import Diabetic from "../../SVG/Diabetic";
import Healthy from "../../SVG/Healthy";

function Plans() {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

    },
    innerContainer: {
      display: "flex",
      border: `1px solid ${Colors.yellow100}`,
      color: "#2F2C5D",
      borderRadius: "1rem",
    },
    itemStyle: {
      padding: "1rem 4rem 1rem 4rem",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemText: {
      fontSize: "70%",
      fontWeight: 700,
    },

  };

  const [active, setActive] = useState(0);
  const [borderRadius, setBorderRadius] = useState("");
  const SelectorItems = ["Non-Vegetarian", "Vegetarian"];
  const clickHandler = (index) => {
    for (var i = 0; i < SelectorItems.length; i++) {
      if (i === index) {
        setActive(i);
      }
    }
  };
  useEffect(() => {
    if (active === 0) {
      setBorderRadius("1rem 0 0 1rem");
    }
    if (active === 1) {
      setBorderRadius("0 1rem 1rem 0");
    }
  }, [active]);


  const content = () => {
    if (active === 0) {
      return <div className="plancontainer">
        <div className="planbox">
          <PlanCard text={"Regular"} icon={<Regular styles={{ height: '16px', width: '16px' }} />} /></div>
        <div className="planbox">   <PlanCard text={"Healthy"} icon={<Healthy styles={{ height: '16px', width: '16px' }} />} /></div>
        <div className="planbox"><PlanCard text={"Diabetic Diet"} icon={<Diabetic styles={{ height: '16px', width: '16px' }} />} /></div>
      </div>
    }
    if (active === 1) {
      return <div className="plancontainer">
        <div className="planbox">
          <PlanCard text={"Regular"} icon={<Regular styles={{ height: '16px', width: '16px' }} />} /></div>
        <div className="planbox">   <PlanCard text={"Healthy"} icon={<Healthy styles={{ height: '16px', width: '16px' }} />} /></div>
        <div className="planbox"><PlanCard text={"Diabetic Diet"} icon={<Diabetic styles={{ height: '16px', width: '16px' }} />} /></div>
      </div>
      
    }
  };

  return (
    <div>

        <div style={styles.container}>
          <div style={styles.innerContainer}>
            {SelectorItems.map((item, index) => (

              <div
                key={index}
                style={{
                  ...styles.itemStyle,
                  ...{
                    background: active === index ? Colors.LinearGradient : "",
                    borderRadius: borderRadius,
                  },
                }}
                onClick={() => clickHandler(index)}
              >
                <span style={styles.itemText}>{item}</span>
              </div>
            ))}
          </div>
        </div>
        {content()}
     
    </div>
  );
}

export default Plans;
