import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import "./OrderHistory.css";

export const OrderHistory = () => {
  const [deliveryData, setDeliveryData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/delivery`)
      .then((response) => {
        setDeliveryData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching delivery data:", error);
      });
  }, []);

  // Filter data based on date range
  useEffect(() => {
    if (startDate && endDate) {
      const filtered = deliveryData.filter((delivery) => {
        const deliveryDate = new Date(delivery.deliverydata.currenttime);
        return (
          deliveryDate >= new Date(startDate) &&
          deliveryDate <= new Date(endDate)
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(deliveryData);
    }
  }, [startDate, endDate, deliveryData]);

  // Calculate total price
  const totalPricePaid = filteredData.reduce(
    (sum, delivery) => sum + Number(delivery.deliverydata.totalpricepaid),
    0
  );

  return (
    <div className="order-history-container">
      {/* Date Range Selection */}
      <div className="date-filter-section">
        <div className="date-inputs">
          <div className="date-input-group">
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="date-input-group">
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="total-price">
          <span>Total Price Paid: ₹{totalPricePaid.toFixed(2)}/-</span>
        </div>
      </div>

      {/* Table Section */}
      <div className="table-container">
        <table className="order-table">
          <thead>
            <tr>
              <th>Sl.No</th>
              <th>Order ID</th>
              <th>Delivery Date</th>
              <th>Route</th>
              <th>Delivery User</th>
              <th>User Data</th>
              <th>Order Details</th>
              <th>Payment</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((delivery, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{delivery.order_id}</td>
                <td>{delivery.deliverydata.currenttime}</td>
                <td>{delivery.deliverydata.userdata.route}</td>
                <td>{delivery.deliveryuser}</td>
                <td>
                  <div className="user-data">
                    <div className="user-data-item">
                      <span className="user-data-label">Phone Number</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.phone_number}
                      </span>
                    </div>
                    <div className="user-data-item">
                      <span className="user-data-label">Name</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.first_name}
                      </span>
                    </div>
                    <div className="user-data-item">
                      <span className="user-data-label">Email</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.last_name}
                      </span>
                    </div>
                    <div className="user-data-item">
                      <span className="user-data-label">House Name</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.house_name}
                      </span>
                    </div>
                    <div className="user-data-item">
                      <span className="user-data-label">Land Mark</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.locality}
                      </span>
                    </div>
                    <div className="user-data-item">
                      <span className="user-data-label">Road</span>
                      <span className="user-data-value">
                        {delivery.deliverydata.userdata.road_and_location}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <ul className="order-details">
                    {delivery.deliverydata.types.map((deliveryItem, idx) => (
                      <li key={idx}>
                        <div>Veg Type: {deliveryItem.type}</div>
                        <div>Item Name: {deliveryItem.name}</div>
                        <div>Quantity: {deliveryItem.quantity}</div>
                        <div>Price: ₹{deliveryItem.price}/-</div>
                        <div>Ingredient: {deliveryItem.ingredient}</div>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <div className="payment-details">
                    <div>SubTotal: ₹{delivery.deliverydata.subtotal}/-</div>
                    <div>
                      Delivery Charge: ₹{delivery.deliverydata.deliverycharge}/-
                    </div>
                    <div>GST: ₹{delivery.deliverydata.gst}/-</div>
                    <div>
                      Total Price Paid: ₹{delivery.deliverydata.totalpricepaid}
                      /-
                    </div>
                  </div>
                </td>
                <td
                  className={
                    delivery.status ? "status-completed" : "status-pending"
                  }
                >
                  {delivery.status ? "Delivery Completed" : "Pending"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderHistory;
