import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import "./Profile.css";
import UserPageHome from "../../../SVG/UserPageHome";
import Phone from "../../../SVG/Phone";
import UserPageContact from "../../../SVG/UserPageContact";
import UserPageRoute from "./../../../SVG/UserPageRoute";
import UserPageDirection from "./../../../SVG/UserPageDirection";
import UserPageLocation from "./../../../SVG/UserPageLocation";
import LatestSubscription from "./LatestSubscription";
import PreviousSubscriptions from "./PreviousSubscriptions";
import Non_Veg from "../../../SVG/Non_Veg";
import Regular from "../../../SVG/Regular";
import More from "./../../../SVG/More";
import { Dropdown } from "bootstrap";

function Profile() {
  const styles = {
    container: {
      backgroundColor: "white",
      padding: "2rem",
    },
    profileContainer: {
      backgroundColor: "red",
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      height: "150px",
    },
    addressContainer: {
      backgroundColor: "yellow",
      flex: 2,
    },
    imageContainer: {
      backgroundColor: "blue",
      flex: 1,
    },
    nameContainer: {
      display: "flex",
      alignItems: "center",
    },
    phoneContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
    },
    addressInnerContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "brown",
    },
    nameText: {
      fontSize: "24px",
      marginLeft: "1rem",
      fontWeight: 500,
    },
    numberText: {
      fontSize: "16px",
      marginLeft: "1rem",
      fontWeight: 500,
    },
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Profile_cont">
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose} size="xl" centered>
        <div className="customer_details">
          <div>Details of the Customer</div>

          <div className="grid_profile">
            <div className="grid_profilebox1">
              <div className="previous_svg">
                <UserPageHome />
              </div>
              <div className="previous_svg">Neelima Bhadra</div>
            </div>

            <div className="grid_profilebox2">
              <div className="grid_profilebox1">
                <div className="previous_svg">
                  <UserPageContact />
                </div>
                <div>9845 326 485</div>
              </div>
            </div>
            <div className="grid_profilebox3">
              <div className="grid_profilebox1">
                <div className="previous_svg">
                  <UserPageHome />
                </div>
                <div>
                  Maliyekkal House Mooriyad Road Pazhayangadi P O
                  Thiruvananthapuram, 612653
                </div>
              </div>
            </div>
            <div className="grid_profilebox4">
              <div className="grid_profilebox1">
                <div className="previous_svg">
                  <UserPageRoute />
                </div>
                <div>Route 3</div>
              </div>
              <div className="grid_profilebox1">
                <div className="previous_svg">
                  <UserPageDirection />
                </div>
                <div>Pazhayangadi</div>
              </div>
              <div className="grid_profilebox1">
                <div className="previous_svg">
                  <UserPageLocation />
                </div>
                <div>8.535400, 76.948024</div>
              </div>
            </div>
            <div className="grid_profilebox5">
              <div>Latest Subscription</div>
            </div>

            {/* Latest Subscription */}
            <div className="grid_profilebox6">
              <div>
                <div className="latest_subscription">
                  <div className="latest_subscription1">
                    <LatestSubscription />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid_profilebox7">
              <div>Previous Subscriptions</div>
            </div>
            <div className="grid_profilebox7">
              <hr></hr>
            </div>

            <div className="grid_profilebox8">
              <PreviousSubscriptions />
            </div>
            <div className="grid_profilebox8">
              <PreviousSubscriptions />
            </div>
          </div>
        </div>

        <div></div>
      </Modal>
    </div>
  );
}
export default Profile;
