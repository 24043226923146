import React, { useState } from "react";
import { Colors } from "../Colors";
import { BASE_URL } from "../Service/service";

const RequestForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [reason, setReason] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      PhoneNumber: phoneNumber,
      FullName: fullName,
      Reason: reason,
    };

    try {
      const response = await fetch(`${BASE_URL}/deleteuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        setAlertMessage(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
    }

    setPhoneNumber("");
    setFullName("");
    setReason("");

    alert(alertMessage);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          width: "100%",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          transition: "0.3s",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            padding: "1rem 0",
            margin: "0",
          }}
        >
          Account Deletion Form
        </h2>
        <form onSubmit={handleSubmit} style={{ padding: "1rem" }}>
          <div style={{ marginBottom: "1rem" }}>
            <label
              htmlFor="phoneNumber"
              style={{ display: "block", marginBottom: "0.5rem" }}
            >
              Phone Number:
            </label>
            <input
              type="text"
              id="phoneNumber"
              placeholder="+91"
              value={phoneNumber}
              onChange={(e) => {
                if (e.target.value.startsWith("+91")) {
                  setPhoneNumber(e.target.value.substring(3));
                } else {
                  setPhoneNumber(e.target.value);
                }
              }}
              maxLength={10}
              style={{
                width: "100%",
                padding: "0.5rem",
                borderRadius: "3px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <label
              htmlFor="fullName"
              style={{ display: "block", marginBottom: "0.5rem" }}
            >
              Full Name:
            </label>
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{
                width: "100%",
                padding: "0.5rem",
                borderRadius: "3px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <label
              htmlFor="reason"
              style={{ display: "block", marginBottom: "0.5rem" }}
            >
              Reason for Deletion:
            </label>
            <textarea
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{
                width: "100%",
                padding: "0.5rem",
                borderRadius: "3px",
                border: "1px solid #ccc",
              }}
              // required
            />
          </div>
          <p>
          Deleting your account will remove all of your information from our database, including any personal data, preferences, and activity history associated with your account.
          </p>
          <button
            type="submit"
            style={{
              backgroundColor: Colors.yellow100,
              color: "black",
              padding: "0.5rem 1rem",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "1rem",
              width: "100%",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default RequestForm;
