import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../Tables/Addons/List.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Colors } from "../../Colors";
import Add from "../../SVG/Add";
import Delete from "../../SVG/Delete";
import PrimaryButton from "../UTIL/Buttons/PrimaryButton";
import InactiveButton from "../UTIL/Buttons/InactiveButton";
import SearchContainer from "../UTIL/SearchContainer";
import useTable from "../Create/useTable";
import TableFooter from "../Create/TableFooter";
import ImageModal from "../Tables/Addons/ImageModal";
import { BASE_URL } from "../../Service/service";
import DashboardHome from "../../Home/DashboardHome";
import NavigationBar from "../../NavigationBar";
function List() {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({});
  //   const addonsList = useSelector((state) => state.addons.addonsList);

  const [page, setPage] = useState(1);
  const [addonsList, setAddonsList] = useState([]);
  const { slice, range } = useTable(addonsList, page, 3);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  const [imageModalShow, setImageModalShow] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [vegType, setVegType] = useState("");
  const [menuType, setMenuType] = useState("");
  const [weekType, setWeekType] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddMenuItem = () => {
    setMenuItems([
      ...menuItems,
      {
        mealType: "",
        imageUrl: "",
        Type: "1",
      },
    ]);
    // setImageModalShow(true);
  };
  const handleAddImage = () => {
    setImageModalShow(true);
  };

  const handleMenuItemChange = (index, key, value) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index][key] = value;
    setMenuItems(updatedMenuItems);
  };

  const handleSaveMenu = () => {
    const menu = {
      VegType: vegType,
      menuType,
      weekType,
      menu: menuItems,
    };

    // Call API to add menu
    fetch(`${BASE_URL}/menu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(menu),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Menu added:", data);
        alert("Menu created successfully");
        setVegType("");
        setMenuType("");
        setWeekType("");
        setMenuItems([]);
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding menu:", error);
        alert("Error creating menu");
      });
  };
  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = () => {
    fetch(`${BASE_URL}/menu`)
      .then((response) => response.json())
      .then((data) => {
        setMenuData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
        toast.error("Failed to fetch menu data");
        setLoading(false);
      });
  };

  const handleStatusChange = (id, currentStatus) => {
    // Toggle the current status
    const updatedStatus = !currentStatus;

    const requestBody = {
      id: id,
      status: updatedStatus,
    };

    fetch(`${BASE_URL}/menu`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          const updatedMenuData = menuData.map((menu) => {
            if (menu.id === id) {
              return {
                ...menu,
                status: updatedStatus,
              };
            } else {
              return menu;
            }
          });

          setMenuData(updatedMenuData);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to update status");
        console.error("Error updating status:", error);
      });
  };
  const fetchImageList = () => {
    fetch(`${BASE_URL}/listImages`)
      .then((response) => response.json())
      .then((data) => {
        const processedImages = data.map((image) => ({
          ...image,
          description: extractDescription(image.image_url),
        }));
        setImageList(data);
        setImages(processedImages);
      })
      .catch((error) => {
        console.error("Error fetching image list:", error);
      });
  };

  useEffect(() => {
    fetchImageList();
  }, []);
  const handleImageSelect = (imageUrl) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[menuItems.length - 1].imageUrl = imageUrl;
    setMenuItems(updatedMenuItems);
    setImageModalShow(false);
  };

  const extractDescription = (url) => {
    const filename = url.split("/").pop();
    return filename.split(".")[0].replace(/_/g, " ");
  };

  const handleDelete = (id) => {
    // Show a confirmation dialog
    if (window.confirm("Are you sure you want to delete this menu?")) {
      fetch(`${BASE_URL}/menu/${id}`, {
        method: "DELETE",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          toast.success("Menu deleted successfully");
          // Remove the deleted menu from the state
          setMenuData(menuData.filter((menu) => menu.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting menu:", error);
          toast.error("Failed to delete menu");
        });
    }
  };

  return (
    <div style={{ flex: 1, minHeight: "100vh" }}>
      {/* <NavigationBar /> */}
      <div style={{ margin: "24px" }}>
        <h1
          style={{
            margin: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Weekly Menu
        </h1>
        <ToastContainer />
        {/* Modal */}
        <div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="vegType">
                  <Form.Label>Veg Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={vegType}
                    onChange={(e) => setVegType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Veg">Veg</option>
                    <option value="Non-veg">Non-Veg</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="menuType">
                  <Form.Label>Menu Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={menuType}
                    onChange={(e) => setMenuType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Regular">Regular</option>
                    <option value="Basic">Basic</option>
                    <option value="Healthy">Healthy</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="weekType">
                  <Form.Label>Week Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={weekType}
                    onChange={(e) => setWeekType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </Form.Control>
                </Form.Group>
                {menuItems.map((menuItem, index) => (
                  <div key={index}>
                    <Form.Group controlId={`Type-${index}`}>
                      <Form.Label style={{ fontWeight: 600 }}>
                        Meal Type {index + 1}
                      </Form.Label>
                      {/* <Form.Label> Meal Type</Form.Label> */}
                      <Form.Control
                        as="select"
                        value={menuItem.Type}
                        onChange={(e) =>
                          handleMenuItemChange(index, "Type", e.target.value)
                        }
                      >
                        <option value="">Select</option>
                        <option value="Breakfast">Breakfast</option>
                        <option value="Lunch">Lunch</option>
                        <option value="Dinner">Dinner</option>
                      </Form.Control>
                    </Form.Group>

                    {/* Meal type */}
                    <Form.Group controlId={`mealType-${index}`}>
  <Form.Label>Meal Name</Form.Label>
  <Form.Control
    as="select"
    value={menuItem.mealType}
    onChange={(e) =>
      handleMenuItemChange(
        index,
        "mealType",
        e.target.value
      )
    }
  >
    <option value="">Select</option>
    {menuItem.Type === "Breakfast" && (
      <>
        <option value="4 Idli + Sambar">4 Idli + Sambar</option>
        <option value="3 Idiyappam + Veg Kurma">3 Idiyappam + Veg Kurma</option>
        <option value="3 Dosa + Kadala Curry">3 Dosa + Kadala Curry</option>
        <option value="3 Veeshappam + Egg Curry">3 Veeshappam + Egg Curry</option>
        <option value="3 Dosa + Potato Curry">3 Dosa + Potato Curry</option>
        <option value="3 Appam + Veg Stew">3 Appam + Veg Stew</option>
        <option value="Puttu + Kadala curry">Puttu + Kadala curry</option>
        <option value="3 Idiyappam + Egg curry">3 Idiyappam + Egg curry</option>
        <option value="3 Dosa + Chutney + Vada">3 Dosa + Chutney + Vada</option>
        <option value="Banana Puttu">Banana Puttu</option>
      </>
    )}
    {menuItem.Type === "Lunch" && (
      <>
        <option value="Rice + Fish Curry + 2 Veg + Ozhichu Curry">Rice + Fish Curry + 2 Veg + Ozhichu Curry</option>
        <option value="Rice + Fish Fry + 2 Veg + Theeyal">Rice + Fish Fry + 2 Veg + Theeyal</option>
        <option value="Veg Pulao + Chicken Roast + Salad + Pickle">Veg Pulao + Chicken Roast + Salad + Pickle</option>
        <option value="Rice + Fish Curry + 2 Veg + Ozhichu Curry (Opt: Brown Rice)">Rice + Fish Curry + 2 Veg + Ozhichu Curry (Opt: Brown Rice)</option>
        <option value="Rice + 3 Veg + Ozhichu Curry + Pickle">Rice + 3 Veg + Ozhichu Curry + Pickle</option>
        <option value="Rice + 3 Veg + Ozhichu Curry + Papadam">Rice + 3 Veg + Ozhichu Curry + Papadam</option>
        <option value="Rice + 3 Veg + Ozhichu Curry + Curd">Rice + 3 Veg + Ozhichu Curry + Curd</option>
        <option value="Rice + 3 Veg + Ozhichu Curry">Rice + 3 Veg + Ozhichu Curry</option>
        <option value="Rice + 3 Veg + Ozhichu Curry + Veg Fry">Rice + 3 Veg + Ozhichu Curry + Veg Fry</option>
        <option value="Veg Pulao + Pickle + Salad">Veg Pulao + Pickle + Salad</option>
        <option value="Rice + 2 Veg + Ozhichu curry + Fish curry">Rice + 2 Veg + Ozhichu curry + Fish curry</option>
        <option value="Rice + Rasam + 2Veg + Fish Fry">Rice + Rasam + 2Veg + Fish Fry</option>
        <option value="Rice + 2Veg + Fish curry + Ozhichu curry">Rice + 2Veg + Fish curry + Ozhichu curry</option>
        <option value="Rice + Sambar + 2 Veg + Fish fry">Rice + Sambar + 2 Veg + Fish fry</option>
        <option value="Pulao + Chicken curry">Pulao + Chicken curry</option>
      </>
    )}
    {menuItem.Type === "Dinner" && (
      <>
        <option value="3 Chapati + Dal Curry">3 Chapati + Dal Curry</option>
        <option value="3 Idiyappam + Veg Kurma">3 Idiyappam + Veg Kurma</option>
        <option value="3 Appam + Greenpeas Curry">3 Appam + Greenpeas Curry</option>
        <option value="3 Veeshappam + Chicken Curry">3 Veeshappam + Chicken Curry</option>
        <option value="3 Idiyappam + Veg Curry">3 Idiyappam + Veg Curry</option>
        <option value="3 Dosa + Omelette + Chutney">3 Dosa + Omelette + Chutney</option>
        <option value="3 Chapati + Kadala Curry">3 Chapati + Kadala Curry</option>
        <option value="3 Palappam + Potato curry">3 Palappam + Potato curry</option>
        <option value="3 Chapathi + Dal curry">3 Chapathi + Dal curry</option>
        <option value="Appam + Chicken curry">3 Appam + Chicken curry</option>
        <option value="Veeshappam + Green peas curry">3 Veeshappam + Green peas curry</option>
        <option value="Chapathi + Potato curry">3 Chapathi + Potato curry</option>
        <option value="Dosa + Chammanthi + Omelette">3 Dosa + Chammanthi + Omelette</option>
      </>
    )}
  </Form.Control>
</Form.Group>

                    {/* img url */}

                    <Form.Group controlId={`imageUrl-${index}`}>
                      <Form.Label>Image URL</Form.Label>
                      <Form.Control
                        type="text"
                        value={menuItem.imageUrl}
                        onChange={(e) =>
                          handleMenuItemChange(
                            index,
                            "imageUrl",
                            e.target.value
                          )
                        }
                      />
                      <Button
                        variant="primary"
                        onClick={handleAddImage}
                        style={{ marginTop: "8px" }}
                      >
                        Upload image
                      </Button>
                    </Form.Group>
                    <Form.Group controlId={`price-${index}`}>
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="text"
                        value={menuItem.price}
                        onChange={(e) =>
                          handleMenuItemChange(index, "price", e.target.value)
                        }
                      />
                    </Form.Group>

                    <Modal
                      show={imageModalShow}
                      onHide={() => setImageModalShow(false)}
                      fullscreen={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Image</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {images.map((image) => (
                          <div
                            key={image.id}
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={image.image_url}
                              alt="Image"
                              onClick={() => handleImageSelect(image.image_url)}
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "5px",
                                cursor: "pointer",
                              }}
                            />
                            <div>{image.description}</div>{" "}
                          </div>
                        ))}
                      </Modal.Body>
                    </Modal>
                  </div>
                ))}
                <Button
                  variant="primary"
                  onClick={handleAddMenuItem}
                  style={{ marginTop: "8px" }}
                >
                  Add
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveMenu}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div
          style={{ display: "flex", height: "2.5rem", marginBottom: "1rem" }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <PrimaryButton
              text={"Add new"}
              icon={<Add />}
              onClick={handleOpenModal}
            />
            {/* <InactiveButton text={"Delete"} icon={<Delete />} /> */}
          </div>
          {/* <div style={{ flex: 1, marginLeft: "3rem" }}>
            <SearchContainer />
          </div> */}
        </div>

        {/* Table */}
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={headerStyle}>ID</th>
                <th style={headerStyle}>Veg Type</th>
                <th style={headerStyle}>Menu Type</th>
                <th style={headerStyle}>Week Type</th>
                <th style={headerStyle}>Menu</th>
                <th style={headerStyle}>Status</th>
                <th style={headerStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : (
                menuData.map((menu, index) => (
                  <tr key={menu.id}>
                    <td>{index + 1}</td>
                    <td>{menu.vegtype}</td>
                    <td>{menu.menutype}</td>
                    <td>{menu.weektype}</td>
                    <td>
                      <ul>
                        {menu.menu.map((item, index) => (
                          <div key={index} style={typeContainerStyle}>
                            <div style={imageContainerStyle}>
                              <img
                                src={item.imageUrl}
                                alt="Food"
                                style={imageStyle}
                              />
                            </div>
                            <div style={typeDetailsStyle}>
                              <div style={lineStyle}>
                                <span style={labelStyle1}> {item.Type}</span>
                              </div>
                              <div style={lineStyle}>
                                <span style={labelStyle1}>Meal Type :</span>
                                {item.mealType}
                              </div>
                              <div style={lineStyle}>
                                <span style={labelStyle1}>Price :</span>
                                {item.price}
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </td>

                    <td valign="middle">
                      <button
                        style={{
                          backgroundColor: menu.status ? "green" : "red",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleStatusChange(menu.id, menu.status)}
                      >
                        {menu.status ? "Enable" : "Disable"}
                      </button>
                    </td>
                    <td valign="middle">
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(menu.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>
    </div>
  );
}

export default List;

const lineStyle = {
  marginBottom: "6px",
};
const headerStyle = {
  // color: "white",
  // backgroundColor:Colors.deepBlue100
  background: Colors.LinearGradient,
};
const labelStyle1 = {
  // fontWeight: "bold",
  marginRight: "5px",
};

const typeContainerStyle = {
  display: "flex",
  marginBottom: "8px",
};

const imageContainerStyle = {
  width: "80px",
  height: "80px",
  overflow: "hidden",
  borderRadius: "10px",
  marginRight: "20px",
};

const imageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  // backgroundColor: "red",
};

const typeDetailsStyle = {
  flexGrow: 1,
};
