import React from 'react'

function ClearFilter() {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8936 14.1533L7.4069 7.66665H2.00023V6.33332H6.07357L4.07357 4.33332H2.00023V2.99999H2.74023L0.740234 0.999987L1.59357 0.15332L14.7402 13.3067L13.8936 14.1533ZM10.0002 6.33332H9.4669L10.0002 6.86665V6.33332ZM14.0002 4.33332V2.99999H6.13357L7.4669 4.33332H14.0002ZM2.00023 11H6.00023V9.66665H2.00023V11Z" fill="#A3A3A3" />
        </svg>

    )
}

export default ClearFilter