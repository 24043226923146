import React, { useState, useEffect } from "react";
import { format, differenceInDays, parse } from "date-fns";
import { BASE_URL } from "../../Service/service";
import NavigationBar from "../../NavigationBar";
import { Colors } from "../../Colors";

const UpdatePauseComponent = () => {
  const [orderId, setOrderId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subscriptionDayCount, setSubscriptionDayCount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (startDate && endDate) {
      const start = parse(startDate, "dd/M/yyyy", new Date());
      const end = parse(endDate, "dd/M/yyyy", new Date());
      const dayCount = differenceInDays(end, start);
      setSubscriptionDayCount(dayCount.toString());
    }
  }, [startDate, endDate]);

  const handleDateChange = (e, setDate) => {
    const inputDate = e.target.value;
    const formattedDate = format(new Date(inputDate), "dd/M/yyyy");
    setDate(formattedDate);
  };

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    if (!orderId || !startDate || !endDate) {
      setError("All fields are required for updating pause");
      setIsLoading(false);
      return;
    }

    const payload = {
      orderId: orderId,
      start_date: startDate,
      end_date: endDate,
      sub_pause: "2",
      subscription_day_count: subscriptionDayCount,
    };

    try {
      const response = await fetch(`${BASE_URL}/update-pause`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to update pause: ${await response.text()}`);
      }

      setSuccess("Subscription pause updated successfully");
    } catch (err) {
      console.error("Error updating pause:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Update Pause Subscription</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Order ID</label>
          <input
            type="text"
            value={orderId}
            onChange={handleOrderIdChange}
            style={styles.input}
            required
            placeholder="Enter Order ID"
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Start Date (DD/M/YYYY)</label>
          <input
            type="date"
            onChange={(e) => handleDateChange(e, setStartDate)}
            style={styles.input}
            min={new Date().toISOString().split("T")[0]}
            required
          />
          <span style={styles.dateDisplay}>{startDate}</span>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>End Date (DD/M/YYYY)</label>
          <input
            type="date"
            onChange={(e) => handleDateChange(e, setEndDate)}
            style={styles.input}
            min={
              startDate
                ? parse(startDate, "dd/M/yyyy", new Date())
                    .toISOString()
                    .split("T")[0]
                : new Date().toISOString().split("T")[0]
            }
            required
          />
          <span style={styles.dateDisplay}>{endDate}</span>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Subscription Day Count</label>
          <input
            type="text"
            value={subscriptionDayCount}
            readOnly
            style={styles.input}
          />
        </div>
        <button
          type="submit"
          style={styles.button}
          disabled={isLoading || !orderId.trim() || !startDate || !endDate}
        >
          {isLoading ? "Processing..." : "Update Pause"}
        </button>
      </form>
      {error && <div style={styles.error}>{error}</div>}
      {success && <div style={styles.success}>{success}</div>}
    </div>
  );
};

const NewPauseComponent = () => {
  const [orderId, setOrderId] = useState("");
  const [pauseDate, setPauseDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handlePauseDateChange = (e) => {
    const inputDate = e.target.value;
    const formattedDate = format(new Date(inputDate), "dd/M/yyyy");
    setPauseDate(formattedDate);
  };

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    if (!orderId || !pauseDate) {
      setError("Order ID and Pause Date are required for pausing");
      setIsLoading(false);
      return;
    }

    const payload = {
      orderId: orderId,
      sub_pause: "4",
      pause_date: pauseDate,
    };

    try {
      const response = await fetch(`${BASE_URL}/update-sub-pause`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to pause subscription: ${await response.text()}`);
      }

      setSuccess("Subscription paused successfully");
    } catch (err) {
      console.error("Error pausing subscription:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Pause Subscription</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Order ID</label>
          <input
            type="text"
            value={orderId}
            onChange={handleOrderIdChange}
            style={styles.input}
            required
            placeholder="Enter Order ID"
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Pause Date (DD/M/YYYY)</label>
          <input
            type="date"
            onChange={handlePauseDateChange}
            style={styles.input}
            min={new Date().toISOString().split("T")[0]}
            required
          />
          <span style={styles.dateDisplay}>{pauseDate}</span>
        </div>
        <button
          type="submit"
          style={styles.button}
          disabled={isLoading || !orderId.trim() || !pauseDate}
        >
          {isLoading ? "Processing..." : "Pause Subscription"}
        </button>
      </form>
      {error && <div style={styles.error}>{error}</div>}
      {success && <div style={styles.success}>{success}</div>}
    </div>
  );
};

const SubscriptionPauseComponent = () => {
  const [activeComponent, setActiveComponent] = useState("update");

  return (
    <div style={{minHeight: "100vh"}}>
      {/* <NavigationBar /> */}
      <h1 style={{ textAlign: "center", marginBottom: 24, padding: 20 }}>
        Subscription Pause Management
      </h1>
      <div style={styles.actionToggle}>
        <div
          style={{...styles.toggleButton, ...(activeComponent === "update" ? styles.activeButton : {}), borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px"}}
          onClick={() => setActiveComponent("update")}
        >
          Update Pause
        </div>
        <div
             style={{...styles.toggleButton, ...(activeComponent === "pause" ? styles.activeButton : {}), borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}
          onClick={() => setActiveComponent("pause")}
        >
          Pause
        </div>
      </div>
      {activeComponent === "update" ? <UpdatePauseComponent /> : <NewPauseComponent />}
    </div>
  );
};

const styles = {
  container: {
    margin: "40px auto",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
  },
  input: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "14px",
  },
  button: {
    padding: "10px",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
  },
  error: {
    marginTop: "16px",
    padding: "8px",
    backgroundColor: "#ffebee",
    color: "#c62828",
    borderRadius: "4px",
  },
  success: {
    marginTop: "16px",
    padding: "8px",
    backgroundColor: "#e8f5e9",
    color: "#2e7d32",
    borderRadius: "4px",
  },
  actionToggle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  toggleButton: {
    padding: "10px 20px",
    border: "1px solid #FAC105",
    background: "white",
    color: "black",
    cursor: "pointer",
  },
  activeButton: {
   background: Colors.LinearGradient,
    color: "black",
  },
};

export default SubscriptionPauseComponent;
