import { createSlice, current } from "@reduxjs/toolkit";
import Non_Veg from "../SVG/Non_Veg";
import VegIcon from "../SVG/VegIcon";
var addonsItems = [
    {
        id: 0,
        name: "Chicken Biriyani",
        image: require('../assets/ChickenBiriyani.jpg'),
        vegType: 'Non-Veg',
        type: "Main Course",
        ingredient: "Chicken",
        price: 200
    },

]
var addonTomorrowActive = [
    {
        id: 0,
        status: true,
        vegType: 'Non-Veg',
        name: 'Chicken Biriyani',
        type: 'Main Course',
        ingredient: 'Chicken',
        'price': 180,
        count: 0
    },
    {
        id: 1,
        status: true,
        vegType: 'Veg',
        name: 'Dosa And Sambar',
        'price': 50,
        type: 'Main Course',
        ingredient: 'Vegetable',
        count: 0

    },
    {
        id: 2,
        status: true,
        vegType: 'Non-Veg',
        name: 'Rice And Fish Curry',
        'price': 20,
        type: 'Semi-Gravy',
        ingredient: 'Fish',
        count: 0

    },
    {
        id: 2,
        status: true,
        vegType: 'Non-Veg',
        name: 'Beef Roast',
        'price': 200,
        type: 'Semi-Gravy',
        ingredient: 'Beef',
        count: 0
    },
]
var addonTomorrowInActive = [
    {
        id: 0,
        status: false,
        vegType: 'Non-Veg',
        name: 'Mutton Fry',
        'price': 400,
        type: 'Main Course',
        ingredient: 'Mutton',
        count: 0
    },
    {
        id: 1,
        status: false,
        vegType: 'Veg',
        name: 'Choclate Shake',
        'price': 100,
        type: 'Others',
        ingredient: 'Milk',
        count: 0

    },
    {
        id: 2,
        status: false,
        vegType: 'Non-Veg',
        name: 'Porotta And Beef',
        'price': 200,
        type: 'Main Course',
        ingredient: 'Beef',
        count: 0

    },
    {
        id: 2,
        status: false,
        vegType: 'Non-Veg',
        name: 'Fish Fry',
        'price': 200,
        type: 'Dry Fry',
        ingredient: 'Fish',
        count: 0

    },
]
addonTomorrowInActive = addonTomorrowInActive.map((item) => ({ ...item, checked: false }))
addonTomorrowActive = addonTomorrowActive.map((item) => ({ ...item, checked: false }))
addonsItems = addonsItems.map((item) => ({ ...item, checked: false }))
// console.log(addonTomorrowActive);
const addonsSlice = createSlice({
    name: "addons",
    initialState: {
        addonsItemFull: addonsItems,
        addonsList: addonsItems,
        search: '',
        addonsTomorrowActive: addonTomorrowActive,
        addonsTomorrowInActive: addonTomorrowInActive,
        addonsTomorrowActiveFull: addonTomorrowActive,
        addonsTomorrowInactiveFull: addonTomorrowInActive
    },
    reducers: {
        addonsListItemDelete(state, action) {

        },
        addonsAdded(state, action) {
            const newItem = {
                id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                name: '',
                image: action.payload.image,
                price: action.payload.price,
                ingredient: action.payload.ingredient,
                vegType: action.payload.preference,
                price: 100,
                type: action.payload.type
            }
            state.addonsList = [...state.addonsList, newItem]
        },
        allSelectHandler(state, action) {
            const newData = state.addonsList.map((item) =>
                item ? { ...item, checked: !action.payload } : item
            )
            state.addonsList = newData
        },
        oneItemSelectHandler(state, action) {
            const newData = state.addonsList.map((item) =>
                item.id === +action.payload.event.target.id ? { ...item, checked: !item.checked } : item
            )
            state.addonsList = newData
        },
        deleteHandler(state, action) {
            state.addonsList = state.addonsList.filter(item => !item.checked)
        },
        modalItemClickHandler(state, action) {
            var selected = action.payload
            if (selected === 'All') {
                state.addonsList = state.addonsItemFull
            }
            else {
                const filterData = state.addonsItemFull.filter((item) => {
                    return item.vegType === selected
                })
                state.addonsList = filterData
            }
        },
        addonActiveToInactive(state, action) {
            const newItem = action.payload.activeToInActive
            newItem.map((item) => {
                state.addonsTomorrowInActive.push({
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    name: item.name,
                    price: item.price,
                    type: item.type,
                    ingredient: item.ingredient,
                    count: item.count,
                    status: !item.status,
                    checked: item.checked,
                    vegType: item.vegType
                })
            })
            state.addonsTomorrowActive = action.payload.active

        },
        addonInActiveToActive(state, action) {
            const newItem = action.payload.InactiveToActive
            newItem.map((item) => {
                state.addonsTomorrowActive.push({
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    name: item.name,
                    price: item.price,
                    type: item.type,
                    ingredient: item.ingredient,
                    count: item.count,
                    status: !item.status,
                    checked: item.checked,
                    vegType: item.vegType
                })
            })
            state.addonsTomorrowInActive = action.payload.InActive

        },
        tomorrowActiveAllSelectHandler(state, action) {
            const newData = state.addonsTomorrowActive.map((item) =>
                item ? { ...item, checked: !action.payload } : item
            )
            state.addonsTomorrowActive = newData
        },
        tomorrowActiveOneItemSelectHandler(state, action) {
            const newData = state.addonsTomorrowActive.map((item) =>
                item.id === +action.payload.event.target.id ? { ...item, checked: !item.checked } : item
            )
            state.addonsTomorrowActive = newData
        },
        tomorrowInActiveAllSelectHandler(state, action) {
            const newData = state.addonsTomorrowInActive.map((item) =>
                item ? { ...item, checked: !action.payload } : item
            )
            state.addonsTomorrowInActive = newData
        },
        tomorrowInActiveOneItemSelectHandler(state, action) {
            const newData = state.addonsTomorrowInActive.map((item) =>
                item.id === +action.payload.event.target.id ? { ...item, checked: !item.checked } : item
            )
            state.addonsTomorrowInActive = newData
        },
        tomorrowActiveModalItemClickHandler(state, action) {
            var selected = action.payload
            if (selected === 'All') {
                state.addonsTomorrowActive = state.addonsTomorrowActiveFull
            }
            else {
                const filterData = state.addonsTomorrowActiveFull.filter((item) => {
                    return item.vegType === selected
                })
                state.addonsTomorrowActive = filterData
            }
        },
        tomorrowInActiveModalItemClickHandler(state, action) {
            var selected = action.payload
            if (selected === 'All') {
                state.addonsTomorrowInActive = state.addonsTomorrowInactiveFull
            }
            else {
                const filterData = state.addonsTomorrowInactiveFull.filter((item) => {
                    return item.vegType === selected
                })
                state.addonsTomorrowInActive = filterData
            }
        },
    }
})
export const addonsAction = addonsSlice.actions
export default addonsSlice