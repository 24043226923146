import React from 'react'
import './PrimaryButton.css'

function PrimaryButton({ children, styles, icon, fontStyle,onClick }) {
    return (
        <div className='buttonContainer' style={styles} onClick={onClick}>
            <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                {icon}
                <span className='buttonText' style={fontStyle}>{children}</span>
            </div>
        </div>
    )
}

export default PrimaryButton