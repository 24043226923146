import React from 'react'

function UserPageRoute() {
  return (
    <div><svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 8H2L0 6L2 4H8V1L9 0L10 1V2H16L18 4L16 6H10V8H16L18 10L16 12H10V18C10.5304 18 11.0391 18.2107 11.4142 18.5858C11.7893 18.9609 12 19.4696 12 20H6C6 19.4696 6.21071 18.9609 6.58579 18.5858C6.96086 18.2107 7.46957 18 8 18V8Z" fill="#2F2C5D"/>
    </svg>
    </div>
  )
}

export default UserPageRoute