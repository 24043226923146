import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import New from "./Components/Customer/New/New";
import All from "./Components/Customer/All/All";
import Overview from "./Components/Customer/Overview/Overview";
import CancelledExpired from "./Components/Customer/CancelledExpired/CancelledExpired";
import AddonsList from "./Components/Addons/AddonsList";
import ActiveAddons from "./Components/Addons/ActiveAddons";
import ActiveAddonsToday from "./Components/Addons/ActiveAddonsToday";
import ActiveAddonsTomorrow from "./Components/Addons/ActiveAddonsTomorrow";
import Activeorders from "./Components/Active orders/Activeorders";
import DateSlider from "./Components/Active orders/DateSlider";
import Table from "./Components/Active orders/Table";
import Plans from "./Components/Plans/Plans";
import PlanMain from "./Components/Plans/PlanMain";
import PlanCard from "./Components/Plans/PlanCard";
import ActiveCard from "./Components/Active orders/ActiveCard";
import EditPlan from "./Components/Plans/EditPlan";
import PlanButton from "./Components/Plans/PlanButton";
import PlanButton2 from "./Components/Plans/PlanButton2";
import MainMenu from "./Components/Menu/MainMenu";
import MenuCard from "./Components/Menu/MenuCard";
import MenuEdit from "./Components/Menu/MenuEdit";
import Profile from "./Components/Customer/CustomerProfile/Profile";
import LatestSubscription from "./Components/Customer/CustomerProfile/LatestSubscription";
import DropDown from "./Components/Customer/CustomerProfile/DropDown";
import PreviousSubscriptions from "./Components/Customer/CustomerProfile/PreviousSubscriptions";
import Userdata from "./Components/Overview/Userdata";
import SavedImages from "./Components/Image/SavedImages";
import { OrderHistory } from "./Components/OrderHistory/OrderHistory";
import Delivery from "./Components/Delivery/Delivery";
import WeeklyMenu from "./Components/Weeklymenu/WeeklyMenu";
import TermsAndConditions from "./Home/Components/Navbar/TermsAndConditions";
import Home from "./Home/Home";
import Main from "./Main";
import DashboardHome from "./Home/DashboardHome";
import PrivacyPolicy from "./Home/Components/Navbar/PrivacyPolicy";
import HomeMain from "./Home/HomeMain";
import RequestForm from "./Home/RequestForm";
import NavigationBar from "./NavigationBar";
import TodaysOrder from "./Components/TodaysOrder/TodaysOrder";
import PaymentScreen from "./Components/Payment/PaymentScreen";
import Rating from "./Components/Rating/Rating";
import AddonScreen from "./Components/Addons/AddonScreen";
import UpdatePush from "./Components/PlayStoreUpdate/UpdatePush";
import PauseSubscription from "./Components/PauseSubscription/PauseSubscription";
import SystemInfo from "./Components/SystemInfo/SystemInfo";
import DeliveryCustomerDashboard from "./Components/Delivery/DeliveryCustomerDashboard";
import ChatComponent from "./Components/ChatModule/ChatComponent";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div
          className="app-container"
          style={{ fontFamily: "Dosis, sans-serif" }}
        >
          {/* <div className='sidebar-container'>
            <Sidebar />
          </div> */}
          {/* <Main/> */}
          <div className="main-container">
            <div className="navigation-bar">
              <NavigationBar />
            </div>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/dashboard" element={<DashboardHome />} />
              <Route path="/customer/overview" element={<Overview />} />
              <Route path="/customer/new" element={<New />} />
              <Route path="/customer/all" element={<All />} />
              <Route
                path="/customer/cancelled-expired"
                element={<CancelledExpired />}
              />
              <Route path="/addons/active" element={<ActiveAddons />} />
              <Route path="/addons/list" element={<AddonsList />} />
              <Route
                path="/addons/active/today"
                element={<ActiveAddonsToday />}
              />
              <Route
                path="/addons/active/tomorrow"
                element={<ActiveAddonsTomorrow />}
              />
              <Route path="/activeorders" element={<Activeorders />} />
              <Route path="/dateslider" element={<DateSlider />} />
              <Route path="/table" element={<Table />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/plansmain" element={<PlanMain />} />
              <Route path="/planscard" element={<PlanCard />} />
              <Route path="/activecard" element={<ActiveCard />} />
              <Route path="/editplan" element={<EditPlan />} />
              <Route path="/planbutton" element={<PlanButton />} />
              <Route path="/planbutton2" element={<PlanButton2 />} />
              <Route path="/mainmenu" element={<MainMenu />} />
              <Route path="/menucard" element={<MenuCard />} />
              <Route path="/menuedit" element={<MenuEdit />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/latestsubscription"
                element={<LatestSubscription />}
              />
              <Route
                path="/previoussubscriptions"
                element={<PreviousSubscriptions />}
              />
              <Route path="/dropdown" element={<DropDown />} />
              <Route path="/userdata" element={<Userdata />} />
              <Route path="/savedimages" element={<SavedImages />} />
              <Route path="/orderhistory" element={<OrderHistory />} />
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/weeklymenu" element={<WeeklyMenu />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/home" element={<Home />} />
              <Route path="/homemain" element={<HomeMain />} />
              <Route path="/navbar" element={<NavigationBar />} />
              <Route path="/todays_order" element={<TodaysOrder />} />
              <Route path="/deleteform" element={<RequestForm />} />
              <Route path="/payment" element={<PaymentScreen />} />
              <Route path="/rating" element={<Rating />} />
              <Route path="/addonmain" element={<AddonScreen />} />
              <Route path="/playstoreupdate" element={<UpdatePush />} />
              <Route
                path="/pausesubscription"
                element={<PauseSubscription />}
              />
              <Route path="/systeminfo" element={<SystemInfo />} />
              <Route path="/deliveryuser" element={<DeliveryCustomerDashboard />} />
              <Route path="/chat" element={<ChatComponent/>} />
            </Routes>
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
