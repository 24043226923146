import React, { useState } from "react";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";

const UpdatePush = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");


  const [alertId, setAlertId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [version, setVersion] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [dismissable, setDismissable] = useState(true);
  const [getResponse, setGetResponse] = useState("");
  const [deleteVersion, setDeleteVersion] = useState("");

  const VALID_USERNAME = "admin";
  const VALID_PASSWORD = "update123";

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === VALID_USERNAME && password === VALID_PASSWORD) {
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Invalid credentials");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername("");
    setPassword("");
    setError("");
  };

  const handlePostPush = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updatepush`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          alert_id: alertId,
          title,
          message,
          version,
          timestamp,
          update_url: updateUrl,
          dismissable,
        }),
      });
      if (response.ok) {
        alert("Push notification sent successfully!");
      } else {
        alert("Failed to send push notification");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the push notification");
    }
  };

  const handleGetPush = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updatepush`);
      const data = await response.json();
      setGetResponse(JSON.stringify(data, null, 2));
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while fetching push notifications");
    }
  };

  const handleDeletePush = async () => {
    if (!deleteVersion) {
      alert("Please enter a version to delete");
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/updatepush`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ version: deleteVersion }),
      });
      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setDeleteVersion("");
      } else {
        alert("Failed to delete push notification");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while deleting the push notification");
    }
  };

  // Styles
  const loginContainerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const loginFormStyle = {
    width: "100%",
    maxWidth: "400px",
    padding: "32px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    margin: "8px 0",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const buttonStyleGet = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: "green",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const buttonStyleDelete = {
    width: "100%",
    padding: "10px",
    margin: "8px 0",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const loginButtonStyle = {
    width: "100%",
    padding: "10px",
    background: Colors.LinearGradient,
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "16px",
    color: "black",
  };

  const logoutButtonStyle = {
    padding: "8px 16px",
    background: Colors.LinearGradient,
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    color: "black",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  };

  const titleStyle = {
    // fontSize: "24px",
    // fontWeight: "bold",
    margin: 0,
  };

  const errorStyle = {
    backgroundColor: "#fee2e2",
    color: "#dc2626",
    padding: "12px",
    borderRadius: "4px",
    marginBottom: "16px",
  };

  if (!isAuthenticated) {
    return (
      <div style={loginContainerStyle}>
        <div style={loginFormStyle}>
          <h1 style={{ ...titleStyle, textAlign: "center", marginBottom: "24px" }}>
            Play Store Update Login
          </h1>
          <form onSubmit={handleLogin}>
            <input
              style={inputStyle}
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              style={inputStyle}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <div style={errorStyle}>{error}</div>}
            <button type="submit" style={loginButtonStyle}>
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
        <div style={headerStyle}>
          <h1 style={titleStyle}>Play Store Update</h1>
          <button onClick={handleLogout} style={logoutButtonStyle}>
            Logout
          </button>
        </div>

        <div>
          <h2>Send Push Notification</h2>
          <input
            style={inputStyle}
            placeholder="Alert ID"
            value={alertId}
            onChange={(e) => setAlertId(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
          <input
            style={inputStyle}
            type="datetime-local"
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
          />
          <input
            style={inputStyle}
            placeholder="Update URL"
            value={updateUrl}
            onChange={(e) => setUpdateUrl(e.target.value)}
          />
          <div style={{ marginBottom: "8px" }}>
            <label>
              <input
                type="checkbox"
                checked={dismissable}
                onChange={(e) => setDismissable(e.target.checked)}
              />{" "}
              Dismissable
            </label>
          </div>
          <button style={buttonStyle} onClick={handlePostPush}>
            Send Push Notification
          </button>
        </div>

        <div>
          <h2>Get Push Notifications</h2>
          <button style={buttonStyleGet} onClick={handleGetPush}>
            Fetch Push Notifications
          </button>
          {getResponse && (
            <pre
              style={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                borderRadius: "4px",
                overflowX: "auto",
              }}
            >
              {getResponse}
            </pre>
          )}
        </div>

        <div>
          <h2>Delete Push Notification</h2>
          <input
            style={inputStyle}
            placeholder="Version to delete"
            value={deleteVersion}
            onChange={(e) => setDeleteVersion(e.target.value)}
          />
          <button style={buttonStyleDelete} onClick={handleDeletePush}>
            Delete Push Notification
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePush;