import React from 'react'

function Healthy({styles}) {
    return (
        <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
            <path d="M12.3725 20.6625H12.3275L11.75 20.4C11.39 20.2425 11.0375 20.0625 10.685 19.875C9.98333 19.5022 9.30687 19.0838 8.65999 18.6225C8.00903 18.1617 7.41268 17.6283 6.88249 17.0325C6.6093 16.7339 6.35862 16.4156 6.13249 16.08C5.91804 15.7383 5.7372 15.3766 5.59249 15H5.47999C5.68775 15.8019 6.03011 16.5627 6.49249 17.25C6.95147 17.9613 7.48993 18.618 8.09749 19.2075C8.69864 19.7988 9.34019 20.3477 10.0175 20.85C10.235 21.015 10.445 21.1725 10.67 21.3225C8.79519 20.9738 7.06525 20.0788 5.69749 18.75C4.80617 17.8671 4.09971 16.8155 3.61938 15.6565C3.13906 14.4976 2.8945 13.2545 2.89999 12C2.89999 12 7.61749 11.4375 10.04 14.0175C11.375 15.435 12.0875 18.93 12.365 20.6325L12.3725 20.6625Z" fill="#04B33F" />
            <path d="M21.2 16.05C20.5688 17.4726 19.5879 18.7123 18.3487 19.6539C17.1095 20.5954 15.6522 21.2081 14.1125 21.435L14.4125 21.165C14.8475 20.745 15.2675 20.31 15.665 19.8525C16.0602 19.3951 16.4307 18.9169 16.775 18.42C17.1274 17.9161 17.4288 17.3784 17.675 16.815C17.9114 16.2643 18.1093 15.698 18.2675 15.12C18.4325 14.55 18.5525 13.9725 18.665 13.395C18.8755 12.2471 19.0158 11.0875 19.085 9.92248H18.9575C18.7525 11.0561 18.477 12.1757 18.1325 13.275C17.9525 13.8225 17.7725 14.3625 17.5475 14.8875C17.3307 15.4034 17.0802 15.9045 16.7975 16.3875L16.58 16.74L16.5275 16.8225L16.4675 16.905L16.3475 17.0625C16.1975 17.28 16.0025 17.49 15.83 17.7C15.4585 18.1177 15.0655 18.5157 14.6525 18.8925C14.24 19.26 13.8125 19.6425 13.3625 19.9425C13.1425 20.1075 12.9175 20.265 12.6875 20.415L12.62 20.46C12.7806 19.0639 12.7402 17.6521 12.5 16.2675C12.0892 14.7614 11.46 13.3237 10.6325 12C11.3485 10.3939 12.3241 8.91663 13.52 7.62748C16.52 4.81498 19.295 5.19748 19.295 5.19748C20.6699 6.60334 21.5875 8.39206 21.9275 10.3288C22.2675 12.2656 22.0139 14.2599 21.2 16.05Z" fill="#04B33F" />
            <path d="M12.5 0.96C14.6835 0.96 16.818 1.60748 18.6335 2.82057C20.449 4.03367 21.864 5.75788 22.6996 7.77517C23.5352 9.79247 23.7538 12.0122 23.3279 14.1538C22.9019 16.2953 21.8504 18.2625 20.3065 19.8065C18.7625 21.3504 16.7953 22.4019 14.6538 22.8279C12.5122 23.2538 10.2925 23.0352 8.27518 22.1996C6.25788 21.364 4.53367 19.949 3.32058 18.1335C2.10749 16.318 1.46001 14.1835 1.46001 12C1.46199 9.07262 2.62577 6.26571 4.69574 4.19573C6.76571 2.12576 9.57262 0.961986 12.5 0.96ZM12.5 0C10.1266 0 7.80655 0.703788 5.83316 2.02236C3.85977 3.34094 2.3217 5.21508 1.41345 7.4078C0.505199 9.60051 0.267559 12.0133 0.730582 14.3411C1.1936 16.6689 2.33649 18.807 4.01472 20.4853C5.69295 22.1635 7.83115 23.3064 10.1589 23.7694C12.4867 24.2324 14.8995 23.9948 17.0922 23.0865C19.2849 22.1783 21.1591 20.6402 22.4776 18.6668C23.7962 16.6934 24.5 14.3734 24.5 12C24.5 8.8174 23.2357 5.76515 20.9853 3.51472C18.7348 1.26428 15.6826 0 12.5 0V0Z" fill="#04B33F" />
        </svg>

    )
}

export default Healthy