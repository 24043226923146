import React from "react";
import Add from "../../SVG/Add";
import Archive from "../../SVG/Archive";
import Delete from "../../SVG/Delete";
import InactiveButton from "../UTIL/Buttons/InactiveButton";
import PrimaryButton from "../UTIL/Buttons/PrimaryButton";
import List from "../Tables/Addons/List";
import SearchContainer from "../UTIL/SearchContainer";
import AddonsTab from "./AddonsTab";
import DashboardHome from "../../Home/DashboardHome";
import NavigationBar from "../../NavigationBar";
function AddonsList() {
  const styles = {
    container: {
      padding: "2rem",
    },
    innerContainer: {
      display: "flex",
      // justifyContent: 'space-around',
      flex: 1,
      // alignItems: 'center',
      height: "2.5rem",
    },
    buttonsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    searchOuterContainer: {
      flex: 1,
      marginLeft: "3rem",
    },
  };
  const deleteHandler = () => {};
  return (
    <div>
      <div>
        {/* <NavigationBar /> */}
        <AddonsTab />
        <div style={styles.container}>
          <List />
        </div>
      </div>
    </div>
  );
}

export default AddonsList;
