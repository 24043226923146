import React from "react";
import Healthy from "../../SVG/Healthy";
import Regular from "../../SVG/Regular";
import Diabetic from "../../SVG/Diabetic";
import PlanCard from "./PlanCard";
import Plans from "./Plans";

function PlanMain() {
  return (
    <>
      <div style={{ padding: "30px 80px 0px 30px" }}>
        <h2>Plans</h2>
      </div>

      <div>
        <Plans />
      </div>
    </>
  );
}

export default PlanMain;
