import React from 'react'
import { Colors } from '../../../Colors'
import VegIcon from '../../../SVG/VegIcon'

function SecondaryButton({ text, icon, onClick , inlineStyle}) {
    const styles = {
        container: {
            border: `2px solid ${Colors.yellow100}`,
            padding: '0.5rem 2rem 0.5rem 2rem',
            borderRadius: '0.6rem',
            display: 'flex',
            alignItems: 'center',

        },
        buttonText: {
            marginLeft: '0.2rem'
        }
    }
    return (
        <div style={{ ...styles.container, ...inlineStyle }} id="clickable" onClick={onClick}>
            {icon}
            <span style={styles.buttonText}>{text}</span>
        </div>
    )
}

export default SecondaryButton