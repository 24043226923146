import React from 'react'

function UserPageDirection() {
  return (
    <div><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0V8.5L3 5.5L6 9C7 10 7 11 7 11V17H11V10C11 10 11 9 10.47 8C9.94 7 9 6 9 6L6 2.58L8.5 0M15 0L10.54 4.47L11 5C11 5 11.93 6 12.47 7C12.68 7.4 12.8 7.79 12.87 8.13L18 3" fill="#2F2C5D"/>
    </svg>
    </div>
  )
}

export default UserPageDirection