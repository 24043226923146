import React from "react";
import Non_Veg from "./../../../SVG/Non_Veg";
import VegIcon from "./../../../SVG/VegIcon";
import Regular from "./../../../SVG/Regular";
import Healthy from "./../../../SVG/Healthy";
import More from "./../../../SVG/More";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
import Close from "./../../../SVG/Close";
import DropDown from "./DropDown";

function PreviousSubscriptions({}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="previous_subscriptions">
        <div className="previous_sub1">
          <div>
            Start Date
            <div>10/10/2022</div>
          </div>
          <div>
            End Date
            <div>9/11/2022</div>
          </div>
        </div>
        <div className="previous_sub2">
          <div className="previous_svg">
            <Non_Veg />
          </div>
          <div>Non-Vegetarian</div>
        </div>
        <div className="previous_sub2">
          <div className="previous_svg">
            <Regular styles={{ height: "20px", width: "20px" }} />
          </div>
          <div>Regular</div>
        </div>
        <div className="previous_sub4">× 2 Nos</div>
        <div className="previous_sub5">
          <div className="previous_svg">{!open ? "More" : "Close"}</div>
          {/* More dropdown 1 */}
          <div>
            {!open ? (
              <More onClick={() => setOpen(!open)} />
            ) : (
              <Close onClick={() => setOpen(!open)} />
            )}
          </div>
        </div>
        <div className="previous_sub2">
          <div className="previous_svg">
            <VegIcon />
          </div>
          <div>Vegetarian</div>
        </div>
        <div className="previous_sub2">
          <div className="previous_svg">
            <Healthy styles={{ height: "20px", width: "20px" }} />
          </div>
          <div>Healthy</div>
        </div>
        <div className="previous_sub8">× 2 Nos</div>
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <DropDown />
        </div>
      </Collapse>
      <div className="grid_profilebox8">{!open && <hr></hr>}</div>
    </div>
  );
}

export default PreviousSubscriptions;
