import React from "react";
import "./TermsAndConditions.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy applies to the Lalas Kitchen app (hereby referred to
        as "Application") for mobile devices that was created by bayesianways
        (hereby referred to as "Service Provider") By using Lalas Kitchen, you
        agree to the terms outlined in this Privacy Policy. If you do not agree
        with these terms, please do not use our application.
      </p>

      {/* <h2>Information Collection and Use</h2>
      <p>
        The Application collects information when you download and use it. This
        information may include information such as:
      </p>
      <ul>
        <li>Your device's Internet Protocol address (e.g. IP address)</li>
        <li>
          The pages of the Application that you visit, the time and date of your
          visit, the time spent on those pages
        </li>
        <li>The time spent on the Application</li>
        <li>The operating system you use on your mobile device</li>
      </ul>
      <p>
        The Application does not gather precise information about the location
        of your mobile device.
      </p>

      <p>
        The Application collects your device's location, which helps the Service
        Provider determine your approximate geographical location and make use
        of in below ways: * Geolocation Services: The Service Provider utilizes
        location data to provide features such as personalized content, relevant
        recommendations, and location-based services. * Analytics and
        Improvements: Aggregated and anonymized location data helps the Service
        Provider to analyze user behavior, identify trends, and improve the
        overall performance and functionality of the Application. * Third-Party
        Services: Periodically, the Service Provider may transmit anonymized
        location data to external services. These services assist them in
        enhancing the Application and optimizing their offerings. The Service
        Provider may use the information you provided to contact you from time
        to time to provide you with important information, required notices and
        marketing promotions. For a better experience, while using the
        Application, the Service Provider may require you to provide us with
        certain personally identifiable information, including but not limited
        to LalasKitchenTVM@gmail.com. The information that the Service Provider
        request will be retained by them and used as described in this privacy
        policy.... Content truncated for brevity ...
      </p> */}

      <h2>Third Party Access</h2>
      <p>
        Only aggregated, anonymized data is periodically transmitted to external
        services to aid the Service Provider in improving the Application and
        their service. The Service Provider may share your information with
        third parties in the ways that are described in this privacy statement.
        Please note that the Application utilizes third-party services that have
        their own Privacy Policy about handling data. Below are the links to the
        Privacy Policy of the third-party service providers used by the
        Application: Google Play Services,Expo,Twlio,Razorpay The Service
        Provider may disclose User Provided and Automatically Collected
        Information: as required by law, such as to comply with a subpoena, or
        similar legal process; when they believe in good faith that disclosure
        is necessary to protect their rights, protect your safety or the safety
        of others, investigate fraud, or respond to a government request; with
        their trusted services providers who work on their behalf, do not have
        an independent use of the information we disclose to them, and have
        agreed to adhere to the rules set forth in this privacy statement.
      </p>

      <h2>Opt-Out Rights</h2>
      <p>
        You can stop all collection of information by the Application easily by
        uninstalling it. You may use the standard uninstall processes as may be
        available as part of your mobile device or via the mobile application
        marketplace or network.
      </p>
      <h2>Data Retention Policy</h2>
      <p>
        The Service Provider will retain User Provided data for as long as you
        use the Application and for a reasonable time thereafter. If you'd like
        them to delete User Provided Data that you have provided via the
        Application, please contact them at LalasKitchenTVM@gmail.com and they
        will respond in a reasonable time.
      </p>
      <h2>Children</h2>
      <p>
        The Service Provider does not use the Application to knowingly solicit
        data from or market to children under the age of 13. The Application
        does not address anyone under the age of 13. The Service Provider does
        not knowingly collect personally identifiable information from children
        under 13 years of age. In the case the Service Provider discover that a
        child under 13 has provided personal information, the Service Provider
        will immediately delete this from their servers. If you are a parent or
        guardian and you are aware that your child has provided us with personal
        information, please contact the Service Provider
        (LalasKitchenTVM@gmail.com) so that they will be able to take the
        necessary actions.
      </p>

      <h2>Changes</h2>
      <p>
        This Privacy Policy may be updated from time to time for any reason. The
        Service Provider will notify you of any changes to the Privacy Policy by
        updating this page with the new Privacy Policy. You are advised to
        consult this Privacy Policy regularly for any changes, as continued use
        is deemed approval of all changes.
      </p>

      <p>This privacy policy is effective as of 2025-03-20</p>

      <h2>Your Consent</h2>
      <p>
        By using the Application, you are consenting to the processing of your
        information as set forth in this Privacy Policy now and as amended by
        us.
      </p>

      <h2>Refund Policy</h2>
      <p>Add-Ons:Add-ons purchased are non-refundable.</p>
      <p>
        Subscriptions:Subscriptions can be canceled at any time. Refunds for
        subscriptions will be prorated based on the number of unused days
        remaining in the subscription period. Refunds will be processed within
        5-7 working days and credited to the customer's bank account.
      </p>

      <h2>Shipping Policy</h2>
      <p>
        <p>Shipping Charges:</p>
        <li>
          Shipping charges may apply and will be clearly communicated during the
          checkout process.
        </li>
        <li>
          Free shipping may be available for orders above a certain value, as
          indicated on our Mobile App.
        </li>
      </p>
      <p>Delivery Zones :</p>
      <p>We offer delivery services to the following areas:</p>
      <ul>
        <li>Anayara</li>
        <li>Chacka</li>
        <li>Eanchakkal</li>
        <li>Palkulangara</li>
        <li>Pettah</li>
        <li>Attukal</li>
        <li>Kaithamukku</li>
        <li>Karamana</li>
        <li>Manacaud</li>
        <li>Vanchiyoor</li>
        <li>Keshavadasapuram</li>
        <li>Kuravankonam</li>
        <li>Muttada</li>
        <li>Nalanchira</li>
        <li>Parottukoonam</li>
        <li>Patom</li>
        <li>PMG</li>
        <li>Kowdiar</li>
        <li>Vazhuthacud</li>
        <li>Shasthamangala</li>
        <li>Poojapura</li>
        <li>Museum Road</li>
        <li>Ayurveda College</li>
        <li>Bakery Junction</li>
        <li>General Hospital</li>
        <li>Overbridge</li>
        <li>Palayalam</li>
        <li>Pattor</li>
        <li>Puthenkotta</li>
        <li>Statue</li>
        <li>Thampanoor</li>
        <li>Bapuji Nagar</li>
        <li>Kannamoola</li>
        <li>Kumarapuram</li>
        <li>Kunnukuzhy</li>
        <li>Medical College</li>
        <li>Pothancode</li>
        <li>Puthenpalam</li>
        <li>Pogummoodu</li>
        <li>Ullor</li>
      </ul>

      <p>Order Processing:</p>
      <li>
        Orders are processed and shipped the next day after the order is placed.
      </li>
      <li>Same-day delivery is not available.</li>
      <p>
      1.Breakfast Delivery
      </p>
      <li>
      Minimum Delivery Time: 7:00 AM
      </li>
      <li>Maximum Delivery Time: 9:30 AM</li>
      <p>
      2.Lunch Delivery:
      </p>
      <li>Minimum Delivery Time: 12:00 PM</li>
      <li>Maximum Delivery Time: 2:30 PM</li>
      <p>
      3.Dinner Delivery:
      </p>
      <li>Minimum Delivery Time: 7:00 PM</li>
      <li>Maximum Delivery Time: 9:00 PM</li>
      <p>
        <p>
          Delivery timelines may vary during peak seasons, holidays, or
          unforeseen circumstances such as adverse weather conditions or
          logistical constraints.
        </p>
        <p>
          In such cases, we will make every effort to notify you promptly and
          provide updates on the status of your delivery.
        </p>
      </p>
      <h2>Order Confirmation:</h2>
      <p>
        Upon successful placement of your order, you will receive an order
        confirmation mobile app containing details of your purchase and
        estimated delivery details.
      </p>
      <h2>Razorpay's Refund Policy</h2>
      <p>
        For information on Razorpay's refund policy, please refer to Razorpay's
        terms of service or contact Razorpay directly for assistance.
        <a href="https://razorpay.com/">Razorpay</a>
      </p>
      <h2>Information We Collect</h2>
      <p>
        Personal Information: We may collect personal information such as your
        full name, phone number, address, and delivery location to facilitate
        the ordering and delivery process.
      </p>
      <p>How We Use Your Information</p>
      <li>Process and fulfill orders you place through our app.</li>
      <li>Improve our services and enhance the user experience.</li>
      <h2>Data Security</h2>
      <p>
        We take the security of your data seriously. Lalas Kitchen employs
        industry-standard security measures to protect your personal information
        from unauthorized access, alteration, disclosure, or destruction. We use
        encryption, firewalls, and secure socket layer technology (SSL) to
        safeguard your data during transmission.
      </p>
      <h2>Account Deletion</h2>
      <p>
        If you wish to delete your Lalas Kitchen account and remove your
        personal information from our systems, please fill out the account
        deletion form <a href="https://lalaskitchen.in/deleteform">here.</a>{" "}
        Once we receive your request, we will process it promptly and securely
        delete your account and associated data from our database.
      </p>
      <p>
        You can delete your Lalas Kitchen account directly from the mobile app.
        Simply follow these steps:
      </p>
      <li>Open the Lalas Kitchen app on your mobile device.</li>
      <li>profile section of the app.</li>
      <li>Select the option to delete your account.</li>
      <li>Follow the on-screen instructions to confirm the deletion.</li>
      <p>
        Once you confirm the deletion of your account, your personal information
        will be securely deleted from our systems.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions regarding privacy while using the Application,
        or have questions about the practices, please contact via email at{" "}
        <a href="mailto:LalasKitchenTVM@gmail.com">LalasKitchenTVM@gmail.com</a>
        .
      </p>

      {/* <p>
        This privacy policy page was generated by{" "}
        <a href="">App Privacy Policy Generator</a>
      </p> */}
    </div>
  );
};

export default PrivacyPolicy;
