import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { AiOutlineSortAscending } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Colors } from '../../../Colors'
import CheckBoxSort from '../../../SVG/CheckBoxSort'
import DateSort from '../../../SVG/DateSort'
import RadioButtonSort from '../../../SVG/RadioButtonSort'
import TableFooter from '../../Create/TableFooter'
import useTable from '../../Create/useTable'
import Selector from '../../Selector/Selector'
import moment from 'moment'
import ClearFilter from '../../../SVG/ClearFilter'
import Delete from '../../../SVG/Delete'
import Archive from '../../../SVG/Archive'
function CancelledOrExpired({ selectorValue }) {
    const styles = {
        container: {
            flex: 1,

        },
        table: {
            border: '1px solid #E9E9E9',
            color: Colors.deepBlue100
        },
        tableHead: {
            backgroundColor: Colors.yellow25,
        },
        tableBody: {
            backgroundColor: 'white',
        },
        icon: {
            cursor: "pointer"
        },
        checkBoxContainer: {
            width: '250px',
            boxShadow: '0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: '#FFFCEB',
        },
        innerContainer: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1rem 0 1rem 0',
            height: '3rem',

        },
        selectorContainer: {
            flex: 1,
            marginRight: '1rem',

        },
        searchOuterContainer: {

            display: 'flex',
            flex: 1,
        },

        searchContainer: {
            flex: 12,
        },

        searchIconContainer: {
            flex: 1,
            backgroundColor: '#FFD814',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0 0.5rem 0.5rem 0',
        },

        searchInput: {
            border: '2px solid #FFD814',
            padding: '0.5rem',
            width: '100%',
            borderRadius: ' 0.5rem 0 0 0.5rem',
            height: '3rem'

        },
        radioButtonContainer: {
            width: '250px',
            boxShadow: ' 0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: '#FFFCEB',
        },
        radioItemContainer: {
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        changesContainer: {
            height: '2.3rem',
            marginBottom: '1rem',
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between'
        },
        clearfilterContainer: {
            flex: 1,
            backgroundColor: Colors.grey95,
            border: `1px solid ${Colors.grey84}`,
            borderRadius: '0.5rem',
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
            cursor: "pointer"
        },
        changeText: {
            fontSize: '12px',
            color: Colors.grey64,
            marginLeft: '0.5rem'
        },
        changesInnerContainer: {
            flex: 8,
            display: "flex"
        },
        archiveContainer: {
            backgroundColor: Colors.grey95,
            border: `1px solid ${Colors.grey84}`,
            borderRadius: '0.5rem',
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
            padding: ' 1rem 2rem 1rem 2rem',
            cursor: "pointer",


        },
        deleteContainer: {
            backgroundColor: Colors.grey95,
            border: `1px solid ${Colors.grey84}`,
            borderRadius: '0.5rem',
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
            padding: ' 1rem 2rem 1rem 2rem',
            marginLeft: '1rem',
            cursor: "pointer"
        }
    }
    const tableData = useSelector(state => state.customer.customerCancelledOrExpired)
    const CATEGORIES = ["Regular", 'Diabetic', 'Healthy']
    const [data, setData] = useState([...tableData])
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("")
    const [Rmodal, setRModal] = useState(false)
    const [Cmodal, setCModal] = useState(false)
    const [checked, setChecked] = useState(false)
    const [joinDateSort, setJoinDateSort] = useState(false)
    const [expireDateSort, setExpireDateSort] = useState(false)
    const [sort, setSort] = useState(false)
    const { slice, range } = useTable(data, page, 3);
    const [active, setActive] = useState('')
    const [deleteData, setDeleteData] = useState([])
    const [state, setState] = useState({
        productsItem: data,
        filters: new Set(),
    })
    const [select, setSelect] = useState({
        list: data
    })
    const checkedHandler = () => {
        setChecked(!checked)
        const newData = data.map((item) =>
            item ? { ...item, checked: !checked } : item
        )
        setData(newData)
    }
    const ff = []
    const checkedOneHandler = (id, event) => {
        setData(prevState => {
            return prevState.map(
                li =>
                (li.id === +event.target.id ? {
                    ...li,
                    checked: !li.checked
                } : li)
            )

        });
        // setData(select.list)
    }
    console.log(select.list);
    const sortNameHandler = () => {
        setSort(!sort)
    }
    useEffect(() => {
        setData(tableData)
    }, [])
    useEffect(() => {
        const sortedArray = data.sort(function (a, b) {
            const x = a.name;
            const y = b.name;
            return sort ? (x > y ? 1 : -1) : (x < y ? 1 : -1)
        }).map(prod => {
            return prod
        })
        setData(sortedArray)
    }, [sort])
    const handleFilterChange = useCallback(event => {
        setState(previousState => {
            let filters = new Set(previousState.filters)
            let productsItem = data

            if (event.target.checked) {
                filters.add(event.target.value)

            } else {
                filters.delete(event.target.value)
            }

            if (filters.size) {
                productsItem = data.filter(product => {
                    return product.plans.some((tag) => filters.has(tag))
                })
            }

            return {
                filters,
                productsItem,
            }
        })

    }, [setState])
    const checkBoxModalHandler = () => {
        setCModal(!Cmodal)
    }
    useEffect(() => {
        setData(state.productsItem)
    }, [state.filters, state.productsItem])

    const handleChange = (e) => {
        setSearch(e.target.value)

    }
    const radioButtonModalHandler = () => {
        setRModal(!Rmodal)
    }
    const handleRadioFilterChange = (event) => {
        const status = event.target.value
        const filterData = tableData.filter((datas) =>
            datas.status == status
        )
        setData(filterData)

    }
    const joindateSortHandler = () => {
        setJoinDateSort(!joinDateSort)
    }
    const expiredateSortHandler = () => {
        setExpireDateSort(!expireDateSort)
    }
    useEffect(() => {
        const sortedArray = data.sort(function (a, b) {
            var c = new Date(a.joinDate);
            var d = new Date(b.joinDate);
            return joinDateSort ? c > d ? 1 : -1 : c < d ? 1 : -1;
        }).map(prod => {
            return prod
        })
        setData(sortedArray)
    }, [joinDateSort])
    useEffect(() => {
        const sortedArray = data.sort(function (a, b) {
            var c = new Date(a.expireDate);
            var d = new Date(b.expireDate);
            return expireDateSort ? c > d ? 1 : -1 : c < d ? 1 : -1;
        }).map(prod => {
            return prod
        })
        setData(sortedArray)
    }, [expireDateSort])
    const selectorValuesHandler = (event) => {
        if (event === 0) {
            const yesterday = new Date(Date.now() - 864e5);
            const yesterdayFilter = tableData.filter((item) => {
                return new Date(item.joinDate).toDateString() === yesterday.toDateString()
            })
            setData(yesterdayFilter)
        }
        if (event === 1) {
            const daysAgo = []
            for (var i = 0; i <= 7; i++) {
                daysAgo[i] = moment().subtract(i, 'days').format("ddd MMM DD YYYY")
            }
            const weekFilter = tableData.filter(person => daysAgo.includes(new Date(person.joinDate).toDateString()))
            setData(weekFilter)
        }
        if (event === 2) {
            const months = []
            for (var i = 0; i <= 30; i++) {
                months[i] = moment().subtract(i, 'days').format("ddd MMM DD YYYY")
            }
            const monthFilter = tableData.filter(person => months.includes(new Date(person.joinDate).toDateString()))
            setData(monthFilter)
        }

    }
    const setActiveHandler = (event) => {
        // console.log(event);
    }
    const clearFilterHandler = () => {
        setData(tableData)
        setActive('clear')
    }
    const deleteHandler = () => {
        setData(prevState => {
            return prevState.filter(li => !li.checked)

        });
    }
    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <div style={styles.selectorContainer}>
                    <Selector selectorValue={selectorValuesHandler} active={active} setActive={setActive} />
                </div>
                <div style={styles.searchOuterContainer}>
                    <div style={styles.searchContainer}>
                        <input type="text" placeholder='search...' style={styles.searchInput} value={search} onChange={handleChange} />
                    </div>
                    <div style={styles.searchIconContainer}>
                        <BsSearch />
                    </div>
                </div>
            </div>
            <div style={styles.changesContainer}>
                <div style={styles.changesInnerContainer}>
                    <div style={styles.archiveContainer} id="clickable">
                        <Archive />
                        <span style={styles.changeText}>Archive</span>
                    </div>
                    <div style={styles.deleteContainer} id="clickable" onClick={deleteHandler}>
                        <Delete />
                        <span style={styles.changeText}>Delete</span>
                    </div>
                </div>
                <div style={styles.clearfilterContainer} onClick={clearFilterHandler} id="clickable">
                    <ClearFilter />
                    <span style={styles.changeText}>Clear Filter</span>
                </div>
            </div>

            
            <Table style={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th>
                            <input type="checkbox" checked={checked} onChange={checkedHandler} />
                        </th>
                        <th>Phone
                        </th>
                        <th>Name
                            <AiOutlineSortAscending style={styles.icon} onClick={sortNameHandler} id="clickable" />
                        </th>
                        <th>Plans
                            <CheckBoxSort style={styles.icon} onClick={() => checkBoxModalHandler()} id="clickable" />
                            {Cmodal && <div style={styles.checkBoxContainer}>
                                {CATEGORIES.map(category => (
                                    <label>
                                        <input
                                            onChange={handleFilterChange}
                                            type="checkbox"
                                            value={category} />
                                        {category}
                                    </label>
                                ))}
                            </div>}
                        </th>
                        <th>Status
                            <RadioButtonSort style={styles.icon} onClick={() => radioButtonModalHandler()} id="clickable" />
                            {Rmodal && <div style={styles.radioButtonContainer}>
                                <div style={styles.radioItemContainer}>
                                    <label>
                                        <input
                                            onChange={handleRadioFilterChange}
                                            type="radio"
                                            name="status"
                                            value={"Cancelled"} />
                                        Cancelled
                                    </label>
                                </div>
                                <div style={styles.radioItemContainer}>
                                    <label>
                                        <input
                                            onChange={handleRadioFilterChange}
                                            type="radio"
                                            name="status"
                                            value={"Expired"} />
                                        Last Date
                                    </label>
                                </div>

                            </div>}
                        </th>
                        <th>Join Date <DateSort style={styles.icon} onClick={joindateSortHandler} id="clickable" /></th>
                        <th>Expired Date <DateSort style={styles.icon} onClick={expiredateSortHandler} id="clickable" /></th>
                    </tr>
                </thead>
                <tbody style={styles.tableBody}>
                    {slice.filter((val) => {
                        //val.name.toLowerCase().includes(search.toLowerCase())
                        if (val.phone.toLowerCase().includes(search.toLowerCase()) || val.name.toLowerCase().includes(search.toLowerCase())) {
                            return val
                        }
                    }).map((item, id) => (
                        <tr key={id}>
                            <td><input type="checkbox" checked={item.checked} id={item.id} onChange={(e) => checkedOneHandler(id, e)} /></td>
                            <td>{item.phone}</td>
                            <td>{item.name}</td>
                            <td>{item.plans.map(u => <><span>{u}</span><br /></>)}</td>
                            <td>{item.status}</td>
                            <td>{item.joinDate}</td>
                            <td>{item.expireDate}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </div>
    )
}

export default CancelledOrExpired