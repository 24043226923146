import React from "react";
import "./Profile.css";
import ProgressBar from "@ramonak/react-progress-bar";
import Non_Veg from "../../../SVG/Non_Veg";
import Regular from "./../../../SVG/Regular";
import Diabetic from "./../../../SVG/Diabetic";
import Active from "./../../../SVG/Active";
import Polygon from "./../../../SVG/Polygon";
function LatestSubscription() {
  return (
    <div className="latest_sub">
      <div
        className="latest_sub1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
        }}
      >
        <div>10/11/2022</div>
        <div>
          21 Days <br /> Remaining
        </div>
        <div>10/12/2022</div>
      </div>
      <div className="latest_sub2">
        <Active /> Active
        <div style={{ marginTop: "6px" }}>
          <ProgressBar
            completed={50}
            bgColor="#04CC48"
            height="8px"
            labelAlignment="left"
            labelColor="#e8090"
            labelSize="0px"
            baseBgColor="#EBFFF1"
          />
        </div>
      </div>
      <div className="latest_sub3" style={{ fontWeight: 700 }}>
        Payment Details
      </div>
      <div
        className="latest_sub4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "20px",
          fontSize: "12px",
        }}
      >
        <div>Transaction ID</div>
        <div>Payment Method</div>
        <div>Payment Date</div>
      </div>
      <div className="latest_sub5">
        <div className="latest_token1">
          <div>
            <div style={{ fontWeight: 700 }}> Token 1</div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "6px",
              }}
            >
              <div>
                <Non_Veg /> Non-Vegetarian
              </div>
              <div>
                <Regular styles={{ height: "20px", width: "20px" }} /> Regular
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6px",
              }}
            >
              <div>
                <Polygon /> Breakfast
              </div>
              <div>
                <Polygon /> Lunch
              </div>
              <div>
                <Polygon /> Dinner
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6px",
              }}
            >
              <div>× for 2</div>
            </div>
          </div>
        </div>
        <div className="latest_token2">
          <div>
            <div style={{ fontWeight: 700 }}>Token 2</div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "6px",
              }}
            >
              <div>
                <Non_Veg />
                Non-Vegetarian
              </div>
              <div>
                <Diabetic styles={{ height: "20px", width: "20px" }} /> Diabetic
                Diet
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginRight: "50px",
                marginTop: "6px",
              }}
            >
              <div>
                <Polygon /> Breakfast
              </div>
              <div>
                <Polygon /> Lunch
              </div>
              <div>
                <Polygon /> Dinner
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6px",
              }}
            >
              <div>× for 1</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="latest_sub6"
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        <div>002547823645921</div>
        <div>Google Pay</div>
        <div>9 Nov 2022</div>
      </div>
      <div
        className="latest_sub7"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "20px",
          fontSize: "12px",
        }}
      >
        <div>Total Per Day Cost</div>
        <div>Total Amount Paid</div>
      </div>
      <div
        className="latest_sub8"
        style={{
          display: "flex",
          justifyContent: "space-around",
          fontWeight: 700,
          fontSize: "12px",
        }}
      >
        <div>₹90</div>
        <div>₹720</div>
      </div>
    </div>
  );
}

export default LatestSubscription;
