import React, { useEffect, useRef, useState } from 'react'
import { Colors } from '../Colors'
import ImageEdit from '../SVG/ImageEdit'
import VegIcon from '../SVG/VegIcon'
import Non_Veg from '../SVG/Non_Veg'
import PrimaryButton from './UTIL/Buttons/PrimaryButton'
import SecondaryButton from './UTIL/Buttons/SecondaryButton'
import Save from '../SVG/Save'
import Cancel from '../SVG/Cancel'
import Switch from "react-switch";
import Toggle from 'react-toggle'
import { useDispatch } from 'react-redux'
import { addonsAction } from '../store/addons'

const typeItems = [, 'Select Type', 'Dry Fry', 'Semi Gravy', 'Main course', 'Gravy', 'Side Dish', 'Desserts', 'Drinks']
const ingredientItems = ['Select Ingredient', 'Chicken', 'Fish', 'Beef', 'Veg', 'Egg', 'Others']
function AddItem({ addModal, setAddModal }) {
    const styles = {
        container: {
            margin: '2rem',
            backgroundColor: 'white',
            borderRadius: '1rem',
            width: '800px'
        },
        h2: {
            fontSize: '16px',
            fontWeight: "700"
        },
        innerContainer: {
            display: "flex",
            justifyContent: 'space-between',

        },
        preferenceContainer: {

        },
        detailsContainer: {
            width: '48%'
        },
        imageContainer: {
            height: '352px',
            width: '48%'
        },
        image: {
            borderRadius: '1rem',
            height: '100%',
            width: '100%'
        },
        imageEditContainer: {
            position: 'absolute',
            marginLeft: '28%',
            top:380,
            cursor: "pointer"
        },
        preferenceContainer: {
            display: "flex",
            justifyContent: 'space-between',
            flex: 1,
            border: `2px solid ${Colors.Primary}`,
            borderRadius: '0.5rem',
            height: '2.5rem',
            width: '100%'
        },
        itemStyle: {
            padding: '1rem 2rem 1rem 2rem',
            flex: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
        },
        itemText: {
            fontSize: '13px',
        },
        dropDownContainer: {
            border: `2px solid ${Colors.Primary}`,
            borderRadius: '0.5rem',
            height: '2.5rem',
            width: '100%'
        },
        detailsItemContainer: {
            marginTop: "2rem",
        },
        priceContainer: {
            border: `1px solid ${Colors.Primary}`,
            borderRadius: '0 0.5rem 0.5rem 0',
            height: '2.5rem',
            width: '100%',
            padding: '0.5rem',
            outlineStyle: 'none'
        },
        priceOuterContainer: {
            display: "flex",
            border: `1px solid ${Colors.Primary}`,
            borderRadius: '0.5rem'
        },
        priceRupeeContainer: {
            border: `1px solid ${Colors.Primary}`,
            borderRadius: '0.5rem 0 0 0.5rem',
            height: '2.5rem',
            padding: '0.5rem',
            backgroundColor: Colors.yellow1,
        },
        actionContainer: {
            // height: '2.5rem',
            width: '100%',
            display: 'flex',
            marginTop: '1rem'
        },
        defaultContainer: {
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        },
        saveCancelContainer: {
            display: "flex",
            alignItems: 'center',
            width: '50%',
            justifyContent: 'space-around',
        },
        textContainer: {
            marginLeft: '1rem'
        },
        boldText: {
            fontWeight: 600
        },
        title: {
            marginTop: 20
        }
    }
    const preferenceItems = [
        {
            icon: <VegIcon />,
            type: 'Vegeterian'
        },
        {
            icon: <Non_Veg />,
            type: 'Non-Vegeterian'
        },
    ]
    const ref = useRef()
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(false)
    const [close, setClose] = useState(true)
    const [image, setImage] = useState(null)
    const [active, setActive] = useState(null)
    const [preference, setPreference] = useState(null)
    const [type, setType] = useState(null)
    const [ingredient, setIngredient] = useState(null)
    const [price, setPrice] = useState(null)
    const handleClick = (e) => {
        ref.current.click()
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }
    const clickHandler = (index) => {

        for (var i = 0; i < preferenceItems.length; i++) {
            if (i === index) {
                setActive(i)
            }
        }
    }
    useEffect(() => {
      
        if (active === 0) {
            setPreference('Veg')
        }
        if (active === 1) {
            setPreference('Non-Veg')
        }
    }, [active])
    const cancelHandler = () => {
        setAddModal(false)
    }

    const saveHandler = () => {
        dispatch(addonsAction.addonsAdded({
            preference: preference,
            type: type,
            ingredient: ingredient,
            price: price,
            image: image
        }))
    }
    return (
        <div style={styles.container}>
            <h2 style={styles.h2} >Add new Item</h2>
            <div style={styles.innerContainer}>
                <div style={styles.detailsContainer}>
                    <span style={styles.title}>Select Preference</span>
                    <div style={styles.preferenceContainer}>
                        {preferenceItems.map((item, index) => (
                            <div
                                key={index}
                                style={{ ...styles.itemStyle, ...{ background: active === index ? Colors.LinearGradient : "" } }}
                                onClick={() => clickHandler(index)}>
                                <span style={{ marginRight: '0.5rem' }}>{item.icon}</span>
                                <span style={styles.itemText}>
                                    {item.type}
                                </span>

                            </div>
                        ))}
                    </div>
                    <div style={styles.detailsItemContainer}>
                        <span style={styles.title}>Type</span>
                        <select style={styles.dropDownContainer} value={type} onChange={(e) => setType(e.target.value)}>
                            {typeItems.map((item, index) => (
                                <option key={index} style={styles.dropDownContainer} value={item} >{item}</option>
                            ))}

                        </select>
                    </div>
                    <div style={styles.detailsItemContainer}>
                        <span style={styles.title}>Ingredient</span>
                        <select style={styles.dropDownContainer} onChange={(e) => setIngredient(e.target.value)} value={ingredient}>
                            {ingredientItems.map((item, index) => (
                                <option key={index} style={styles.dropDownContainer} value={item} >{item}</option>
                            ))}

                        </select>
                    </div>
                    <div style={styles.detailsItemContainer}>
                        <span style={styles.title}>Price</span>
                        <div style={styles.priceOuterContainer}>
                            <div style={styles.priceRupeeContainer}>Rs</div>
                            <input type="number" style={styles.priceContainer} placeholder="Price" onChange={(e) => setPrice(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div style={styles.imageContainer}>
                    <img src={image} style={styles.image} />
                    <div style={styles.imageEditContainer} onClick={handleClick}>
                        <ImageEdit style={styles.imageEdit} />
                        <input type="file" ref={ref} style={{ display: 'none' }} onChange={onImageChange} />
                    </div>

                </div>
            </div>
            <div style={styles.actionContainer}>
                <div style={styles.defaultContainer}>
                    <div>
                        <Switch
                            checked={checked}
                            onChange={(val) => setChecked(val)}
                            onColor={Colors.yellow100}
                            onHandleColor={Colors.yellow100}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                        />
                    </div>
                    <div style={styles.textContainer}>
                        <span style={styles.boldText}>Set as Default Add-On</span>
                        <p>Everyday default Add-Ons will be automatically added to the ‘Active Add-Ons’ list.</p>
                    </div>
                </div>
                <div style={styles.saveCancelContainer}>
                    <SecondaryButton text={"Cancel"} icon={<Cancel />} onClick={cancelHandler} />
                    <PrimaryButton text={"Save"} icon={<Save />} onClick={saveHandler} />
                </div>
            </div>
        </div>
    )
}

export default AddItem

