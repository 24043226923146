
import React from 'react'

const Regular = ({styles}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
            <path d="M11.325 17.7525C11.325 17.7525 11.52 17.5725 12.195 17.055C12.87 16.5375 13.275 15.8175 14.25 15.4275C15.225 15.0375 16.3575 15.6 16.7925 15.75C17.348 15.0386 17.8494 14.2864 18.2925 13.5L16.8825 16.0125C16.5298 16.5883 16.0521 17.0775 15.4848 17.4436C14.9174 17.8097 14.2749 18.0434 13.605 18.1275C12.8274 18.1584 12.0517 18.0308 11.325 17.7525V17.7525Z" fill="#502197" />
            <path d="M13.2825 9.8025C13.5101 8.93969 13.8802 8.12093 14.3775 7.38C14.8841 6.87604 15.5243 6.52735 16.2225 6.375C16.3422 5.73366 16.4149 5.08443 16.44 4.4325C16.44 4.4325 16.3875 6.41249 16.3875 6.51749C16.4596 7.07409 16.3741 7.63974 16.1408 8.15017C15.9074 8.66061 15.5356 9.09535 15.0675 9.405C14.5283 9.72003 13.9043 9.85899 13.2825 9.8025Z" fill="#502197" />
            <path d="M13.1851 13.6125C13.6043 12.6611 14.1797 11.7865 14.8876 11.025C15.5807 10.522 16.3971 10.2162 17.2501 10.14C17.5575 9.42581 17.8083 8.68853 18.0001 7.935C18.0001 7.935 17.4751 10.185 17.4526 10.3275C17.3906 10.9441 17.1836 11.5373 16.8486 12.0587C16.5137 12.5801 16.0601 13.0148 15.5251 13.3275C14.7816 13.6183 13.9766 13.7164 13.1851 13.6125V13.6125Z" fill="#502197" />
            <path d="M13.1775 7.16999C12.6564 6.6357 12.2258 6.02015 11.9025 5.34749C11.7823 4.73422 11.8396 4.09938 12.0675 3.51749C12.0675 3.43499 12 2.39999 12 2.39999C12.0645 2.76247 12.1598 3.11877 12.285 3.46499C12.6556 3.6881 12.9687 3.99496 13.1993 4.36096C13.4298 4.72696 13.5713 5.1419 13.6125 5.57249C13.5996 6.13245 13.4503 6.68081 13.1775 7.16999V7.16999Z" fill="#502197" />
            <path d="M11.325 17.67C11.2805 16.4401 11.0608 15.2228 10.6725 14.055C10.2421 13.3217 9.59486 12.74 8.82 12.39C8.5804 11.5214 8.39009 10.64 8.25 9.75C8.25 9.75 8.61 12.4125 8.5725 12.5325C8.37463 13.2089 8.33257 13.9213 8.44948 14.6162C8.56639 15.3112 8.83922 15.9706 9.2475 16.545C9.82225 17.1054 10.5416 17.4949 11.325 17.67V17.67Z" fill="#502197" />
            <path d="M13.2525 9.82501C13.1075 8.97922 12.8237 8.16321 12.4125 7.41001C11.9732 6.88031 11.3895 6.4895 10.7325 6.28501C10.5772 5.66123 10.4669 5.0271 10.4025 4.38751C10.4025 4.38751 10.5675 6.30751 10.56 6.40501C10.4491 6.93288 10.4849 7.48102 10.6634 7.99002C10.8418 8.49902 11.1562 8.94945 11.5725 9.29251C12.0633 9.64075 12.6507 9.82692 13.2525 9.82501Z" fill="#502197" />
            <path d="M13.185 13.59C12.9189 12.5859 12.488 11.633 11.91 10.77C11.3136 10.1734 10.5674 9.74849 9.75 9.54C9.56862 8.78528 9.44328 8.01819 9.375 7.245C9.375 7.245 9.5475 9.5775 9.5475 9.69C9.51176 10.313 9.62433 10.9356 9.87596 11.5067C10.1276 12.0777 10.5111 12.581 10.995 12.975C11.6639 13.3556 12.4158 13.5667 13.185 13.59V13.59Z" fill="#502197" />
            <path d="M7.21504 20.25C8.19754 20.3625 9.18754 20.4675 10.1625 20.505C10.6341 20.5427 11.1086 20.515 11.5725 20.4225C12.0296 20.3039 12.4723 20.1354 12.8925 19.92C13.7838 19.4343 14.7494 19.0998 15.75 18.93C16.7455 18.8118 17.7496 18.7842 18.75 18.8475C17.7617 18.8688 16.7775 18.9818 15.81 19.185C15.3361 19.2848 14.8772 19.4462 14.445 19.665C14.0175 19.89 13.6125 20.175 13.17 20.415C12.7029 20.6758 12.1962 20.8583 11.67 20.955C11.1555 21.0334 10.6321 21.0334 10.1175 20.955C9.11843 20.8437 8.13174 20.6404 7.17004 20.3475L7.21504 20.25Z" fill="#502197" />
            <path d="M13.1475 6.13501C13.275 7.54501 13.38 8.96251 13.4325 10.38C13.5422 11.807 13.4411 13.2424 13.1325 14.64C12.7702 16.0472 12.0221 17.3252 10.9725 18.33C10.4474 18.8295 9.86303 19.2628 9.23248 19.62C8.6235 20.0047 7.94186 20.26 7.22998 20.37H7.16248C7.15301 20.3711 7.14342 20.3701 7.13434 20.3672C7.12526 20.3643 7.11691 20.3595 7.10982 20.3531C7.10274 20.3467 7.09708 20.3389 7.09323 20.3302C7.08938 20.3215 7.08742 20.312 7.08748 20.3025C7.08943 20.2915 7.09381 20.2811 7.1003 20.272C7.10678 20.2629 7.11522 20.2554 7.12498 20.25V20.25C7.45907 19.3682 7.56484 18.4162 7.43248 17.4825C7.35837 16.5379 7.10377 15.6162 6.68248 14.7675C6.44166 14.3314 6.22873 13.8805 6.04498 13.4175C5.87027 12.9368 5.78141 12.429 5.78248 11.9175C5.86139 10.9265 6.16673 9.96689 6.67498 9.11251V9.11251C6.19504 9.96765 5.93718 10.9295 5.92498 11.91C5.9478 12.3915 6.0677 12.8635 6.27748 13.2975C6.49903 13.7242 6.74962 14.1352 7.02748 14.5275C7.59172 15.3875 7.92018 16.3807 7.97998 17.4075C8.09459 18.4437 7.82798 19.4861 7.22998 20.34V20.34L7.16998 20.25C7.44699 20.0268 7.7657 19.8611 8.10748 19.7625C8.41498 19.6125 8.74498 19.4775 9.04498 19.305C9.65105 18.962 10.2149 18.5492 10.725 18.075C11.7471 17.1268 12.4825 15.9106 12.8475 14.565C13.1679 13.1971 13.2867 11.7897 13.2 10.3875C13.2 8.97001 13.1325 7.55251 13.05 6.14251C13.0539 6.13277 13.0606 6.12441 13.0693 6.11853C13.078 6.11264 13.0882 6.1095 13.0987 6.1095C13.1092 6.1095 13.1195 6.11264 13.1282 6.11853C13.1369 6.12441 13.1436 6.13277 13.1475 6.14251V6.13501Z" fill="#502197" />
            <path d="M12 0.9C14.1954 0.9 16.3414 1.551 18.1668 2.77069C19.9922 3.99037 21.4149 5.72395 22.2551 7.75221C23.0952 9.78047 23.315 12.0123 22.8867 14.1655C22.4584 16.3187 21.4013 18.2965 19.8489 19.8489C18.2965 21.4012 16.3187 22.4584 14.1655 22.8867C12.0123 23.315 9.78048 23.0952 7.75222 22.2551C5.72396 21.4149 3.99038 19.9922 2.77069 18.1668C1.55101 16.3414 0.900005 14.1954 0.900005 12C0.903974 9.05732 2.07471 6.23629 4.1555 4.1555C6.2363 2.0747 9.05732 0.903969 12 0.9V0.9ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519937 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2349 1.26428 15.1826 0 12 0V0Z" fill="#502197" />
        </svg>


    )
}

export default Regular
