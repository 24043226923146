import React from 'react'
import CancelledOrExpired from '../../Tables/customer/CancelledOrExpired'
import CustomerTable from '../../Tables/customer/ExpireInThreeDays'
import Customer from '../CustomerMain/Customer'

function CancelledExpired() {
    const styles = {
        container: {
            padding: '2rem',
        },
        headerText: {
            fontSize: '24px',
            fontWeight: '700'
        },
        subHeaderText: {
            fontSize: '16px',
            fontWeight: '700'
        }
    }
    return (
        <div>
            <Customer />
            <div style={styles.container}>
                <h2 style={styles.headerText}>Users with Plans Expiring in the Next Three Days</h2>
                <p style={styles.subHeaderText}>16 November 2022 - 18 November 2022</p>
                <CustomerTable />
                <div>
                    <h2 style={styles.headerText}>Cancelled & Expired Users</h2>
                    <CancelledOrExpired />
                </div>
            </div>
        </div>
    )
}

export default CancelledExpired