import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import { Colors } from "../../../src/Colors";

function PlanButton() {
  const styles = {
    detailsItemContainer: {
      // marginTop: "2rem",
    },
    priceContainer: {
      border: `1px solid ${Colors.Primary}`,
      borderRadius: '0 0.5rem 0.5rem 0',
      // height: '2.5rem',
      width: '100%',
      // padding: '0.5rem',
      outlineStyle: 'none'
    },
    priceOuterContainer: {
      display: "flex",
      border: `1px solid ${Colors.Primary}`,
      borderRadius: '0.5rem'
    },
    priceRupeeContainer: {
      border: `1px solid ${Colors.Primary}`,
      borderRadius: '0.5rem 0 0 0.5rem',
      height: '2.5rem',
      padding: '0.5rem',
      backgroundColor: Colors.yellow1,
    },
  }

  const [price, setPrice] = useState(null)
  return (
    <div style={styles.detailsItemContainer}>
      <div style={styles.priceOuterContainer}>
        <div style={styles.priceRupeeContainer}>Rs</div>
        <input type="number" style={styles.priceContainer} placeholder="Price" onChange={(e) => setPrice(e.target.value)} />
      </div>
    </div>
  )
}

export default PlanButton