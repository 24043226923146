import React from "react";

function DropDown() {
  return (
    <>
      <div className="dropdown">
        <div>Transaction ID</div>
        <div>Payment Method</div>
        <div>Payment Date</div>
        <div>Total Per Day Cost</div>
        <div>Total Amount Paid</div>
      </div>
      <div className="dropdown_bold">
        <div>002547823645921</div>
        <div>Google Pay</div>
        <div>9 Nov 2022</div>
        <div>₹90</div>
        <div>₹720</div>
      </div>
    </>
  );
}

export default DropDown;
