import React from 'react'
import './Plans.css'
function PlanButton2() {
  return (
    <div className='button2'>
    <div>₹</div>
    <div>120</div>
    </div>
  )
}

export default PlanButton2
