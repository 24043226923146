import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Colors } from "../../../src/Colors";
import PrimaryButton from "../../Components/UTIL//Buttons/PrimaryButton";
import Add from "../../../src/SVG/Add";
import AddItem from "../../Components/AddItem";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ImageEdit from "../../SVG/ImageEdit";
import SecondaryButton from "../../Components/UTIL//Buttons/SecondaryButton";
import Cancel from "../../SVG/Cancel";
import Save from "../../SVG/Save";
import InputGroup from "react-bootstrap/InputGroup";
import PlanButton from "./PlanButton";
import PlanButton2 from "./PlanButton2";
import Non_Veg from "../../SVG/Non_Veg";
import Regular from "../../SVG/Regular";
import "./Plans.css";
function EditPlan() {
  const ref = useRef();
  // image upload
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const ChangeImage = (e) => {
    console.log(e);
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  // modal
  const [lgShow, setLgShow] = useState(false);

  const styles = {
    container: {},
    table: {
      border: "1px solid #E9E9E9",
      color: Colors.deepBlue100,
    },
    tableHead: {
      backgroundColor: Colors.yellow25,
    },
    tableBody: {
      backgroundColor: "white",
    },
    icon: {
      cursor: "pointer",
      height: "20px",
      width: "20px",
    },
    checkBoxContainer: {
      width: "250px",
      boxShadow: "0px 2px 2px #9E9E9E",
      fontSize: "12px",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-around",
      position: "absolute",
      backgroundColor: "#FFFCEB",
    },
    image: {
      borderRadius: "8px",
    },
    modalContainer: {
      boxShadow: "0px 2px 2px #9E9E9E",
      fontSize: "12px",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-around",
      position: "absolute",
      backgroundColor: "white",
      border: `1px solid ${Colors.yellow100}`,
      borderRadius: 8,
    },

    innerContainer: {},
    buttonsContainer: {},
    searchOuterContainer: {
      flex: 1,
      marginLeft: "3rem",
    },

    saveCancelContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "50px",
    },
    cont_0: {
      margin: "50px",
    },
    cont_1: {
      display: "flex",
      justifyContent: "center",
    },
    cont_2: {
      flex: 1,
      fontSize: "16px",
      // backgroundColor:'yellow'
    },
    cont_3: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    cont_4: {
      display: "flex",
      position: "absolute",
    },
    cont_5: {
      display: "flex",
      justifyContent: "space-around",
      flex: 1,
    },
    cont_6: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cont_7: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "6rem",
      alignItems: "center",
      fontWeight: 700,
    },
  };

  const [addModal, setAddModal] = useState(false);

  const addItemHandler = () => {
    setAddModal(!addModal);
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.buttonsContainer}></div>
      </div>
      <Modal
        show={addModal}
        contentClassName="modal"
        onHide={() => setAddModal(false)}
      >
        <AddItem addModal={addModal} setAddModal={setAddModal} />
      </Modal>

      {/* model */}

      <div
        style={{
          display: "flex",
        }}
      >
        <PrimaryButton
          onClick={() => setLgShow(true)}
          text={"Edit Details"}
          inlineStyle={{ padding: "4px 20px 4px 20px", marginBottom: "16px" }}
        />
      </div>
      <div>
        <Modal
          closeButton
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div style={styles.cont_0}>
            <div style={{ fontSize: "24px", marginBottom: "10px" }}>Edit Plan</div>

            <div className="grid_container">
              <div className="grid_box">
              <div >
                
                    <input
                      type="text"
                      style={{
                        border: "none",
                        border: `2px solid ${Colors.yellow100}`,
                        borderRadius: "10px",
                height:'150px',
                width:'300px'
                      }}
                    />
                 
                </div>
              </div>
              <div className="grid_box1">
      
                  <div className="grid_box">
                <div>Currently Editing</div>
                <div>  
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Non_Veg
                        styles={{
                        
                        }}
                      />{" "}
                      Non-Vegetarian
                    </div>
                    <div >
                
                      <Regular
                        styles={{
                          width: "16px",
                          height: "16px",
                          margin: "5px",
                        }}
                      />
                      Regular
                    </div>
                  </div>
                </div>

              <div>Description of Plan</div>
              </div>
            
              </div>
              <div className="grid_box2">
          
                <div  style={styles.cont_3}>
                  <img
                    src={image}
                    alt="images"
                    style={{
                      height: "256px",
                      width: "256px",
                      border: "2px solid black",
                      display: "flex",
                      borderRadius: "10px",
                    }}
                  />

                  <input
                    type="file"
                    onChange={ChangeImage}
                    ref={ref}
                    style={{ display: "none" }}
                  />
                  <div style={styles.cont_4}>
                    <ImageEdit onClick={() => ref.current.click()}/>
                  </div>
                  </div>

              </div>
            </div>

            <div style={{marginTop:'10px'}}>
              <p style={{ fontWeight: 700 }}>Price</p>
            </div>

            <div className="editplan_grid">
              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Breakfast</div>
                  <div>
                    {" "}
                    <PlanButton />
                  </div>
                </div>
              </div>
              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Per Day</div>
                  <div>
                    {" "}
                    <PlanButton2 />
                  </div>
                </div>
              </div>

              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Lunch</div>
                  <div>
                    {" "}
                    <PlanButton />
                  </div>
                </div>
              </div>
              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Per Week</div>
                  <div>
                    {" "}
                    <PlanButton2 />
                  </div>
                </div>
              </div>
              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Dinner</div>
                  <div>
                    {" "}
                    <PlanButton />
                  </div>
                </div>
              </div>
              <div className="editplan_gridbox">
                <div className="editplan_innerbox">
                  <div>Per Month</div>
                  <div>
                    {" "}
                    <PlanButton2 />
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.saveCancelContainer}>
              <div style={{ marginRight: "32px" }}>
                <SecondaryButton text={"Cancel"} icon={<Cancel />} />
              </div>
              <div>
                <PrimaryButton text={"Save"} icon={<Save />} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default EditPlan;
