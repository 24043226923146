import { createSlice } from "@reduxjs/toolkit";

var customerExpireInThreeDays = [
    {
        id: 0,
        phone: '9745741369',
        name: 'John',
        plans: ['Regular', 'Healthy'],
        joinDate: '1 November 2022',
        expireDate: '16 November 2022'
    },
    {
        id: 1,
        phone: '9745741369',
        name: 'Adam',
        plans: ['Diabetic'],
        joinDate: '5 November 2022',
        expireDate: '4 December 2022'
    }
]
var customerCancelledOrExpired = [
    {
        id: 0,
        phone: '9745741369',
        name: 'Anu',
        plans: ['Healthy'],
        status: 'Expired',
        joinDate: '3 April 2022',
        expireDate: '23 March 2023'
    },
    {
        id: 1,
        phone: '9745741369',
        name: 'John',
        plans: ['Regular', 'Healthy'],
        status: 'Cancelled',
        joinDate: '1 November 2022',
        expireDate: '16 November 2022'
    },
    {
        id: 2,
        phone: '7034551432',
        name: 'Adam',
        plans: ['Regular', 'Healthy'],
        status: 'Expired',
        joinDate: '10 Augest 2022',
        expireDate: '20 December 2022'
    },
    {
        id: 3,
        phone: '8089981687',
        name: 'Sam',
        plans: ['Diabteic'],
        status: 'Cancelled',
        joinDate: '10 December 2022',
        expireDate: '20 December 2023'
    },
    {
        id: 4,
        phone: '9745741369',
        name: 'Roy',
        plans: ['Healthy'],
        status: 'Expired',
        joinDate: '31 December 2022',
        expireDate: '20 December 2023'
    },
    {
        id: 5,
        phone: '9745741369',
        name: 'Baby',
        plans: ['Diabetic'],
        status: 'Cancelled',
        joinDate: '4 January 2023',
        expireDate: '20 December 2023'
    },
]
customerExpireInThreeDays = customerExpireInThreeDays.map((item) => ({ ...item, checked: false }))
customerCancelledOrExpired = customerCancelledOrExpired.map((item) => ({ ...item, checked: false }))
const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        customerExpireInThreeDays: customerExpireInThreeDays,
        customerCancelledOrExpired: customerCancelledOrExpired
    },
    reducers: {},
})
export const customerAction = customerSlice.actions;
export default customerSlice