import React from 'react';
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePDF";
import { Colors } from "../../Colors";
import { FaPrint } from 'react-icons/fa'; 
const PDFDownloadButton = ({ user }) => {
  const printButtonStyle = {
    background: Colors.Primary,
    color: "black",
    border: "none",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
    display: "flex", 
    alignItems: "center", 
  };

  const iconStyle = {
    marginRight: "5px", 
  };

  return (
    <PDFDownloadLink
      document={<InvoicePDF invoiceData={user} />}
      fileName={`invoice-${user.orderid}.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <button
          style={printButtonStyle}
          disabled={loading}
        >
          <FaPrint style={iconStyle} /> 
          {loading ? "Loading..." : "Print"}
        </button>
      )}
    </PDFDownloadLink>
  );
};

export default PDFDownloadButton;
