import React from 'react'
import { Colors } from '../../../Colors'
import VegIcon from '../../../SVG/VegIcon'

function PrimaryButton({ text, icon, onClick, inlineStyle }) {
    const styles = {
        container: {
            background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
            padding: '0.5rem 2rem 0.5rem 2rem',
            borderRadius: '0.6rem',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '1px 1px 4px 0px #9E9E9E',

        },
        buttonText: {
            marginLeft: '0.2rem',
            // fontWeight: 600
        }
    }
    return (
        <div style={{ ...styles.container, ...inlineStyle }} id="clickable" onClick={onClick}>
            {icon}
            <span style={styles.buttonText} >{text}</span>
        </div>
    )
}

export default PrimaryButton