import React from 'react'

function Add() {
    return (

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_279_553)">
                <circle cx="8" cy="8" r="8" fill="white" fill-opacity="0.5" />
                <path d="M12.6666 8.66683H8.66658V12.6668H7.33325V8.66683H3.33325V7.3335H7.33325V3.3335H8.66658V7.3335H12.6666V8.66683Z" fill="#04CC48" />
            </g>
            <defs>
                <clipPath id="clip0_279_553">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>



    )
}

export default Add