import React, { useEffect, useState } from 'react'
import './style.css';
function Main() {
    const [animationState, setAnimationState] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setAnimationState(prevState => !prevState);
      }, 2000); // Change the interval duration as needed
  
      return () => clearInterval(interval);
    }, []);
    return (
        <svg width="300" height="388" viewBox="0 0 489 488" fill="none" xmlns="http://www.w3.org/2000/svg"  style={{
            animation: 'moveForward 2s linear infinite',
          }}>
            <circle cx="244.5" cy="244" r="244" fill="url(#paint0_linear_4_145)" />
            <g clip-path="url(#clip0_4_145)">
                <path d="M93.686 358.78C93.686 358.78 92.822 390.405 122.774 390.775C152.725 391.144 136.624 358.78 136.624 358.78" fill="#292929" />
                <path d="M100.796 358.942C100.796 359.059 100.661 370.808 107.573 377.944C111.218 381.711 115.574 383.567 122.216 383.648C122.351 383.648 129.1 384.54 131.764 380.062C134.194 375.98 132.763 367.06 130.243 361.942" fill="#474747" />
                <path d="M136.912 354.5C137.371 357.239 139.387 358.753 140.584 362.33C141.961 366.466 140.467 367.835 140.467 367.835L90.941 370.178C90.941 370.178 86.5581 353.915 105.485 344.742C119.498 337.949 130.603 337.651 130.603 337.651C130.603 337.651 136.075 349.455 136.921 354.5H136.912Z" fill="#B7B7B7" />
                <path d="M129.766 254.083C129.766 252.821 128.038 249.109 129.667 248.452C131.296 247.794 132.475 248.398 133.447 250.758C134.446 253.191 131.44 255.083 134.734 254.903C135.94 254.831 136.651 252.65 137.497 252.074C139.135 250.947 142.105 251.578 142.105 251.578L140.908 244.244C140.908 244.244 135.436 244.523 133.852 244.397C133.168 244.343 130.9 244.01 128.992 245.172C127.057 246.352 126.643 248.488 126.553 248.866C125.986 251.101 126.58 251.848 128.074 254.488C128.524 255.281 129.766 254.263 129.766 254.083V254.083Z" fill="#BC814B" />
                <path d="M171.688 195.797C171.688 195.797 160.528 193.229 160.366 203.789C160.294 208.564 162.148 215.718 171.598 227.927C181.426 240.622 180.544 240.685 179.041 242.865C178.132 244.19 175.027 242.928 165.802 242.631C147.649 242.036 147.532 244.244 138.946 243.64L139.783 249.677C139.783 249.677 140.278 249.695 150.178 249.983C155.092 250.127 164.623 250.839 169.816 251.191C179.581 251.857 190.885 252.191 193.594 250.308C198.318 247.037 193.909 230.801 192.406 223.268C190.75 214.961 187.699 205.32 186.259 202.798C183.685 200.419 175.918 197.292 171.688 195.806V195.797Z" fill="#EFC443" />
                <path d="M128.335 248.028L132.925 247.83L133.915 249.938L129.613 252.903L128.335 248.028Z" fill="#292929" />
                <path d="M308.972 298.998H319.529C319.529 298.998 323.489 298.953 322.625 308.278C321.608 319.271 305.075 321.316 305.075 321.316L308.972 299.007V298.998Z" fill="#910C38" />
                <path d="M264.638 358.996C264.638 358.996 263.775 390.622 293.726 390.991C323.678 391.36 307.577 358.996 307.577 358.996" fill="#292929" />
                <path d="M271.748 359.167C271.748 359.285 271.613 371.034 278.525 378.17C282.17 381.936 286.526 383.792 293.168 383.873C293.303 383.873 300.053 384.765 302.717 380.287C305.147 376.205 303.716 367.285 301.196 362.168" fill="#474747" />
                <path d="M173.47 347.689C173.47 347.689 163.057 348.004 162.589 355.212C162.202 361.213 168.295 361.123 172.363 360.888C179.869 360.456 185.755 355.257 186.313 349.95L188.887 351.734L193.863 344.868C194.061 342.976 192.244 343.724 190.993 342.508C190.723 339.219 187.609 336.138 185.107 336.03C185.107 336.03 179.896 339.183 177.799 340.048C177.799 340.048 180.76 344.751 173.479 347.68L173.47 347.689Z" fill="#895912" />
                <path d="M249.627 368.168H161.374L158.773 360.267H244.83L249.627 368.168Z" fill="#474747" />
                <path d="M161.374 368.168H145.228C145.228 368.168 137.974 363.258 134.698 356.852C122.108 332.074 114.926 298.827 110.948 277.788C109.067 267.994 110.732 260.985 110.732 260.985L115.457 258.921C115.457 258.921 116.591 261.021 119.651 264.796C126.58 273.446 129.181 265.868 132.772 274.301C136.363 282.735 158.764 360.276 158.764 360.276L161.356 368.177L161.374 368.168Z" fill="#D6D6D6" />
                <path d="M107.393 254.651C110.543 260.705 103.001 263.607 99.4819 259.174C95.8009 254.542 94.2529 248.109 93.56 243.947C93.164 241.559 95.1439 237.784 98.7979 240.694C101.633 242.955 105.026 250.073 107.402 254.651H107.393Z" fill="#F5F9FF" />
                <path d="M129.064 247.56L130.054 251.38L114.251 254.29L108.752 240.847C108.752 240.847 114.827 245.037 119.696 246.298C124.574 247.56 129.055 247.551 129.055 247.551L129.064 247.56Z" fill="#BCBCBC" />
                <path d="M129.244 249.794L134.266 248.686L136.21 255.083L130.522 254.669L129.244 249.794Z" fill="#292929" />
                <path d="M129.73 250.001C129.64 249.614 129.343 249.335 128.956 249.28C127.714 249.118 124.69 248.641 120.542 247.469C117.464 246.605 114.8 244.866 113.009 243.478C112.307 242.739 111.587 242.117 110.84 241.631C110.759 241.559 110.687 241.478 110.687 241.478L110.705 241.532C107.402 239.405 104.108 239.369 104.108 239.369H95.4049C95.4049 239.369 96.8449 244.28 99.0769 251.857C101.309 259.435 104.117 260.777 104.117 260.777C104.117 260.777 107.348 263.471 115.448 258.903V258.894V258.912C115.448 258.912 115.493 258.849 115.52 258.804C115.565 258.75 115.619 258.669 115.709 258.543C116.402 257.588 118.481 254.984 120.722 254.669C124.385 254.155 129.127 254.822 130.522 255.047C130.783 255.092 130.855 254.822 130.855 254.822L129.712 250.01L129.73 250.001Z" fill="#D6D6D6" />
                <path d="M308.558 331.092L312.653 311.324L214.437 311.549C197.769 319.514 227.874 360.122 227.874 360.122H270.641L308.558 331.092V331.092Z" fill="#A3A3A3" />
                <path d="M206.688 309.134L201.639 298.295C198.228 290.979 203.565 282.6 211.62 282.6H310.295L317.351 302.386C318.944 306.846 315.641 311.54 310.907 311.54H210.459C208.839 311.54 207.363 310.603 206.679 309.125L206.688 309.134Z" fill="#292929" />
                <path d="M182.803 347.499C182.803 347.499 172.39 347.373 171.616 354.554C170.977 360.528 177.07 360.699 181.138 360.636C188.653 360.519 194.754 355.572 195.537 350.301L198.03 352.194L203.295 345.544C203.574 343.661 201.72 344.328 200.523 343.066C200.388 339.769 197.409 336.561 194.916 336.345C194.916 336.345 189.58 339.273 187.447 340.048C187.447 340.048 190.201 344.868 182.812 347.49L182.803 347.499Z" fill="#9E6812" />
                <path d="M225.912 283.005C199.308 291.222 172.3 285.213 170.878 288.853C170.41 290.096 182.551 299.584 186.106 309.882C189.346 319.226 192.559 336.552 192.559 336.552L176.629 343.328C176.629 343.328 175.846 339.309 169.483 319.154C167.449 312.765 148.459 289.24 149.494 282.744C150.7 275.175 159.754 270.364 169.105 268.454C178.618 266.553 198.138 263.192 198.138 263.192C198.39 262.841 205.671 261.832 214.626 260.201C223.392 258.642 228.351 255.687 229.539 254.876C237.333 268.904 233.067 280.789 225.903 283.005H225.912Z" fill="#092238" />
                <path d="M161.95 202.734C161.95 202.734 155.74 201.518 152.986 195.193C152.986 195.193 162.184 189.201 162.553 188.859C162.922 188.517 167.539 194.752 170.14 195.887C172.741 197.022 161.95 202.734 161.95 202.734V202.734Z" fill="#BC7F48" />
                <path d="M180.58 189.12C176.521 190.508 174.433 190.048 172.741 190.075C169.006 190.138 170.572 188.372 170.149 188.408C168.817 188.517 167.332 189.436 167.332 189.436C167.332 189.436 166.432 191.58 170.149 193.121C171.013 193.481 177.367 193.256 181.453 195.058C185.89 197.013 189.742 201.788 192.208 202.32C193.252 202.545 198.975 202.536 200.172 202.825C203.07 203.527 207.048 204.861 209.982 204.572C212.151 204.356 214.671 201.86 216.426 201.662C218.685 201.419 217.56 201.194 219.279 200.95C226.65 199.878 225.948 198.878 225.948 198.878C225.948 198.878 223.41 199.617 220.287 199.5C218.568 199.437 216.804 198.464 214.887 198.5C213.87 198.518 211.494 199.175 209.838 199.112C207.228 199.004 205.356 198.157 205.356 198.157C205.356 198.157 208.056 198.238 210.144 198.022C211.197 197.914 212.061 197.527 212.421 197.428C214.689 196.788 216.372 194.806 216.372 194.806C215.967 194.806 215.688 195.139 211.971 195.671C209.847 195.977 207.246 195.4 205.86 195.067C205.194 194.905 203.43 195.499 202.845 195.526C201.855 195.562 200.901 195.184 200.172 195.031C199.686 194.923 201.531 194.058 202.323 193.553C202.854 193.22 203.394 191.841 204.708 191.454C204.708 191.454 198.633 191.94 193.908 191.454C189.184 190.967 187.024 186.913 180.58 189.12V189.12Z" fill="#292929" />
                <path d="M230.043 281.915C203.268 290.763 176.971 285.699 175.54 289.24C175.063 290.448 186.655 299.331 189.976 309.342C193.027 318.505 196.662 335.732 196.662 335.732L181.3 343.355C181.3 343.355 179.365 338.57 173.569 318.694C171.733 312.477 154.21 290.222 155.362 284.032C156.694 276.833 165.343 271.986 174.271 269.832C183.424 267.67 202.458 263.679 202.458 263.679C202.71 263.336 202.674 263.075 202.413 262.805C203.61 262.696 209.847 262.021 218.802 260.102C227.595 258.218 232.599 255.128 233.805 254.29C241.734 267.634 237.378 279.491 230.061 281.915H230.043Z" fill="#0A2C4F" />
                <path d="M219.747 259.894C210.747 261.507 202.413 262.805 202.413 262.805C200.991 261.309 194.448 256.525 188.833 247.487C186.169 243.181 185.17 239.261 181.939 233.179C179.131 227.837 172.129 222.178 170.77 220.791C157.045 206.654 161.563 196.977 167.503 195.337C173.443 193.661 191.857 199.86 197.085 213.24C201.882 225.521 212.979 229.648 221.871 238.234C227.415 243.577 231.249 249.344 233.814 254.299C233.85 254.335 228.693 258.3 219.765 259.903L219.747 259.894Z" fill="#F2C12C" />
                <path d="M133.843 254.551C133.717 253.29 131.593 249.794 133.141 248.965C134.689 248.136 135.922 248.605 137.146 250.857C138.37 253.11 135.616 255.371 138.874 254.831C140.062 254.632 140.539 252.389 141.313 251.722C142.825 250.425 145.84 250.731 145.84 250.731L143.86 243.568C143.86 243.568 138.451 244.433 136.867 244.478C136.183 244.496 133.888 244.406 132.115 245.776C130.342 247.145 130.144 249.326 130.081 249.713C129.757 251.993 130.432 252.677 132.196 255.137C132.727 255.885 133.852 254.732 133.834 254.551H133.843Z" fill="#D19152" />
                <path d="M165.37 190.228L168.466 188.066C168.466 188.066 170.176 186.85 170.833 187.778C171.49 188.706 169.834 189.472 169.834 189.472L166.945 191.22C166.945 191.22 166.018 191.778 165.568 191.147C165.118 190.517 165.361 190.228 165.361 190.228H165.37Z" fill="#F05689" />
                <path d="M236.712 368.168C236.712 368.168 226.83 354.653 228.837 349.337C231.33 342.706 266.933 314.108 297.101 323.722C310.007 327.83 316.847 341.634 320.339 352.779C322.733 360.411 317.072 368.168 309.08 368.168H236.712V368.168Z" fill="#D6D6D6" />
                <path d="M170.752 195.058C170.752 195.058 161.554 194.013 161.752 203.599C161.842 208.086 163.669 214.988 172.552 227.386C182.632 241.451 181.804 241.514 180.562 244.01C179.806 245.532 176.755 244.01 168.115 243.505C151.717 242.541 150.934 244.487 143.167 242.991L144.76 250.983C144.76 250.983 145.048 251.001 154.066 251.605C158.548 251.902 167.44 253.011 172.39 253.587C181.894 254.687 193.189 255.47 195.681 253.236C200.01 249.362 193.522 230.476 191.227 222.322C188.806 213.718 185.143 204.257 183.658 201.878C180.22 196.364 175.855 195.788 170.752 195.058V195.058Z" fill="#FFCE3B" />
                <path d="M133.249 249.461C133.249 249.461 132.853 254.768 135.598 255.119C138.343 255.47 137.479 249.938 135.769 248.974C134.059 248.01 133.249 249.47 133.249 249.47V249.461Z" fill="black" />
                <path d="M140.845 176.227C140.845 176.227 138.55 179.777 139.261 185.273C140.233 192.742 145.066 202.464 152.806 199.184C155.425 198.076 156.694 197.716 157.711 196.923C164.425 191.652 153.067 184.021 152.797 183.66L158.431 170.623L140.845 176.218V176.227Z" fill="#D68B47" />
                <path d="M169.249 192.229C172.75 190.517 173.731 187.408 173.731 187.408C173.731 187.408 179.212 169.983 160.654 164.216C145.624 159.54 138.001 169.947 138.001 169.947C138.001 169.947 133.213 176.416 136.561 176.623C139.909 176.83 146.236 173.767 144.625 181.642C143.014 189.517 145.786 193.733 149.854 195.625C153.922 197.518 166.576 193.526 169.24 192.229H169.249Z" fill="#490721" />
                <path d="M302.825 244.685H253.776C251.382 244.685 249.456 247.091 250.023 249.38L261.129 294.511C261.471 295.917 262.686 296.854 264.153 296.854H313.202C315.596 296.854 317.522 294.448 316.955 292.159L305.849 247.028C305.507 245.622 304.292 244.685 302.825 244.685V244.685Z" fill="#707070" />
                <path d="M122.756 302.062C122.477 298.07 126.634 295.863 130.333 295.772C130.333 295.772 134.149 304.954 132.664 310.044C131.188 315.135 126.166 315.198 126.166 315.198C126.166 315.198 123.152 307.765 122.756 302.062V302.062Z" fill="#910C38" fill-opacity="0.16" />
                <path d="M272 273.716C272 273.716 272.999 276.067 276.689 276.049L277.895 277.158H290.99L291.683 276.049C295.382 276.067 295.292 273.716 295.292 273.716H271.991H272Z" fill="#F5CC02" />
                <path d="M275.492 273.022C275.492 273.022 274.241 270.175 276.545 270.049C278.228 269.95 280.415 272.283 280.415 272.283C280.415 272.283 279.893 271.571 279.218 271.004C278.615 270.499 277.76 269.977 277.76 269.977C277.76 269.977 278.237 267.463 279.92 267.346C282.467 267.165 284.564 271.265 284.564 271.265C284.564 271.265 284.186 270.301 283.745 269.616C283.061 268.562 282.233 267.886 282.233 267.886C282.233 267.886 283.268 266.129 285.662 266.859C287.444 267.4 288.632 269.04 288.632 269.04C288.632 269.04 288.227 269.085 287.732 269.238C287.264 269.391 286.769 269.697 286.769 269.697C286.769 269.697 289.136 268.652 290.72 269.544C291.584 270.022 292.331 271.535 292.097 273.031H275.492V273.022Z" fill="#F5CC02" />
                <path d="M275.294 258.246C276.572 258.858 277.679 260.246 277.661 261.534C277.697 262.642 277.148 263.715 277.265 264.733C277.418 265.49 277.679 266.237 278.048 266.976C277.4 266.409 276.932 265.67 276.59 264.931C276.365 264.435 276.329 263.904 276.347 263.462C276.572 261.048 277.085 260.786 275.294 258.255V258.246Z" fill="#F5CC02" />
                <path d="M279.641 254.641C280.217 256.029 280.586 257.399 281.522 258.552C282.458 259.516 282.89 260.795 282.656 261.859C282.458 263.012 281.747 263.814 281.819 265.021C281.837 265.246 281.882 265.49 281.954 265.742C281.558 265.318 281.288 264.778 281.189 264.255C280.991 263.255 281.297 262.318 281.342 261.435C281.405 260.615 281.09 259.921 280.496 259.237C279.425 257.786 279.263 256.038 279.641 254.651V254.641Z" fill="#F5CC02" />
                <path d="M285.806 260.183C285.752 261.012 285.887 261.777 286.373 262.507C287.273 263.724 288.353 265.544 287.606 266.76C287.588 266.201 287.417 265.652 287.093 265.138C286.598 264.3 285.788 263.724 285.401 262.805C284.996 261.823 285.167 260.75 285.806 260.183Z" fill="#F5CC02" />
                <path d="M271.874 283.816L271.784 283.438H272.639L272.729 283.816H271.874ZM273.017 287.087L272.153 283.438H272.63L273.494 287.087H273.017Z" fill="#FFD814" />
                <path d="M275.177 287.15C274.979 287.15 274.799 287.114 274.637 287.051C274.475 286.979 274.34 286.88 274.232 286.753C274.124 286.627 274.043 286.465 274.007 286.285C273.971 286.105 273.971 285.933 274.025 285.807C274.079 285.681 274.178 285.582 274.331 285.519C274.484 285.456 274.664 285.42 274.898 285.42H275.627L275.708 285.78H274.961C274.772 285.78 274.628 285.825 274.547 285.924C274.466 286.015 274.439 286.141 274.475 286.285C274.511 286.429 274.592 286.546 274.718 286.636C274.844 286.726 275.006 286.771 275.195 286.771C275.312 286.771 275.411 286.753 275.501 286.708C275.591 286.663 275.663 286.6 275.708 286.501C275.753 286.402 275.762 286.267 275.726 286.096L275.897 286.285C275.924 286.474 275.915 286.636 275.87 286.762C275.825 286.889 275.744 286.988 275.627 287.051C275.51 287.114 275.357 287.15 275.177 287.15ZM276.032 287.078L275.843 286.276H275.762L275.546 285.348C275.501 285.168 275.42 285.023 275.303 284.933C275.186 284.843 275.033 284.789 274.853 284.789C274.754 284.789 274.646 284.789 274.538 284.789C274.43 284.789 274.322 284.789 274.223 284.798C274.124 284.798 274.034 284.798 273.962 284.816L273.863 284.393C273.935 284.393 274.007 284.384 274.088 284.375C274.169 284.375 274.25 284.375 274.34 284.366C274.43 284.366 274.511 284.366 274.592 284.366C274.862 284.366 275.096 284.402 275.285 284.465C275.474 284.528 275.627 284.636 275.744 284.78C275.861 284.924 275.951 285.123 276.014 285.375L276.419 287.06H276.041L276.032 287.078Z" fill="#FFD814" />
                <path d="M276.176 283.816L276.086 283.438H276.941L277.031 283.816H276.176ZM277.319 287.087L276.455 283.438H276.932L277.796 287.087H277.319Z" fill="#FFD814" />
                <path d="M279.488 287.15C279.29 287.15 279.11 287.114 278.948 287.051C278.786 286.979 278.651 286.88 278.543 286.753C278.435 286.627 278.354 286.465 278.318 286.285C278.282 286.105 278.282 285.933 278.336 285.807C278.39 285.681 278.489 285.582 278.642 285.519C278.795 285.456 278.975 285.42 279.209 285.42H279.938L280.019 285.78H279.272C279.083 285.78 278.939 285.825 278.858 285.924C278.777 286.015 278.75 286.141 278.786 286.285C278.822 286.429 278.903 286.546 279.029 286.636C279.155 286.726 279.317 286.771 279.506 286.771C279.623 286.771 279.722 286.753 279.812 286.708C279.902 286.663 279.974 286.6 280.019 286.501C280.064 286.402 280.073 286.267 280.037 286.096L280.208 286.285C280.235 286.474 280.226 286.636 280.181 286.762C280.136 286.889 280.055 286.988 279.938 287.051C279.821 287.114 279.668 287.15 279.488 287.15ZM280.343 287.078L280.154 286.276H280.073L279.857 285.348C279.812 285.168 279.731 285.023 279.614 284.933C279.497 284.843 279.344 284.789 279.164 284.789C279.065 284.789 278.957 284.789 278.849 284.789C278.741 284.789 278.633 284.789 278.534 284.798C278.435 284.798 278.345 284.798 278.273 284.816L278.174 284.393C278.246 284.393 278.318 284.384 278.399 284.375C278.48 284.375 278.561 284.375 278.651 284.366C278.741 284.366 278.822 284.366 278.903 284.366C279.173 284.366 279.407 284.402 279.596 284.465C279.785 284.528 279.938 284.636 280.055 284.78C280.172 284.924 280.262 285.123 280.325 285.375L280.73 287.06H280.352L280.343 287.078Z" fill="#FFD814" />
                <path d="M280.775 284.951L280.712 284.69H280.757C280.865 284.69 280.946 284.672 281 284.636C281.054 284.6 281.09 284.546 281.099 284.465C281.108 284.384 281.099 284.285 281.072 284.168L280.982 283.789L281.153 284.014C281.153 284.014 281.108 284.041 281.072 284.041C281.036 284.041 281 284.05 280.964 284.05C280.874 284.05 280.784 284.023 280.712 283.96C280.64 283.906 280.586 283.825 280.559 283.717C280.532 283.618 280.55 283.537 280.586 283.474C280.631 283.411 280.703 283.375 280.802 283.375C280.865 283.375 280.928 283.393 280.991 283.42C281.054 283.447 281.117 283.501 281.171 283.582C281.225 283.663 281.27 283.771 281.306 283.915L281.333 284.023C281.387 284.267 281.405 284.456 281.378 284.591C281.351 284.726 281.288 284.816 281.189 284.87C281.09 284.924 280.964 284.951 280.82 284.951H280.784H280.775Z" fill="#FFD814" />
                <path d="M283.43 287.168C283.079 287.168 282.791 287.096 282.566 286.943C282.341 286.789 282.188 286.591 282.125 286.33H282.575C282.602 286.429 282.683 286.528 282.8 286.618C282.917 286.708 283.097 286.753 283.331 286.753C283.547 286.753 283.7 286.708 283.79 286.627C283.88 286.546 283.916 286.438 283.889 286.312C283.862 286.204 283.799 286.114 283.7 286.051C283.601 285.988 283.457 285.952 283.268 285.924L282.971 285.897C282.71 285.87 282.485 285.798 282.296 285.672C282.107 285.546 281.981 285.375 281.927 285.141C281.891 284.969 281.9 284.825 281.954 284.699C282.008 284.573 282.107 284.483 282.251 284.42C282.386 284.357 282.557 284.321 282.755 284.321C283.052 284.321 283.313 284.384 283.529 284.519C283.745 284.654 283.889 284.852 283.961 285.114H283.511C283.484 285.005 283.412 284.915 283.295 284.843C283.178 284.771 283.034 284.735 282.845 284.735C282.656 284.735 282.53 284.771 282.449 284.852C282.368 284.933 282.341 285.023 282.377 285.141C282.404 285.24 282.458 285.321 282.539 285.384C282.62 285.447 282.746 285.483 282.908 285.501L283.205 285.528C283.502 285.555 283.745 285.636 283.952 285.762C284.159 285.888 284.285 286.069 284.339 286.303C284.375 286.474 284.366 286.618 284.303 286.744C284.24 286.871 284.132 286.97 283.979 287.042C283.826 287.114 283.646 287.15 283.43 287.15V287.168Z" fill="#FFD814" />
                <path d="M286.355 287.087L285.491 283.438H285.968L286.832 287.087H286.355ZM288.2 287.087L286.895 285.843H286.454L287.345 284.42H287.849L287.039 285.708L287.012 285.456L288.749 287.096H288.191L288.2 287.087Z" fill="#FFD814" />
                <path d="M288.398 284.798L288.308 284.42H289.199L289.289 284.798H288.398ZM288.749 283.987C288.641 283.987 288.56 283.96 288.488 283.906C288.425 283.852 288.38 283.78 288.362 283.69C288.344 283.6 288.353 283.528 288.389 283.474C288.425 283.42 288.497 283.393 288.605 283.393C288.713 283.393 288.794 283.42 288.866 283.474C288.929 283.528 288.974 283.6 288.992 283.69C289.01 283.78 289.001 283.852 288.965 283.906C288.929 283.96 288.857 283.987 288.749 283.987ZM289.352 287.087L288.722 284.42H289.199L289.829 287.087H289.352Z" fill="#FFD814" />
                <path d="M289.829 284.771L289.748 284.411H291.593L291.674 284.771H289.829ZM291.818 287.114C291.62 287.114 291.44 287.087 291.278 287.033C291.116 286.979 290.981 286.88 290.873 286.744C290.765 286.609 290.675 286.42 290.621 286.186L290.009 283.618H290.468L291.089 286.249C291.125 286.393 291.188 286.501 291.287 286.582C291.386 286.663 291.503 286.699 291.647 286.699H292.115L292.214 287.132H291.8L291.818 287.114Z" fill="#FFD814" />
                <path d="M294.041 287.177C293.807 287.177 293.6 287.141 293.411 287.06C293.222 286.979 293.06 286.871 292.916 286.735C292.772 286.6 292.655 286.447 292.565 286.285C292.475 286.123 292.403 285.961 292.367 285.798L292.349 285.708C292.313 285.537 292.295 285.375 292.313 285.213C292.331 285.051 292.376 284.906 292.457 284.771C292.538 284.636 292.655 284.537 292.799 284.456C292.943 284.375 293.132 284.339 293.357 284.339C293.582 284.339 293.789 284.384 293.987 284.465C294.185 284.546 294.356 284.663 294.5 284.816C294.644 284.969 294.752 285.15 294.806 285.357H294.347C294.284 285.195 294.176 285.05 294.014 284.942C293.861 284.834 293.672 284.771 293.447 284.771C293.258 284.771 293.105 284.816 293.006 284.897C292.898 284.987 292.835 285.105 292.808 285.249C292.781 285.402 292.79 285.564 292.835 285.762C292.88 285.961 292.952 286.114 293.051 286.267C293.15 286.42 293.276 286.537 293.42 286.627C293.573 286.717 293.744 286.762 293.933 286.762C294.086 286.762 294.203 286.735 294.302 286.681C294.401 286.627 294.473 286.555 294.518 286.465C294.563 286.375 294.581 286.276 294.563 286.159H295.022C295.058 286.366 295.04 286.546 294.968 286.699C294.896 286.853 294.77 286.97 294.617 287.06C294.455 287.141 294.257 287.186 294.032 287.186L294.041 287.177Z" fill="#FFD814" />
                <path d="M295.904 287.087L295.04 283.438H295.517L296.039 285.618H295.922C295.859 285.357 295.841 285.132 295.859 284.933C295.877 284.735 295.949 284.591 296.075 284.492C296.192 284.393 296.363 284.339 296.588 284.339H296.606C296.93 284.339 297.191 284.447 297.407 284.654C297.623 284.861 297.776 285.168 297.866 285.564L298.226 287.087H297.749L297.362 285.465C297.308 285.249 297.209 285.078 297.056 284.96C296.903 284.843 296.732 284.78 296.543 284.78C296.327 284.78 296.174 284.852 296.084 284.996C295.985 285.141 295.967 285.321 296.021 285.555L296.39 287.096H295.913L295.904 287.087Z" fill="#FFD814" />
                <path d="M300.269 287.177C300.035 287.177 299.828 287.141 299.639 287.06C299.45 286.979 299.288 286.871 299.144 286.744C299 286.618 298.892 286.465 298.802 286.303C298.712 286.141 298.649 285.97 298.604 285.807L298.586 285.717C298.541 285.546 298.532 285.375 298.541 285.213C298.55 285.051 298.595 284.906 298.676 284.771C298.757 284.636 298.865 284.537 299.009 284.456C299.153 284.375 299.342 284.339 299.567 284.339C299.855 284.339 300.116 284.402 300.332 284.528C300.548 284.654 300.728 284.816 300.863 285.014C300.998 285.213 301.097 285.42 301.151 285.645L301.205 285.861H298.829L298.748 285.501H300.773L300.71 285.699C300.665 285.51 300.593 285.348 300.503 285.204C300.413 285.069 300.296 284.96 300.152 284.879C300.008 284.798 299.855 284.762 299.666 284.762C299.477 284.762 299.333 284.807 299.225 284.888C299.117 284.978 299.054 285.096 299.027 285.24C299 285.393 299.009 285.564 299.054 285.762C299.099 285.961 299.171 286.123 299.27 286.276C299.369 286.429 299.495 286.546 299.639 286.636C299.792 286.726 299.963 286.771 300.17 286.771C300.377 286.771 300.539 286.726 300.647 286.627C300.755 286.537 300.809 286.429 300.809 286.303H301.259C301.259 286.483 301.232 286.645 301.151 286.78C301.07 286.916 300.962 287.015 300.809 287.087C300.656 287.159 300.476 287.195 300.269 287.195V287.177Z" fill="#FFD814" />
                <path d="M302.168 287.087L301.538 284.42H301.916L302.186 285.564H302.123C302.06 285.303 302.042 285.078 302.069 284.897C302.096 284.717 302.177 284.582 302.294 284.483C302.42 284.384 302.591 284.339 302.816 284.339H302.834C303.167 284.339 303.446 284.447 303.653 284.654C303.869 284.861 304.022 285.168 304.112 285.564L304.472 287.087H303.995L303.608 285.465C303.563 285.258 303.464 285.096 303.311 284.969C303.167 284.843 302.987 284.78 302.789 284.78C302.591 284.78 302.42 284.843 302.321 284.978C302.222 285.105 302.195 285.285 302.249 285.501L302.627 287.096H302.15L302.168 287.087Z" fill="#FFD814" />
                <path opacity="0.24" d="M277.868 172.641H201.405L199.515 170.875H275.978L277.868 172.641Z" fill="#292929" />
                <path opacity="0.24" d="M313.778 181.056H212.529L210.027 179.29H311.276L313.778 181.056Z" fill="#292929" />
                <path opacity="0.24" d="M436.5 272.319H335.242L332.74 270.553H433.998L436.5 272.319Z" fill="#292929" />
                <path opacity="0.24" d="M418.959 280.708H345.403L343.585 278.942H417.141L418.959 280.708Z" fill="#292929" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_4_145" x1="406" y1="62" x2="88.5" y2="412" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF6CC" />
                    <stop offset="0.797757" stop-color="#FFF6CC" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_4_145">
                    <rect width="346" height="228" fill="white" transform="translate(90.5 163)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Main