import React from 'react'
import { Colors } from '../../Colors'
import Regular from '../../SVG/Regular';
import Healthy from '../../SVG/Healthy'
import Diabetic from '../../SVG/Diabetic'

function Mealtype({ status, userStatus }) {
    const styles = {
        container: {
            flex: 1,
            padding: '1.2rem',
            backgroundColor: Colors.yellow1,
            boxShadow: '1px 1px 4px 0px #9E9E9E',
            borderRadius: '1rem',
            // height: '260px'
        },
        headerText: {
            fontSize: '20px',
            fontWeight: '700',
            textAlign: "center"
        },
        subHeaderText: {
            textAlign: "center"
        },
        innerContainer: {
            display: "flex",
            justifyContent: 'space-between',
        },
        itemContainer: {
            display: "flex",
            justifyContent: 'space-between',
        },
        itemText: {
            marginLeft: '1rem',
            fontSize: '16px'
        },
        totalText: {
            fontWeight: "700"
        }
    }
    return (
        <div style={styles.container}>
            <div>
                <h3 style={styles.headerText}>{status}</h3>
                {/* <div style={{ marginBottom: '1rem' }}>
                    <p style={styles.subHeaderText}>{userStatus}</p>

                </div> */}
                <div style={styles.innerContainer}>
                    <div style={styles.itemContainer}>
                        <Regular />
                        <p style={styles.itemText}>Basic</p>
                    </div>
                    <div>92</div>
                </div>
                <div style={styles.innerContainer}>
                    <div style={styles.itemContainer}>
                        <Healthy />
                        <p style={styles.itemText}>Regular</p>
                    </div>
                    <div>92</div>
                </div>
                <div style={styles.innerContainer}>
                    <div style={styles.itemContainer}>
                        <Diabetic />
                        <p style={styles.itemText}>Healthy</p>
                    </div>
                    <div>92</div>
                </div>
                <div style={styles.innerContainer}>
                    <div>
                        <span style={styles.totalText}>Total</span>
                    </div>
                    <div> <span style={styles.totalText}>170</span></div>
                </div>
            </div>
        </div>
    )
}

export default Mealtype