import React, { useCallback, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import { BsSearch } from "react-icons/bs";
// import CounterInput from "react-counter-input";
import { AiOutlineSortAscending } from "react-icons/ai";
import Switch from "react-switch";
// import useTable from '../Create/useTable'
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from '../../../store/product';
import { Colors } from '../../../Colors';
import { addonsAction } from '../../../store/addons';
import VegIcon from '../../../SVG/VegIcon';
import Non_Veg from '../../../SVG/Non_Veg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Veg_Non_Veg from '../../../SVG/Veg_Non_Veg';



function AddonsTomorrowInActive() {
    const styles = {
        table: {
            border: '1px solid #E9E9E9',
            color: Colors.deepBlue100
        },
        tableHead: {
            backgroundColor: Colors.yellow25,
        },
        tableBody: {
            backgroundColor: 'white',
        },
        outerStepperContainer: {
            display: 'flex',
            // backgroundColor: 'red',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%'
        },
        stepperContainer: {
            background: Colors.grey84,
            height: '30px',
            width: '30px',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.grey64,
            fontWeight: 800,
            fontSize: '16px'
        },
        inputStyle: {
            color: Colors.grey84,
            fontWeight: 500,
            outlineStyle: 'none',
            border: 'none'
        },
        modalContainer: {

            boxShadow: '0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid ${Colors.yellow100}`,
            borderRadius: 8
        },
        modalItemContainer: {
            cursor: "pointer",
            margin: '4px',
        },
        h2: {
            fontSize: '24px',
            fontWeight: '700'
        }
    }
    const CATEGORIES = ["Regular", 'Diabetic', 'Healthy']
    const addonsTomorrowInActive = useSelector(state => state.addons.addonsTomorrowInActive)
    const dispatch = useDispatch()
    const [page, setPage] = useState(1);
    const [checked, setChecked] = useState(false)
    const [sort, setSort] = useState(false)
    const [modal, setModal] = useState(false)
    const [counter, setCounter] = useState(0)
    // const [products, setProducts] = useState([...addonInActive])

    // const { slice, range } = useTable(products, page, 4);
    // const [state, setState] = useState({
    //     productsItem: products,
    //     filters: new Set(),
    // })



    // useEffect(() => {
    //     setProducts(state.productsItem)
    // }, [state.filters, state.productsItem])

    const checkedHandler = () => {
        setChecked(!checked)
        dispatch(addonsAction.tomorrowInActiveAllSelectHandler(checked))
    }
    const checkedOneHandler = (id, event) => {
        dispatch(addonsAction.tomorrowInActiveOneItemSelectHandler({ id: id, event: event }))
    }
    const sortNameHandler = () => {
        setSort(!sort)
        // const sortedArray = products.sort(function (a, b) {
        //     const x = a.name;
        //     const y = b.name;
        //     return sort ? (x > y ? 1 : -1) : (x < y ? 1 : -1)
        // }).map(prod => {
        //     return prod
        // })
        // setProducts(sortedArray)
    }


    const filterClearHandler = () => {
        //     setProducts(addonInActive)
    }
    const switchHandleChange = (id) => {
        const fdata = addonsTomorrowInActive.filter((item) => {
            return item.id != id
        })
        const sdata = addonsTomorrowInActive.filter((item) => {
            return item.id === id
        })
        dispatch(addonsAction.addonInActiveToActive({ InactiveToActive: sdata, InActive: fdata }))
    }
    const vegTypeHandler = () => {
        setModal(!modal)
    }
    const modalItemClickHandler = (selected) => {
        dispatch(addonsAction.tomorrowInActiveModalItemClickHandler(selected))
        setModal(false)
    }
    return (
        <div>
            <h2 style={styles.h2}>Inactive Addons</h2>
            <Table bordered hover className={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th>
                            <input type="checkbox" checked={checked} onChange={checkedHandler} />
                        </th>
                        <th ><Veg_Non_Veg /><RiArrowDropDownLine style={styles.icon} onClick={vegTypeHandler} />
                            {modal && (
                                <div style={styles.modalContainer}>
                                    <div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('All') }} id="clickable">All</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Veg') }} id="clickable">Veg</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Non-Veg') }} id="clickable">Non-Veg</div>
                                    </div>
                                </div>
                            )}</th>
                        <th>status</th>
                        <th>Name <AiOutlineSortAscending className={styles.Icon} onClick={sortNameHandler} /></th>
                        <th>Type</th>
                        <th>Ingredient</th>
                        <th>Price</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody style={styles.tableBody}>
                    {addonsTomorrowInActive.map((item, index) => (
                        <tr key={index}>
                            <td><input type="checkbox" checked={item.checked} onChange={(e) => checkedOneHandler(index, e)} /></td>
                            <td>{item.vegType === "Veg" ? <VegIcon /> : <Non_Veg />}</td>
                            <td>{item.status}
                                <Switch
                                    onChange={() => switchHandleChange(item.id)}
                                    checked={item.status} checkedIcon={false} uncheckedIcon={false} />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>{item.ingredient}</td>
                            <td>{item.price}</td>
                            <td>
                                {/* <CounterInput
                                    min={null}
                                    max={null}
                                    count={0}
                                    btnStyle={styles.stepperContainer}
                                    inputStyle={styles.inputStyle}
                                    onCountChange={count => setCounter(count)}
                                /> */}
                                </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} /> */}
        </div>
    )
}

export default AddonsTomorrowInActive