import React from 'react'

function RadioButtonSort({style,onClick}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={style}>
            <path d="M12.6667 11.3333H14.6667L12 14L9.33333 11.3333H11.3333V2H12.6667V11.3333ZM4.66667 2C3.19333 2 2 3.19333 2 4.66667C2 6.14 3.19333 7.33333 4.66667 7.33333C6.14 7.33333 7.33333 6.14 7.33333 4.66667C7.33333 3.19333 6.14 2 4.66667 2ZM4.66667 6C3.93333 6 3.33333 5.4 3.33333 4.66667C3.33333 3.93333 3.93333 3.33333 4.66667 3.33333C5.4 3.33333 6 3.93333 6 4.66667C6 5.4 5.4 6 4.66667 6ZM4.66667 8.66667C3.19333 8.66667 2 9.86 2 11.3333C2 12.8067 3.19333 14 4.66667 14C6.14 14 7.33333 12.8067 7.33333 11.3333C7.33333 9.86 6.14 8.66667 4.66667 8.66667Z" fill="#2F2C5D" />
        </svg>

    )
}

export default RadioButtonSort