import React from "react";
import Overview from "../Components/Customer/Overview/Overview";
import DashboardHome from "./DashboardHome";

const HomeMain = () => {
  return (
    <div>
      {/* <Overview/> */}

      <DashboardHome leftPanel={""} rightPanel={ <Overview/>} />
    </div>
  );
};

export default HomeMain;
