import React from "react";
import { Col, Row } from "react-bootstrap";

function Footer() {
  const styles = {
    backgroundColor: "#FFEE99",
    height: "200px",
  };
  return (
    <div style={styles}>
      <Row>
        <Col sm={3} />
        <Col>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "16px",
              fontWeight: "700",
            }}
          >
            <p style={{ cursor: "pointer" }}>Gmail:LalasKitchenTVM@gmail.com</p>
            <p style={{ cursor: "pointer" }}>Contact :+91 7994544423</p>
            {/* <p style={{cursor:"pointer"}}>Contact</p> */}
            {/* <p style={{cursor:"pointer"}}>FAQ</p> */}
            {/* <p style={{ cursor: "pointer" }}>About</p>
            <p style={{ cursor: "pointer" }}>Employee Login</p> */}
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              fontWeight: "900",
              fontSize: "20px",
            }}
          >
            <a
              href="https://www.google.com/search?sa=X&sca_esv=4d2b3b90e40bc13f&rlz=1C1CHBF_enIN1043IN1043&sxsrf=ACQVn0-gI5tI_RtyNXb870PKbHYmGcIWxQ:1711950427296&q=lala%27s+kitchen+thiruvananthapuram+address&ludocid=9846505251033377545&ved=2ahUKEwjK392oqKCFAxV_RmwGHT5rAUkQ6BN6BAhkEAI&biw=1536&bih=730&dpr=1.25"
              style={{ textDecoration: "underline", color: "#2F2C5D" }}
            >
              Address: Nallumuku, Pettah, Thiruvananthapuram, Kerala 695024
            </a>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              fontWeight: "900",
              fontSize: "20px",
            }}
          >
            <a
              href="http://www.bayesian.in/"
              style={{ textDecoration: "underline", color: "#2F2C5D" }}
            >
              Powered by Bayesian Ways LLP
            </a>
          </div>
          <div style={{ textAlign: "center", marginTop: "0px" }}>
            <p>© 2024 - Lalas Kitchen </p>
            <p>All rights reserved</p>
          </div>
        </Col>
        <Col sm={3} />
      </Row>
    </div>
  );
}

export default Footer;
