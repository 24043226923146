import React from 'react'

function Veg_Non_Veg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_234_3548"  maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <path d="M16 0L0 16H16V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_234_3548)">
                <rect x="1" y="1" width="14" height="14" stroke="#04CC48" stroke-width="2" />
            </g>
            <mask id="mask1_234_3548"  maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <path d="M16 0L0 16V0H16Z" fill="white" />
            </mask>
            <g mask="url(#mask1_234_3548)">
                <rect x="1" y="1" width="14" height="14" stroke="#F76719" stroke-width="2" />
            </g>
        </svg>

    )
}

export default Veg_Non_Veg