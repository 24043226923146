import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from '../../Colors'
import { RiArrowDropDownLine } from "react-icons/ri";
import Veg_Non_Veg from '../../../src/SVG/Veg_Non_Veg'
import VegIcon from '../../../src/SVG/VegIcon';
import Non_Veg from '../../../src/SVG/Non_Veg';
import { addonsAction } from '../../../src/store/addons';
import PrimaryButton from '../UTIL/Buttons/PrimaryButton';
import InactiveButton from '../UTIL/Buttons/InactiveButton';
import Add from '../../../src/SVG/Add';
import Archive from '../../SVG/Archive';
import Delete from '../../../src/SVG/Delete';
import SearchContainer from '../UTIL/SearchContainer';
import AddItem from '../AddItem';
import useTable from '../Create/useTable';
import TableFooter from '../Create/TableFooter';
import './Menu.css'
import Addnew from './Addnew';

function MenuItems() {
    const styles = {
        container: {
            flex: 1,
        },
        table: {
            border: '1px solid #E9E9E9',
            color: Colors.deepBlue100
        },
        tableHead: {
            backgroundColor: Colors.yellow25,
        },
        tableBody: {
            backgroundColor: 'white',
        },
        icon: {
            cursor: "pointer",
            height: '20px',
            width: '20px'
        },
        checkBoxContainer: {
            width: '250px',
            boxShadow: '0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: '#FFFCEB',
        },
        image: {
            height: "64px",
            width: "64px",
            borderRadius: '8px'
        },
        modalContainer: {

            boxShadow: '0px 2px 2px #9E9E9E',
            fontSize: '12px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid ${Colors.yellow100}`,
            borderRadius: 8
        },
        modalItemContainer: {
            cursor: "pointer",
            margin: '4px',
        },
        innerContainer: {
            display: "flex",
            // justifyContent: 'space-around',
            flex: 1,
            // alignItems: 'center',
            height: '2.5rem',
            marginBottom: '1rem'
        },
        buttonsContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
        },
        searchOuterContainer: {

            flex: 1,
            marginLeft: '3rem'
        }
    }
    const [checked, setChecked] = useState(false)
    const [modal, setModal] = useState(false)
    const addonsList = useSelector(state => state.addons.addonsList)
    const [search, setSearch] = useState('')
    const [addModal, setAddModal] = useState(false)
    const [page, setPage] = useState(1)
    const { slice, range } = useTable(addonsList, page, 3);
    const dispatch = useDispatch()
    const checkedHandler = () => {
        setChecked(!checked)
        dispatch(addonsAction.allSelectHandler(checked))
    }
    const vegTypeHandler = () => {
        setModal(!modal)
    }
    const modalItemClickHandler = (selected) => {
        dispatch(addonsAction.modalItemClickHandler(selected))
        setModal(false)
    }

    const checkedOneHandler = (id, event) => {
        dispatch(addonsAction.oneItemSelectHandler({ id: id, event: event }))
    }
    const deleteHandler = () => {
        dispatch(addonsAction.deleteHandler())
    }
    const addItemHandler = () => {
        setAddModal(!addModal)
    }
    const handleChange = (e) => {
        setSearch(e.target.value);
    }
    console.log(search);
    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <div style={styles.buttonsContainer}>
                    <PrimaryButton text={"Add new"} icon={<Add />} onClick={addItemHandler} />
                    <InactiveButton text={"Archive"} icon={<Archive />} />
                    <InactiveButton text={"Delete"} icon={<Delete />} onClick={deleteHandler} />
                </div>
                <div style={styles.searchOuterContainer}>
                    <SearchContainer search={search} handleChange={handleChange} />
                </div>
            </div>


            <Modal show={addModal} onHide={() => setAddModal(false)} dialogClassName='my-modal' size="lg"
                aria-labelledby="example-modal-sizes-title-lg">
                <Addnew addModal={addModal} setAddModal={setAddModal} />
            </Modal>

            <Table style={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th>
                            <input type="checkbox" checked={checked} onChange={checkedHandler} />
                        </th>
                        <th><Veg_Non_Veg /><RiArrowDropDownLine style={styles.icon} onClick={vegTypeHandler} />


                            {modal && (
                                <div style={styles.modalContainer}>
                                    <div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('All') }} id="clickable">All</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Veg') }} id="clickable">Veg</div>
                                        <div style={styles.modalItemContainer} onClick={() => { modalItemClickHandler('Non-Veg') }} id="clickable">Non-Veg</div>
                                    </div>
                                </div>
                            )}</th>
                        <th>Image </th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Ingredient</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody style={styles.tableBody}>
                    {slice.filter((val) => {
                        if (val.name.toLowerCase().includes(search.toLowerCase())) {
                            return val
                        }
                    }).map((item, index) => (
                        <tr key={index}>
                            <td valign='middle'><input type="checkbox" checked={item.checked} id={item.id} onChange={(e) => checkedOneHandler(index, e)} /></td>
                            <td valign='middle'>{item.vegType === "Veg" ? <VegIcon /> : <Non_Veg />}</td>
                            <td valign='middle'><img src={item.image} style={styles.image} /></td>
                            <td valign='middle'>{item.name}</td>
                            <td valign='middle' >{item.type}</td>
                            <td valign='middle'>{item.ingredient}</td>
                            <td valign='middle'>₹{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </div>
    )
}

export default MenuItems