const columns = [{
    Header: 'Id',
    accessor: 'id'
}, {
    Header: 'Item',
    accessor: 'item'
}, {
    Header: 'Ingredient',
    accessor: 'ingredient'
}, {
    Header: 'Type',
    accessor: 'type',
}, {
    Header: 'Number of Orders',
    accessor: d => d.order,
}]
export default columns;