// import React, { useEffect, useState } from "react";
// import { Modal, Image, Card } from "react-bootstrap";
// import { BASE_URL } from "../../../Service/service";

// function ImageModal({ show, onHide, onSelectImage }) {
//   const [images, setImages] = useState([]);

//   useEffect(() => {
//     if (show) {
//       fetchImages();
//     }
//   }, [show]);

//   const fetchImages = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}/listImages`);
//       const data = await response.json();
//       if (response.ok) {
//         setImages(data);
//       } else {
//         console.error("Error fetching images:", data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching images:", error.message);
//     }
//   };

//   return (
//     <Modal show={show} onHide={onHide}>
//       <Modal.Header closeButton>
//         <Modal.Title>Select Image</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {images.map((image) => (
//           <Card
//             key={image.id}
//             style={{ width: "200px", marginBottom: "10px", cursor: "pointer" }}
//             onClick={() => {
//               onSelectImage(image.image_url);
//               // console.log("Selected Image URL:", image.image_url); 
//             }}
//           >
//             <Card.Img variant="top" src={image.image_url} />
//             <Card.Body>
//               <Card.Title>{image.title}</Card.Title>
//               <Card.Text>{image.description}</Card.Text>
//             </Card.Body>
//           </Card>
//         ))}
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default ImageModal;



import React, { useEffect, useState } from "react";
import { Modal, Card, Container, Row, Col, Button } from "react-bootstrap";
import { X } from 'lucide-react';
import { BASE_URL } from "../../../Service/service";

function ImageModal({ show, onHide, onSelectImage }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (show) {
      fetchImages();
    }
  }, [show]);

  const fetchImages = async () => {
    try {
      const response = await fetch(`${BASE_URL}/listImages`);
      const data = await response.json();
      if (response.ok) {
        const processedImages = data.map(image => ({
          ...image,
          description: extractDescription(image.image_url)
        }));
        setImages(processedImages);
      } else {
        console.error("Error fetching images:", data.message);
      }
    } catch (error) {
      console.error("Error fetching images:", error.message);
    }
  };

  const extractDescription = (url) => {
    const filename = url.split('/').pop();
    return filename.split('.')[0].replace(/_/g, ' ');
  };

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      dialogClassName="custom-modal" 
      fullscreen={true}
      style={{
        maxWidth: '100%',  
        width: '100%',     
        height: '100%',    
        paddingTop:"10px",
        paddingBottom:"10px",
      }}
    >
      <Modal.Header 
        className="bg-primary text-white d-flex justify-content-between align-items-center"
        style={{
          height: '56px',   
        }}
      >
        <Button variant="link" className="text-white" onClick={onHide}>
          <X size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body 
        className="p-0"
        style={{
          height: 'calc(100% - 56px)',  
          overflowY: 'auto'            
        }}
      >
        <Container fluid className="h-100 p-4">
          <Row xs={1} sm={2} md={3} lg={4} xl={5} className="g-4">
            {images.map((image) => (
              <Col key={image.id} className="d-flex">
                <Card 
                  className="flex-grow-1 shadow-sm" 
                  onClick={() => onSelectImage(image.image_url)}
                  style={{ cursor: "pointer" }}
                >
                  <Card.Img 
                    variant="top" 
                    src={image.image_url} 
                    style={{ height: "200px", objectFit: "cover" }} 
                  />
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title className="text-center">
                      {image.description}
                    </Card.Title>
                    <Button variant="outline-primary" className="mt-2">Select</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ImageModal;

