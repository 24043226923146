import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PrimaryButton from "./Components/Buttons/PrimaryButton/PrimaryButton";
import "./Home.css";
import Menu from "./Components/Menu/Menu";
import Playstore from "./../SVG/Playstore";
import Appstore from "./../SVG/Appstore";
import Footer from "./Components/Footer/Footer";
import NavbarItem from "./Components/Navbar/Navbar";
import Main from "../Home/Constants/SVG/main";
import FAQ from "../Components/FAQ/FAQ";
import Aos from "aos";
import "aos/dist/aos.css";

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    setIsVisible(true);
  }, []);
  const openLink = () => {
    window.open(
      "https://drive.google.com/file/d/1CpmIg8efbGhVhAXhTcxozEng3evCVHnd/view?usp=sharing",
      "_blank"
    );
  };

  return (
    <div>
      {/* <NavbarItem /> */}
      <Container fluid>
        <Row>
          <div className="main_body">
            <Row>
              <Col sm={1}></Col>
              <Col
                xs={{ span: 12, order: 2 }}
                lg={{ span: 4, order: 1 }}
                md={{ span: 5, order: 1 }}
                style={{ display: "flex", alignItems: "center" }}
                className="contentContainer"
              >
                <div style={{ marginTop: "80px" }} data-aos="fade-up">
                  <h1
                    style={{ fontSize: "64px", fontWeight: "900" }}
                    className={`fadeIn text1 ${isVisible ? "visible" : ""}`}
                  >
                    {" "}
                    lala's kitchen
                  </h1>
                  <h3
                    style={{ fontSize: "28px", fontWeight: "700" }}
                    className={`fadeIn text2 ${isVisible ? "visible" : ""}`}
                  >
                    Homely meals at your doorstep
                  </h3>
                  <p
                    style={{ fontWeight: "600" }}
                    className={`fadeIn text3 ${isVisible ? "visible" : ""}`}
                  >
                    Welcome to Lala's Kitchen. We provide tasty and homely meals
                    on subscription packages. Explore our packages and subscribe
                    one today itself!
                  </p>
                  <div>
                    <PrimaryButton
                      styles={{
                        width: "70%",
                        background: "linear-gradient(#FAC105,#FFD814)",
                        border: "none",
                      }}
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.lalaskitchen.lalas&pcampaignid=web_share",
                          "_blank"
                        )
                      }
                    >
                      Get Our app
                    </PrimaryButton>
                  </div>
                </div>
              </Col>
              <Col sm={1}></Col>
              <Col
                xs={{ span: 12, order: 1 }}
                lg={{ span: 6, order: 2 }}
                md={{ span: 4, order: 4 }}
                style={{
                  marginTop: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="imageContainer"
              >
                <Main />
              </Col>
            </Row>
          </div>
        </Row>
        <div id="carousel" data-aos="fade-up">
          {/* <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              margin: "56px 0px 30px 0px",
            }}
          >
            Subscription Plans
          </h1> */}
          {/* <SubscriptionPlan /> */}
        </div>
        <div id="menu" data-aos="zoom-in-up">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              margin: "36px 0px 20px 0px",
            }}
          >
            Regular Meals Menu
          </h1>
          <Menu />
        </div>
        <Row>
          <div
            id="cardCarousel"
            style={{ marginTop: "32px" }}
            data-aos="fade-up"
          >
            {/* <CardCarousel /> */}
          </div>
        </Row>

        {/* <a href="#contact">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              margin: "56px 0px 0px 0px",
            }}
          >
            FAQ
          </h1>
        </a> */}
        <Row></Row>
        <div
          id="cardCarousel"
          style={{ fontSize: "16px", padding: "32px" }}
          data-aos="fade-up"
        >
          <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              margin: "48px 0px 30px 0px",
            }}
          >
            About
          </h1>
          <p style={{ textAlign: "justify" }}>
            Jose Louis believes food really is love and care. When our lives had
            come to a standstill during the lockdown, Josettan received many
            queries for a tiffin service. Many, especially elderly and working
            people, prefer a tiffin service with good quality and homely taste.
          </p>

          <p style={{ textAlign: "justify" }}>
            Homely food delivery services focus on providing nutritious and
            freshly prepared meals delivered directly to people at their homes
            or offices. This service is becoming increasingly popular among
            people who have busy schedules, limited mobility and no time or
            energy to prepare their meals.
          </p>

          <p style={{ textAlign: "justify" }}>
            {" "}
            As the need of the hour, Josettan decided to turn his love for food
            into a business idea- Lala’s Kitchen. While starting a cloud kitchen
            brand, no other name crossed his mind. The Brand is named after
            Josettan’s Lala aunt, whose preparations still remain his favourite
            food memories. Thus the name Lala’s Kitchen!
          </p>

          <p style={{ textAlign: "justify" }}>
            {" "}
            Starting from a small scale, the service is growing exponentially in
            the city of Thiruvananthapuram. In Lala’s kitchen, the meals are
            prepared using fresh and wholesome ingredients and are portioned to
            meet the daily nutritional requirements of each and everyone. These
            meals are usually prepared using traditional cooking methods, and
            without any preservatives or artificial additives.
          </p>

          <p style={{ textAlign: "justify" }}>
            {" "}
            Our service would be a takeaway for people who are trying to
            maintain a healthy diet or those who have specific dietary
            requirements.
          </p>

          <p style={{ textAlign: "justify" }}>
            {" "}
            Yes, we want you and your beloved ones to eat healthy and nutritious
            meals!
          </p>
        </div>
        {/* <Row style={{ display: "flex" }} data-aos="fade-up">
          <Col sm={2} />
          <Col sm={5}>
            <img
              src={require("./assets/images/About1.jpg")}
              style={{
                width: "100%",
                height: "100%",
                border: "2px solid yellow",
                borderRadius: "8px",
              }}
            />
          </Col>
          <Col sm={3}>
            <img
              src={require("./assets/images/About2.jpg")}
              style={{
                width: "100%",
                height: "100%",
                border: "2px solid yellow",
                borderRadius: "8px",
              }}
            />
          </Col>
          <Col sm={1} />
        </Row> */}
        {/* <a href="#contact">
                    <h2 style={{ textAlign: "center", fontWeight: "900",margin: "24px 0px 30px 0px" }}>Contact Us</h2>
                </a> */}
        <div data-aos="fade-up">
          <a href="/privacy" style={{ textDecoration: "none" }}>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "900",
                margin: "24px 0px 30px 0px",
                color: "#2F2C5D",
              }}
            >
              Privacy Policy
            </h2>
          </a>
          <a href="/terms" style={{ textDecoration: "none" }}>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "900",
                margin: "24px 0px 30px 0px",
                color: "#2F2C5D",
              }}
            >
              Terms & Conditions
            </h2>
          </a>
          <a href="/deleteform" style={{ textDecoration: "none" }}>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "900",
                margin: "24px 0px 30px 0px",
                color: "#2F2C5D",
              }}
            >
              Account Deletion Form
            </h2>
          </a>
        </div>
        <div id="apps" style={{ marginBottom: "32px" }} data-aos="fade-up">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              margin: "56px 0px 30px 0px",
            }}
          >
            Get Our App
          </h1>
          <p style={{ textAlign: "center" }}>
            You can download the app from following places
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.lalaskitchen.lalas&pcampaignid=web_share",
                  "_blank"
                )
              }
            >
              <Playstore />
            </div>

            <div style={{ width: "50px" }} />
            <Appstore />
          </div>
        </div>
        <h1
          style={{
            textAlign: "center",
            fontWeight: "900",
            margin: "56px 0px 0px 0px",
          }}
        >
          FAQ
        </h1>
        <Row data-aos="fade-up">
          <Col sm={2} />
          <Col sm={8}>
            <div id="cardCarousel" style={{ marginBottom: "2rem" }}>
              <div>
                <FAQ
                  faq={"How long would the subscription period be?"}
                  answer={
                    "The customer can opt for the subscription duration according to her/his needs. If you want one month of service, it would be available. "
                  }
                />
                <FAQ
                  faq={"What if you want to skip the meal service for a week? "}
                  answer={
                    "If the customer could inform us prior to the break, this option can be availed. "
                  }
                />
                <FAQ
                  faq={"Can you change the plans in between?"}
                  answer={
                    "It is normal to have changes in our plans and so on food choices too. Suppose you are subscribed to a Healthy meal plan and you want to have regular food on a fine Sunday/holiday, it is very much possible. You just have to inform us prior to this change. "
                  }
                />
                <FAQ
                  faq={"Will you get extra meals?"}
                  answer={
                    "Yes, you can order extra meals. At times, you would be having some friends or family coming over and then you can contact Lala’s Kitchen for extra meals."
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={2} />
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  );
}

export default Home;
