import { configureStore } from '@reduxjs/toolkit'
import addonsSlice from './addons'
import customerSlice from './customer'
import productSlice from './product'

const store = configureStore({
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    reducer: {
        products: productSlice.reducer,
        customer: customerSlice.reducer,
        addons: addonsSlice.reducer,
    }
})
export default store