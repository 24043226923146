// import React, { useEffect, useState } from "react";
// import NavigationBar from "../../NavigationBar";
// import styles from "./TodaysOrder.module.css";
// import Mealtype from "../Cards/Mealtype";
// import Non_Veg from "../../SVG/Non_Veg";
// import DateSlider from "../Active orders/DateSlider";
// import VegIcon from "../../SVG/VegIcon";
// import { Col, Container, Row } from "react-bootstrap";
// import Menu from "../../Home/Components/Menu/Menu";
// import ActiveCard from "../Active orders/ActiveCard";
// import Footer from "../../Home/Components/Footer/Footer";
// import axios from "axios";
// import { BASE_URL } from "../../Service/service";
// import orderData from "./ee.json";
// import example from "./e.json";
// import { Colors } from "../../Colors";
// function TodaysOrder() {
//   const [orders, setOrders] = useState([]);
//   const [completeMenu, setCompleteMenu] = useState([]);
//   const [menu, setMenu] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [filteredOrders, setFilteresOrders] = useState([]);
//   let vegBreakfastCount = 0;
//   let vegLunchCount = 0;
//   let vegDinnerCount = 0;

//   let nonVegBreakfastCount = 0;
//   let nonVegLunchCount = 0;
//   let nonVegDinnerCount = 0;
//   useEffect(() => {
//     axios
//       .get(`${BASE_URL}/createSetupMeals`)
//       .then((response) => {
//         // Sort orders by subscription_status
//         const sortedOrders = response.data.sort((a, b) => {
//           if (a.subscription_status === "Active") return -1;
//           if (b.subscription_status === "Active") return 1;
//           return 0;
//         });
//         setOrders(sortedOrders);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//     axios
//       .get(`${BASE_URL}/menu`)
//       .then((response) => {
//         if (response.status === 200) setCompleteMenu(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const parseDate = (dateString) => {
//     const [day, month, year] = dateString.split("/");
//     return new Date(year, month - 1, day);
//   };

//   useEffect(() => {
//     const options = { weekday: "long" };
//     const dayName = new Intl.DateTimeFormat("en-US", options).format(
//       selectedDate
//     );
//     const TodaysMenu = completeMenu.filter((menu) => menu.weektype === dayName);

//     // console.log(TodaysMenu);
//     setMenu(TodaysMenu);
//     const todayWithoutTime = new Date(
//       selectedDate.getFullYear(),
//       selectedDate.getMonth(),
//       selectedDate.getDate()
//     );
//     const filteredOrders1 = orders.filter((order) => {
//       const startDate = parseDate(order.subscription_details.startDate);
//       const endDate = parseDate(order.subscription_details.endDate);

//       return startDate <= todayWithoutTime && todayWithoutTime <= endDate;
//     });

//     setFilteresOrders(filteredOrders1);
//   }, [selectedDate]);

//   const vegMenu = menu.filter((menu) => menu.vegtype === "Veg");
//   // console.log(vegMenu);

//   const vegOrders = filteredOrders.flatMap((order) =>
//     order.subscription_details.tokens
//       .filter((token) => token.vegtype === "Veg")
//       .map((token) => ({
//         ...token,
//         orderid: order.orderid,
//       }))
//   );

//   vegOrders.forEach((order) => {
//     // Increment counts based on the values in each order
//     if (order.Breakfast === "Yes") {
//       vegBreakfastCount += order.numberOfMeals;
//     }
//     if (order.Lunch === "Yes" && order.vegtype === "Veg") {
//       vegLunchCount += order.numberOfMeals;
//     }
//     if (order.Dinner === "Yes" && order.vegtype === "Veg") {
//       vegDinnerCount += order.numberOfMeals;
//     }
//   });

//   const nonVegOrders = filteredOrders.flatMap((order) =>
//     order.subscription_details.tokens
//       .filter((token) => token.vegtype === "Non-Veg")
//       .map((token) => ({
//         ...token,
//         orderid: order.orderid,
//       }))
//   );
//   nonVegOrders.forEach((order) => {
//     // Increment counts based on the values in each order
//     if (order.Breakfast === "Yes" && order.vegtype === "Non-Veg") {
//       nonVegBreakfastCount += order.numberOfMeals;
//     }
//     if (order.Lunch === "Yes" && order.vegtype === "Non-Veg") {
//       nonVegLunchCount += order.numberOfMeals;
//     }
//     if (order.Dinner === "Yes" && order.vegtype === "Non-Veg") {
//       nonVegDinnerCount += order.numberOfMeals;
//     }
//   });
//   const NonVegbreakfastItems = nonVegOrders
//     .filter((order) => order.Breakfast === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[0].mealType }))
//     );
//   // console.log(NonVegbreakfastItems);
//   const NonVeglunchItems = nonVegOrders
//     .filter((order) => order.Lunch === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[1].mealType }))
//     );

//   const NonVegdinnerItems = nonVegOrders
//     .filter((order) => order.Dinner === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[2].mealType }))
//     );
//   const VegbreakfastItems = vegOrders
//     .filter((order) => order.Breakfast === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[0].mealType }))
//     );

//   // console.log(JSON.stringify(updatedVegbreakfastItems));

//   const VeglunchItems = vegOrders
//     .filter((order) => order.Lunch === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[1].mealType }))
//     );

//   const VegdinnerItems = vegOrders
//     .filter((order) => order.Dinner === "Yes")
//     .flatMap((data) =>
//       menu
//         .filter(
//           (m) => m.vegtype === data.vegtype && m.menutype === data.menutype
//         )
//         .map((item) => ({ ...data, item: item.menu[2].mealType }))
//     );

//   const handlePrevDate = () => {
//     const prevDate = new Date(selectedDate);
//     prevDate.setDate(prevDate.getDate() - 1);
//     setSelectedDate(prevDate);
//   };

//   const handleNextDate = () => {
//     console.log("select date " + selectedDate);
//     const nextDate = new Date(selectedDate);
//     console.log("next date " + nextDate);
//     nextDate.setDate(nextDate.getDate() + 1);
//     setSelectedDate(nextDate);
//   };
//   return (
//     <div  style={{minHeight: "100vh", }}>
//       <NavigationBar />
//       <h1
//         style={{
//           margin: "24px",
//           display: "flex",
//           justifyContent: "center",
//         }}
//       >
//         Order Details
//       </h1>
//       <div >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             padding: "0 5rem 0 5rem",
//           }}
//         >
//           <button
//             onClick={handlePrevDate}
//             style={{
//               background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
//               color: "black",
//               border: "none",
//               fontWeight: "600",
//               borderRadius: "5px",
//               padding: "1rem 2rem            ",
//               cursor: "pointer",
//               minWidth: "40px",
//             }}
//           >
//             &lt;
//           </button>
//           <div
//             style={{
//               flex: 1,
//               textAlign: "center",
//               padding: "1rem 2rem            ",
//               margin: "0 5px",
//               border: "1px solid #ccc",
//               borderRadius: "5px",
//               backgroundColor: "#f0f0f0",
//               boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//               fontWeight: "bold",
//               color: "black",
//             }}
//           >
//             {selectedDate.toLocaleDateString()}
//           </div>
//           <button
//             onClick={handleNextDate}
//             style={{
//               background: `linear-gradient(${Colors.Primary}, ${Colors.Secondary})`,
//               color: "black",
//               border: "none",
//               fontWeight: "600",
//               borderRadius: "5px",
//               padding: "1rem 2rem            ",
//               cursor: "pointer",
//               minWidth: "40px",
//             }}
//           >
//             &gt;
//           </button>
//         </div>

//         <div style={{ margin: "16px 80px 0px 80px" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               fontSize: "24px",
//               marginBottom: "16px",
//             }}
//           >
//             <div>
//               <Non_Veg
//                 styles={{ height: "24px", width: "24px", marginRight: "8px" }}
//               />
//             </div>
//             <div>Non-Vegetarian</div>
//           </div>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr 1fr",
//               columnGap: "80px",
//             }}
//           >
//             <div>
//               <ActiveCard
//                 mealTime={"Breakfast"}
//                 count={nonVegBreakfastCount}
//                 data={NonVegbreakfastItems}
//               />
//             </div>
//             <div>
//               <ActiveCard
//                 mealTime={"Lunch"}
//                 count={nonVegLunchCount}
//                 data={NonVeglunchItems}
//               />
//             </div>
//             <div>
//               <ActiveCard
//                 mealTime={"Dinner"}
//                 count={nonVegDinnerCount}
//                 data={NonVegdinnerItems}
//               />
//             </div>
//           </div>
//         </div>
//         <div style={{ margin: "16px 80px 80px 80px" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               fontSize: "24px",
//             }}
//           >
//             <div>
//               <VegIcon
//                 styles={{ height: "24px", width: "24px", marginRight: "8px" }}
//               />
//             </div>
//             <div>Vegetarian</div>
//           </div>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr 1fr",
//               columnGap: "80px",
//             }}
//           >
//             <div>
//               <ActiveCard
//                 mealTime={"Breakfast"}
//                 count={vegBreakfastCount}
//                 data={VegbreakfastItems}
//               />
//             </div>
//             <div>
//               <ActiveCard
//                 mealTime={"Lunch"}
//                 count={vegLunchCount}
//                 data={VeglunchItems}
//               />
//             </div>
//             <div>
//               <ActiveCard
//                 mealTime={"Dinner"}
//                 count={vegDinnerCount}
//                 data={VegdinnerItems}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <Footer /> */}
//     </div>
//   );
// }

// export default TodaysOrder;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import NavigationBar from "../../NavigationBar";
// import { Colors } from "../../Colors";
// import { BASE_URL } from "../../Service/service";
// const Dashboard = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     axios
//       .get(`${BASE_URL}/createSetupMeals`)
//       .then((response) => {
//         setData(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);
//   const getCounts = (vegType, mealType, menuType) => {
//     return data.filter((item) =>
//       item.subscription_details.tokens.some(
//         (token) =>
//           token.vegtype === vegType &&
//           token[mealType] === "Yes" &&
//           token.menutype === menuType
//       )
//     ).length;
//   };
//   const getVegTypeLabel = (vegType) => {
//     if (vegType === "Veg") return "Vegetarian";
//     if (vegType === "Non-Veg") return "Non-Vegetarian";
//     return vegType;
//   };
//   return (
//     <div>
//       <NavigationBar />
//       <div style={styles.container}>
//         <h1 style={styles.title}>Today's Order</h1>
//         <div style={styles.categoryContainer}>
//           {["Veg", "Non-Veg"].map((vegType) => (
//             <div key={vegType} style={styles.category}>
//               <h2 style={styles.vegType}>{getVegTypeLabel(vegType)}</h2>
//               <div style={styles.menuRow}>
//                 {["Regular", "Basic", "Healthy"].map((menuType) => (
//                   <div key={menuType} style={styles.menuColumn}>
//                     <h3 style={styles.menuTypeTitle}>{menuType}</h3>
//                     {["Breakfast", "Lunch", "Dinner"].map((mealType) => (
//                       <div key={mealType} style={styles.mealCard}>
//                         <h4 style={styles.mealType}>{mealType}</h4>
//                         <p style={styles.count}>
//                           {getCounts(vegType, mealType, menuType)}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     padding: "20px",
//     maxWidth: "1400px",
//     margin: "0 auto",
//   },
//   title: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   categoryContainer: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   category: {
//     marginBottom: "40px",
//   },
//   vegType: {
//     // fontSize: "1.5rem",
//     marginBottom: "10px",
//     // textAlign: "center",
//   },
//   menuRow: {
//     display: "flex",
//     justifyContent: "space-around",
//   },
//   menuColumn: {
//     flex: "1",
//     margin: "0 10px",
//   },
//   menuTypeTitle: {
//     backgroundColor: Colors.deepBlue100,
//     color: "white",
//     padding: "10px",
//     fontSize: "1.5rem",
//     fontWeight: "bold",
//     textAlign: "center",
//     marginBottom: "10px",
//   },
//   mealCard: {
//     backgroundColor: "#f8f9fa",
//     border: "1px solid #2165CC",
//     marginBottom: "10px",
//     padding: "10px",
//     textAlign: "center",
//   },
//   mealType: {
//     fontSize: "1.3rem",
//     marginBottom: "5px",
//   },
//   count: {
//     fontSize: "1rem",
//     fontSize: "1.3rem",
//     fontWeight: "bold",
//   },
// };

// export default Dashboard;


import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationBar from "../../NavigationBar";
import { Colors } from "../../Colors";
import { BASE_URL } from "../../Service/service";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState([]);

 useEffect(() => {
    axios
      .get(`${BASE_URL}/createSetupMeals`)
      .then((response) => {
        // Filter 
        const filteredSubscriptions = response.data.filter(
          (item) => 
            item.subscription_status === "Active" && 
            (item.order_status === 2)
        );
        setData(filteredSubscriptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Generate dates for the slider
    const dateRange = [];
    for (let i = -3; i <= 3; i++) {
      dateRange.push(addDays(new Date(), i));
    }
    setDates(dateRange);
  }, []);

  

  const getCounts = (vegType, mealType, menuType) => {
    const selectedDateWithoutTime = new Date(selectedDate.setHours(0, 0, 0, 0));
  
    return data.reduce((count, item) => {
      const startDate = parseDate(item.subscription_details.startDate);
      const endDate = parseDate(item.subscription_details.endDate);
  
      if (startDate <= selectedDateWithoutTime && endDate >= selectedDateWithoutTime) {
        const matchingTokens = item.subscription_details.tokens.filter(
          (token) =>
            token.vegtype === vegType &&
            token[mealType] === "Yes" &&
            token.menutype === menuType
        );
  
        return count + matchingTokens.reduce((sum, token) => sum + token.numberOfMeals, 0);
      }
  
      return count;
    }, 0);
  };
  const getVegTypeLabel = (vegType) => {
    if (vegType === "Veg") return "Vegetarian";
    if (vegType === "Non-Veg") return "Non-Vegetarian";
    return vegType;
  };

  // Helper function to add days to a date
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // Helper function to parse date string
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);
  };

  // Helper function to format date
  const formatDate = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return {
      day: days[date.getDay()],
      date: date.getDate(),
      month: months[date.getMonth()]
    };
  };

  return (
    <div>
      {/* <NavigationBar /> */}
      <div style={styles.container}>
      <h1 style={styles.title}>Today's Active Orders</h1>
        <div style={styles.dateSliderContainer}>
          <div style={styles.dateSlider}>
            {dates.map((date) => {
              const formattedDate = formatDate(date);
              return (
                <div
                  key={date.toISOString()}
                  style={{
                    ...styles.dateCard,
                    ...(selectedDate.toDateString() === date.toDateString() ? styles.selectedDateCard : {})
                  }}
                  onClick={() => setSelectedDate(date)}
                >
                  <div style={styles.dayOfWeek}>{formattedDate.day}</div>
                  <div style={styles.dateNumber}>{formattedDate.date}</div>
                  <div style={styles.month}>{formattedDate.month}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={styles.categoryContainer}>
          {["Veg", "Non-Veg"].map((vegType) => (
            <div key={vegType} style={styles.category}>
              <h2 style={styles.vegType}>{getVegTypeLabel(vegType)}</h2>
              <div style={styles.menuRow}>
                {["Regular", "Basic", "Healthy"].map((menuType) => (
                  <div key={menuType} style={styles.menuColumn}>
                    <h3 style={styles.menuTypeTitle}>{menuType}</h3>
                    {["Breakfast", "Lunch", "Dinner"].map((mealType) => (
                      <div key={mealType} style={styles.mealCard}>
                        <h4 style={styles.mealType}>{mealType}</h4>
                        <p style={styles.count}>
                          {getCounts(vegType, mealType, menuType)}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "1400px",
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dateSlider: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '10px 0',
    marginBottom: '20px',
  },
  dateSliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  dateCard: {
    display: 'inline-block',
    width: '100px',
    textAlign: 'center',
    padding: '10px',
    border: '1px solid #2F2C5D',
    borderRadius: '5px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedDateCard: {
    // backgroundColor: Colors.deepBlue100,
    // color: 'white',
    background: Colors.LinearGradient 
  },
  dayOfWeek: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  dateNumber: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  month: {
    fontSize: '0.8rem',
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  category: {
    marginBottom: "40px",
  },
  vegType: {
    marginBottom: "10px",
  },
  menuRow: {
    display: "flex",
    justifyContent: "space-around",
  },
  menuColumn: {
    flex: "1",
    margin: "0 10px",
  },
  menuTypeTitle: {
   background: Colors.LinearGradient,
    // color: "white",
    padding: "10px",
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "10px",
  },
  mealCard: {
    backgroundColor: "#f8f9fa",
    border: "1px solid #2165CC",
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
  },
  mealType: {
    fontSize: "1.3rem",
    marginBottom: "5px",
  },
  count: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
};

export default Dashboard;