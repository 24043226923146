import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Accordion from "react-bootstrap/Accordion";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import "./FAQ.css";

export default function App({ faq, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ marginTop: "1rem" }} >
      <Accordion style={{ color: "red" }}>
        <Accordion.Item eventKey="0" >
          <Accordion.Header style={{ color: "red" }}>{faq}</Accordion.Header>
          <Accordion.Body >{answer}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
