import React from 'react'

function Diabetic({styles}) {
  return (
    <svg width="20" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
<path d="M12.5 23.9999C10.1266 23.9999 7.80651 23.2962 5.83312 21.9776C3.85973 20.659 2.32166 18.7849 1.41341 16.5921C0.505154 14.3994 0.267514 11.9866 0.730536 9.65886C1.19356 7.33109 2.33645 5.19289 4.01468 3.51466C5.69291 1.83643 7.8311 0.693544 10.1589 0.230521C12.4866 -0.232502 14.8994 0.0051384 17.0922 0.91339C19.2849 1.82164 21.159 3.35971 22.4776 5.3331C23.7962 7.30649 24.5 9.62657 24.5 11.9999C24.4966 15.1815 23.2312 18.2318 20.9815 20.4815C18.7318 22.7312 15.6815 23.9966 12.5 23.9999ZM12.5 0.923021C10.3092 0.923021 8.16754 1.57267 6.34595 2.78982C4.52436 4.00697 3.1046 5.73695 2.26622 7.76099C1.42783 9.78503 1.20847 12.0122 1.63588 14.1609C2.06328 16.3097 3.11826 18.2834 4.66739 19.8325C6.21653 21.3816 8.19025 22.4366 10.339 22.864C12.4877 23.2914 14.7149 23.0721 16.7389 22.2337C18.763 21.3953 20.4929 19.9755 21.7101 18.154C22.9272 16.3324 23.5769 14.1908 23.5769 11.9999C23.5735 9.06319 22.4054 6.24769 20.3288 4.17109C18.2522 2.09449 15.4367 0.926381 12.5 0.923021Z" fill="#C71047"/>
<path d="M14.7765 8.42305C13.1923 5.53151 12.5 3.24805 12.5 3.24805C12.5 3.24805 11.8077 5.53151 10.2235 8.42305C8.95423 10.7423 6.92346 12.6646 7.19808 15.7696C7.35269 17.528 9.13769 20.7173 12.5058 20.753C15.8681 20.7173 17.6531 17.528 17.8077 15.7696C18.0765 12.6634 16.0435 10.7411 14.7765 8.42305ZM12.3662 16.8692C12.3662 16.9916 12.3175 17.109 12.231 17.1956C12.1444 17.2821 12.027 17.3307 11.9046 17.3307H10.0077C9.88528 17.3307 9.76789 17.2821 9.68133 17.1956C9.59478 17.109 9.54615 16.9916 9.54615 16.8692V14.97C9.54615 14.8476 9.59478 14.7302 9.68133 14.6436C9.76789 14.5571 9.88528 14.5084 10.0077 14.5084H11.9046C12.027 14.5084 12.1444 14.5571 12.231 14.6436C12.3175 14.7302 12.3662 14.8476 12.3662 14.97V16.8692ZM11.5515 14.3711C11.4291 14.3711 11.3117 14.3225 11.2252 14.2359C11.1386 14.1494 11.09 14.032 11.09 13.9096V12.0104C11.09 11.8879 11.1386 11.7706 11.2252 11.684C11.3117 11.5974 11.4291 11.5488 11.5515 11.5488H13.4485C13.5709 11.5488 13.6883 11.5974 13.7748 11.684C13.8614 11.7706 13.91 11.8879 13.91 12.0104V13.9084C13.91 14.0308 13.8614 14.1482 13.7748 14.2348C13.6883 14.3213 13.5709 14.37 13.4485 14.37L11.5515 14.3711ZM15.4538 16.8692C15.4538 16.9916 15.4052 17.109 15.3187 17.1956C15.2321 17.2821 15.1147 17.3307 14.9923 17.3307H13.0954C12.973 17.3307 12.8556 17.2821 12.769 17.1956C12.6825 17.109 12.6338 16.9916 12.6338 16.8692V14.97C12.6338 14.8476 12.6825 14.7302 12.769 14.6436C12.8556 14.5571 12.973 14.5084 13.0954 14.5084H14.9923C15.1147 14.5084 15.2321 14.5571 15.3187 14.6436C15.4052 14.7302 15.4538 14.8476 15.4538 14.97V16.8692Z" fill="#C71047"/>
<path d="M11.7627 11.8708H11.5619C11.4982 11.8708 11.4465 11.9224 11.4465 11.9862V12.1869C11.4465 12.2507 11.4982 12.3023 11.5619 12.3023H11.7627C11.8264 12.3023 11.8781 12.2507 11.8781 12.1869V11.9862C11.8781 11.9224 11.8264 11.8708 11.7627 11.8708Z" fill="#C71047"/>
<path d="M12.3465 11.8708H12.1458C12.0821 11.8708 12.0304 11.9224 12.0304 11.9862V12.1869C12.0304 12.2507 12.0821 12.3023 12.1458 12.3023H12.3465C12.4103 12.3023 12.4619 12.2507 12.4619 12.1869V11.9862C12.4619 11.9224 12.4103 11.8708 12.3465 11.8708Z" fill="#C71047"/>
<path d="M12.0546 12.4512H11.8538C11.7901 12.4512 11.7385 12.5028 11.7385 12.5666V12.7673C11.7385 12.8311 11.7901 12.8827 11.8538 12.8827H12.0546C12.1183 12.8827 12.17 12.8311 12.17 12.7673V12.5666C12.17 12.5028 12.1183 12.4512 12.0546 12.4512Z" fill="#C71047"/>
<path d="M10.1796 14.8177H9.97885C9.91512 14.8177 9.86346 14.8693 9.86346 14.9331V15.1338C9.86346 15.1976 9.91512 15.2492 9.97885 15.2492H10.1796C10.2433 15.2492 10.295 15.1976 10.295 15.1338V14.9331C10.295 14.8693 10.2433 14.8177 10.1796 14.8177Z" fill="#C71047"/>
<path d="M10.7634 14.8177H10.5627C10.499 14.8177 10.4473 14.8693 10.4473 14.9331V15.1338C10.4473 15.1976 10.499 15.2492 10.5627 15.2492H10.7634C10.8272 15.2492 10.8788 15.1976 10.8788 15.1338V14.9331C10.8788 14.8693 10.8272 14.8177 10.7634 14.8177Z" fill="#C71047"/>
<path d="M10.4715 15.3969H10.2708C10.2071 15.3969 10.1554 15.4486 10.1554 15.5123V15.7131C10.1554 15.7768 10.2071 15.8284 10.2708 15.8284H10.4715C10.5353 15.8284 10.5869 15.7768 10.5869 15.7131V15.5123C10.5869 15.4486 10.5353 15.3969 10.4715 15.3969Z" fill="#C71047"/>
<path d="M13.3031 14.8177H13.1023C13.0386 14.8177 12.9869 14.8693 12.9869 14.9331V15.1338C12.9869 15.1976 13.0386 15.2492 13.1023 15.2492H13.3031C13.3668 15.2492 13.4185 15.1976 13.4185 15.1338V14.9331C13.4185 14.8693 13.3668 14.8177 13.3031 14.8177Z" fill="#C71047"/>
<path d="M13.8869 14.8177H13.6862C13.6224 14.8177 13.5708 14.8693 13.5708 14.9331V15.1338C13.5708 15.1976 13.6224 15.2492 13.6862 15.2492H13.8869C13.9506 15.2492 14.0023 15.1976 14.0023 15.1338V14.9331C14.0023 14.8693 13.9506 14.8177 13.8869 14.8177Z" fill="#C71047"/>
<path d="M13.595 15.3969H13.3942C13.3305 15.3969 13.2788 15.4486 13.2788 15.5123V15.7131C13.2788 15.7768 13.3305 15.8284 13.3942 15.8284H13.595C13.6587 15.8284 13.7104 15.7768 13.7104 15.7131V15.5123C13.7104 15.4486 13.6587 15.3969 13.595 15.3969Z" fill="#C71047"/>
</svg>

  )
}

export default Diabetic