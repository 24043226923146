import React from 'react'
import './Menu.css'
import Non_Veg from './../../SVG/Non_Veg';
import Idiyappam from '../../assets/idiyappam.jpg';
import MenuEdit from './MenuEdit';

function MenuCard() {
  return (
    <div className="card">
      <div className="image" style={{ backgroundImage: `url(${Idiyappam})`, }}>
        {/* <img src={require('../../assets/BeefChilly.jpg')} width="100%" alt="game image" /> */}
      </div>
      <div className='cardname'>
        <div className='cardname1'>
          <div>
          <Non_Veg />
          </div>
          <div style={{marginLeft:'4px',fontSize:'17px'}}>Breakfast</div>
        </div>
        <h3 className='itemText'>Appam</h3>
        <h3 className='itemText'>Chicken Stew</h3>
      </div>
      <div>
        <MenuEdit  />
      </div>
    </div>


  )
}

export default MenuCard


