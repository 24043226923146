import React from 'react'

function CheckBoxSort({ onClick, style }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={style}>
            <path d="M12.6667 11.3333H14.6667L12 14L9.33333 11.3333H11.3333V2H12.6667V11.3333ZM6 8.66667H3.33333C2.59333 8.66667 2 9.26 2 10V12.6667C2 13.4067 2.59333 14 3.33333 14H6C6.74 14 7.33333 13.4067 7.33333 12.6667V10C7.33333 9.26 6.74 8.66667 6 8.66667ZM4.18 13L2.49333 11.3L3.20667 10.6L4.18667 11.5933L6.13333 9.66667L6.84 10.3667L4.18 13ZM6 2H3.33333C2.59333 2 2 2.59333 2 3.33333V6C2 6.74 2.59333 7.33333 3.33333 7.33333H6C6.74 7.33333 7.33333 6.74 7.33333 6V3.33333C7.33333 2.59333 6.74 2 6 2ZM6 6H3.33333V3.33333H6V6Z" fill="#2F2C5D" />
        </svg>

    )
}

export default CheckBoxSort