import React from 'react'
import AddonsActiveToday from '../Tables/Addons/AddonsActiveToday'
import AddonsTomorrowActive from '../Tables/Addons/AddonsTomorrowActive'
import AddonsTomorrowInActive from '../Tables/Addons/AddonsTomorrowInActive'

function ActiveAddonsTomorrow() {
    const styles = {
        container: {
            marginTop: '2rem'
        }
    }
    return (
        <div style={styles.container}>
            <AddonsTomorrowActive />
            <AddonsTomorrowInActive />
        </div>
    )
}

export default ActiveAddonsTomorrow