import React from 'react'
import Mealtype from '../../Cards/Mealtype'
import MealtypeWithSelector from '../../Cards/MealTypeWithSelector'
import Selector from '../../Selector/Selector'
import Non_Veg from '../../../SVG/Non_Veg'
import Veg from '../../../SVG/VegIcon'
import Customer from '../CustomerMain/Customer'
function Overview() {
  const styles = {
    container: {
      padding: '2rem',
      // height: "700px",
      flex: 1,
    },
    headerTextMain: {
      fontSize: '24px',
      fontWeight: '700'
    },
    activeContainer: {
      flex: 1,
      height: '150px',
      display: "flex",
      justifyContent: 'space-between',
    },
    currentContainer: {
      flex: 1,
      backgroundColor: 'white',
      boxShadow: '1px 1px 4px 0px #9E9E9E',
      height: '100%',
      marginRight: '1rem',
      padding: '1rem',
      borderRadius: '1rem'

    },
    overviewContainer: {
      flex: 1,
      padding: '1.5rem 3rem 1.5rem 3rem',
      backgroundColor: 'white',
      boxShadow: '1px 1px 4px 0px #9E9E9E',
      height: '100%',
      marginLeft: '1rem',
      borderRadius: '1rem'
    },
    headerText: {
      textAlign: "center",
      fontSize: '20px',
      fontWeight: '700'
    },
    priceContainer: {
      display: "flex",
      justifyContent: 'space-between',
      margin: '0.5rem 3rem 0.5rem 3rem',
    },
    priceInnerContainer: {
      textAlign: 'center'
    },
    price: {
      fontSize: '24px',
      fontWeight: '500',
    },
    statusText: {
      fontSize: '12px',

    },
    veg_nonVegOuterContainer: {
      marginTop: '1.5rem'
    },
    veg_nonVegContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemContainer: {
      margin: '0.5rem',
      flex: 1,

    },
    veg_nonVegHeaderContainer: {
      display: "flex",
      alignItems: 'center',
    },
    veg_nonVegText: {
      fontWeight: '700',
      marginLeft: '0.5rem'
    }
  }
  const selectorhandler = () => {

  }
  return (
    <div>
      <Customer />
      <div style={styles.container}>
        <h2 style={styles.headerTextMain}> Overview</h2>
        <div style={styles.activeContainer}>
          {/* current status 1*/}
          <div style={styles.currentContainer}>
            <p style={styles.headerText}>Current Status</p>
            <div style={styles.priceContainer}>

              <div style={styles.priceInnerContainer}>
                <span style={styles.price}>34 </span>
                <div>
                  <span style={styles.statusText}>Active users</span>
                </div>
              </div>

              <div style={styles.priceInnerContainer}>
                <span style={styles.price}>30 </span>
                <div>
                  <span style={styles.statusText}>Total</span>
                </div>
              </div>

            </div>
          </div>
 {/* current status 2*/}
          {/* <div style={styles.overviewContainer}>
            <Selector selectorValue={selectorhandler} active={''} setActive={''} />
            <div style={styles.priceContainer}>

              <div style={styles.priceInnerContainer}>
                <span style={styles.price}>3 </span>
                <div>
                  <span style={styles.statusText}>Cancelled</span>
                </div>
              </div>

              <div style={styles.priceInnerContainer}>
                <span style={styles.price}>10 </span>
                <div>
                  <span style={styles.statusText}>Expired</span>
                </div>
              </div>

            </div>
          </div> */}

        </div>
        {/* Non - Vegeterian */}
        <div style={styles.veg_nonVegOuterContainer}>
          <div style={styles.veg_nonVegHeaderContainer}><Non_Veg /> <span style={styles.veg_nonVegText}>Non - Vegeterian</span></div>
          <div style={styles.veg_nonVegContainer}>
            <div style={styles.itemContainer}>
              <Mealtype status={'Active'} userStatus={'Currently Active Users'} />
            </div>
            <div style={styles.itemContainer}>
              <Mealtype status={'Paused'} userStatus={'Currently Paused Users'} />
            </div>
            <div style={styles.itemContainer}>
              <MealtypeWithSelector status={'Cancelled'} />
            </div >
            <div style={styles.itemContainer}>
              <MealtypeWithSelector status={'Expired'} />
            </div>
          </div>
        </div>

        {/* Vegeterian */}
        <div style={styles.veg_nonVegOuterContainer}>
          <div style={styles.veg_nonVegHeaderContainer}><Veg /> <span style={styles.veg_nonVegText}>Vegeterian</span></div>
          <div style={styles.veg_nonVegContainer}>
            <div style={styles.itemContainer}>
              <Mealtype status={'Active'} userStatus={'Currently Active Users'} />
            </div>
            <div style={styles.itemContainer}>
              <Mealtype status={'Paused'} userStatus={'Currently Paused Users'} />
            </div>
            <div style={styles.itemContainer}>
              <MealtypeWithSelector status={'Cancelled'} />
            </div >
            <div style={styles.itemContainer}>
              <MealtypeWithSelector status={'Expired'} />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Overview