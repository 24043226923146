import React from 'react'

function VegIcon({styles}) {
    return (
        <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
            <rect x="1.5" y="1.5" width="14" height="14" stroke="#04CC48" stroke-width="2" />
            <circle cx="8.5" cy="8.5" r="4" fill="#04CC48" />
        </svg>



    )
}

export default VegIcon