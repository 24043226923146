import { createSlice, current } from '@reduxjs/toolkit'
const AddonActiveData = [
    {
        id: 0,
        name: 'Chicken Biriyani',
        'price': 1000,
        category: 'Regular',
        status: true,
        checked: false
    },
    {
        id: 1,
        name: 'Dosa And Sambar',
        'price': 500,
        category: 'Diabetic',
        status: true,
        checked: false
    },
    {
        id: 2,
        name: 'Rice And Fish Curry',
        'price': 20,
        category: 'Regular',
        status: true,
        checked: false
    },
    {
        id: 3,
        name: 'Chicken Biriyani',
        'price': 1000,
        category: 'Regular',
        status: true,
        checked: false
    },
]
const addonInActiveData = [
    {
        id: 0,
        name: 'Rice ',
        'price': 20,
        category: 'Regular',
        status: false,
        checked: false
    },
    {
        id: 1,
        name: 'Chicken Biriyani',
        'price': 1000,
        category: 'Regular',
        status: false,
        checked: false
    },
    {
        id: 2,
        name: 'Porotta And Beef',
        'price': 500,
        category: 'Diabetic',
        status: false,
        checked: false
    },
    {
        id: 3,
        name: 'Biriyani',
        'price': 20,
        category: 'Regular',
        status: false,
        checked: false
    },
]
const productSlice = createSlice({
    name: "products",
    initialState: {
        addonActive: AddonActiveData,
        addonInActive: addonInActiveData
    },
    reducers: {
        addonActiveToInactive(state, action) {
            const newItem = action.payload.activeToInActive
            newItem.map((item) => {
                state.addonInActive.push({
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    name: item.name,
                    price: item.price,
                    category: item.category,
                    status: !item.status,
                    checked: item.checked
                })
            })
            state.addonActive = action.payload.active
            // console.log("activetoInactive :" + JSON.stringify(state.addonActive));
            // console.log("InactivetoActive: " + JSON.stringify(state.addonInActive));
        },
        addonInActiveToActive(state, action) {
            const newItem = action.payload.InactiveToActive
            newItem.map((item) => {
                state.addonActive.push({
                    id: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
                    name: item.name,
                    price: item.price,
                    category: item.category,
                    status: !item.status,
                    checked: item.checked
                })
            })
            state.addonInActive = action.payload.InActive
            // console.log("activetoInactive :" + JSON.stringify(state.addonActive));
            // console.log("InactivetoActive: " + JSON.stringify(state.addonInActive));
        }
    }
})
export const productAction = productSlice.actions
export default productSlice