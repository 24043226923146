import React from 'react'

function Non_Veg({styles}) {
    return (
        <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
            <rect x="1.5" y="1.5" width="14" height="14" stroke="#F76719" stroke-width="2" />
            <path d="M12.5 11.5H4.5L8.5 4.5L12.5 11.5Z" fill="#F76719" />
        </svg>

    )
}

export default Non_Veg