import React from 'react'

function More({onClick}) {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M1.5 1L5.5 5L9 1" stroke="#2F2C5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default More
