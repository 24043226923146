import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Button,Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../Colors";
import { RiArrowDropDownLine } from "react-icons/ri";
import Veg_Non_Veg from "../../../SVG/Veg_Non_Veg";
import VegIcon from "../../../SVG/VegIcon";
import Non_Veg from "../../../SVG/Non_Veg";
import { addonsAction } from "../../../store/addons";
import PrimaryButton from "../../UTIL/Buttons/PrimaryButton";
import InactiveButton from "../../UTIL/Buttons/InactiveButton";
import Add from "../../../SVG/Add";
import Delete from "../../../SVG/Delete";
import SearchContainer from "../../UTIL/SearchContainer";
import "./List.css";
import useTable from "../../Create/useTable";
import TableFooter from "../../Create/TableFooter";
import ImageModal from "./ImageModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../Service/service";
import DynamicFoodSelector from "./DynamicFoodSelector";
import { color } from "framer-motion";
function List() {
  const styles = {
    container: {
      flex: 1,
    },
    table: {
      border: "1px solid #E9E9E9",
      color: Colors.deepBlue100,
    },
    tableHead: {
      backgroundColor: Colors.yellow25,
    },
    tableBody: {
      backgroundColor: "white",
    },
    icon: {
      cursor: "pointer",
      height: "20px",
      width: "20px",
    },
    checkBoxContainer: {
      width: "250px",
      boxShadow: "0px 2px 2px #9E9E9E",
      fontSize: "12px",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-around",
      position: "absolute",
      backgroundColor: "#FFFCEB",
    },
    image: {
      height: "64px",
      width: "64px",
      borderRadius: "8px",
    },
    modalContainer: {
      boxShadow: "0px 2px 2px #9E9E9E",
      fontSize: "12px",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-around",
      position: "absolute",
      backgroundColor: "white",
      border: `1px solid ${Colors.yellow100}`,
      borderRadius: 8,
    },
    modalItemContainer: {
      cursor: "pointer",
      margin: "4px",
    },
    innerContainer: {
      display: "flex",
      // justifyContent: 'space-around',
      flex: 1,
      // alignItems: 'center',
      height: "2.5rem",
      marginBottom: "1rem",
    },
    buttonsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    searchOuterContainer: {
      flex: 1,
      marginLeft: "3rem",
    },
  };

  const [checked, setChecked] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    image_url: "",
    vegType: "",
    type: "",
    ingredient: "",
    price: "",
    status: "",
  });
  //   const addonsList = useSelector((state) => state.addons.addonsList);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [addonsList, setAddonsList] = useState([]);
  const { slice, range } = useTable(addonsList, page, 3);
  const [quantities, setQuantities] = useState({});
  const dispatch = useDispatch();
  const [typeFilter, setTypeFilter] = useState("All");
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getfoodItems`);
      const data = await response.json();
      if (response.ok) {
        // Initialize quantities state with default values
        // const defaultQuantities = {};
        // data.forEach((item) => {
        //   defaultQuantities[item.id] = 0;
        // });
        // setQuantities(defaultQuantities);
        setAddonsList(data);
      } else {
        console.error("Error fetching data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const filteredAddonsList = typeFilter === "All" 
    ? addonsList 
    : addonsList.filter(item => item.type === typeFilter);

  const uniqueTypes = ["All", ...new Set(addonsList.map(item => item.type))];
  
  const checkedHandler = () => {
    setChecked(!checked);
    dispatch(addonsAction.allSelectHandler(checked));
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModalSubmit = async () => {
    try {
      const formattedData = {
        name: formData.name,
        imageUrl: formData.image_url,
        vegType: formData.vegType,
        type: formData.type,
        // ingredient: formData.ingredient,
        ingredient: "Not-avilable",
        price: formData.price,
        status: true,
        qty: "0",
      };

      const response = await fetch(`${BASE_URL}/addFoodItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        setModalShow(false);
        // onnu koode vilicahl mathi auto reload ayikollum
        fetchData();
      } else {
        toast.error("Error: " + data.message);
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };

  const handleOpenModal = () => {
    setFormData({
      name: "",
      image_url: "",
      vegType: "",
      type: "",
      ingredient: "",
      price: "",
      status: "",
    });
    setModalShow(true);
  };

  // const deleteHandler = () => {
  //   dispatch(addonsAction.deleteHandler());
  // };

  const [imageModalShow, setImageModalShow] = useState(false);

  const handleImageSelect = (imageUrl) => {
    // Use the selected image URL
    setFormData({ ...formData, image_url: imageUrl });
    console.log("tt", imageUrl);
    setImageModalShow(false);
  };
  const handleStatusToggle = async (index) => {
    try {
      const updatedAddonsList = [...addonsList];
      updatedAddonsList[index].status = !updatedAddonsList[index].status;

      const response = await fetch(
        `${BASE_URL}/addons/${updatedAddonsList[index].id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: updatedAddonsList[index].status }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        toast.success(data.message);
        setAddonsList(updatedAddonsList);
      } else {
        toast.error("Error: " + data.message);
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };
  // increment quantity
  const handleIncrement = async (id) => {
    try {
      // Make API call to increment quantity
      const response = await fetch(`${BASE_URL}/increment/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: id }),
      });
      const data = await response.json();
      if (response.ok) {
        // Update quantities state
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [id]: prevQuantities[id] + 1,
        }));
      } else {
        console.error("Error incrementing quantity:", data.message);
      }
    } catch (error) {
      console.error("Error incrementing quantity:", error.message);
    }
    fetchData();
  };

  //  quantity decrement
  const handleDecrement = async (id) => {
    try {
      // Make API call to decrement quantity
      const response = await fetch(`${BASE_URL}/decrement/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: id }),
      });
      const data = await response.json();
      if (response.ok) {
        // Update quantities state
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [id]: Math.max(0, prevQuantities[id] - 1),
        }));
      } else {
        console.error("Error decrementing quantity:", data.message);
      }
    } catch (error) {
      console.error("Error decrementing quantity:", error.message);
    }
    fetchData();
  };

  // deletion
  const handleDeleteSelected = async () => {
    try {
      // Make API call to delete selected items
      const response = await Promise.all(
        selectedItems.map(async (id) => {
          const deleteResponse = await fetch(
            `${BASE_URL}/deleteFoodItem/${id}`,
            {
              method: "DELETE",
            }
          );
          return deleteResponse.json();
        })
      );

      // Handle response
      response.forEach((res) => {
        if (res.message) {
          toast.success(res.message);
        } else {
          toast.error("Failed to delete some items");
        }
      });

      // Refresh data
      fetchData();
      // Clear selected items
      setSelectedItems([]);
    } catch (error) {
      console.error("Error deleting items:", error.message);
      toast.error("Error deleting items");
    }
  };
  const toggleSelection = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleTypeChange = (type) => {
    setFormData({ ...formData, type: type, name: "" });
  };

  const handleNameChange = (name) => {
    setFormData({ ...formData, name: name });
  };
  return (
    <div style={{ flex: 1, minHeight: "100vh" }}>
      <ToastContainer />
      {/* Modal */}
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Food Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="image_url">
              <Form.Label>Image</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "10px" }}>
                  <img
                    src={formData.image_url}
                    style={{
                      height: "70%",
                      width: "70%",
                      borderRadius: "8px",
                      marginRight: "10px",
                    }}
                    alt="Preview"
                  />
                </div>
              </div>
            </Form.Group>

            <Button variant="success" onClick={() => setImageModalShow(true)}>
              Upload image
            </Button>

            <DynamicFoodSelector
              onTypeChange={handleTypeChange}
              onNameChange={handleNameChange}
            />

            {/* <Form.Group controlId="ingredient">
              <Form.Label>Ingredient</Form.Label>
              <Form.Control
                type="text"
                name="ingredient"
                value={formData.ingredient}
                onChange={handleFormChange}
              />
            </Form.Group> */}
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={formData.price}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Button variant="success" onClick={handleModalSubmit} style={{marginTop:16}}>
              Submit
            </Button>

            <ImageModal
              show={imageModalShow}
              onHide={() => setImageModalShow(false)}
              onSelectImage={handleImageSelect}
            />
          </Form>
        </Modal.Body>
      </Modal>
      {/* Buttons and Search */}
      <div style={{ display: "flex", height: "2.5rem", marginBottom: "1rem" }}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <PrimaryButton
            text={"Add new"}
            icon={<Add />}
            onClick={handleOpenModal}
          />
          <InactiveButton
            text={"Delete"}
            icon={<Delete />}
            onClick={handleDeleteSelected}
          />
        </div>
        {/* <div style={{ flex: 1, marginLeft: "3rem" }}>
          <SearchContainer search={search} handleChange={handleChange} />
        </div> */}
      </div>

      {/* Table */}
      <Table style={{ border: "1px solid #E9E9E9", color: "#1F3A68" }}>
        <thead style={{ backgroundColor: "#FFFAE5" }}>
          <tr>
            <th style={headerStyle}><input type="checkbox" /></th>
            <th style={headerStyle}>Image</th>
            <th style={headerStyle}>Price</th>
            <th style={headerStyle}>Name</th>
            <th style={headerStyle}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Type
                <select 
                  value={typeFilter} 
                  onChange={handleTypeFilterChange}
                  style={{
                    marginLeft: '10px',
                    padding: '2px 5px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    fontSize: '14px'
                  }}
                >
                  {uniqueTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </th>
            <th style={headerStyle}>Quantity</th>
            <th style={headerStyle}>Status</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "white" }}>
          {filteredAddonsList.map((item, index) => (
            <tr key={index}>
              <td valign="middle">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(item.id)}
                  onChange={() => toggleSelection(item.id)}
                />
              </td>
              <td valign="middle">
                <img
                  src={item.image_url}
                  style={{
                    height: "64px",
                    width: "64px",
                    borderRadius: "8px",
                  }}
                  alt="Food"
                />
              </td>
              <td valign="middle">₹{item.price}</td>
              <td valign="middle">{item.name}</td>
              <td valign="middle">{item.type}</td>
              <td valign="middle">
                <button
                  onClick={() => handleDecrement(item.id)}
                  style={buttonStyle}
                >
                  -
                </button>
                <span style={{ margin: "0 5px" }}> {item.qty}</span>
                <button
                  onClick={() => handleIncrement(item.id)}
                  style={buttonStyle}
                >
                  +
                </button>
              </td>
              <td valign="middle">
                <button
                  onClick={() => handleStatusToggle(index)}
                  style={{
                    backgroundColor: item.status ? "green" : "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                >
                  {item.status ? "Enable" : "Disable"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} /> */}
    </div>
  );
}

export default List;

const headerStyle = {
  // color: "white",
  // backgroundColor:Colors.deepBlue100
  background: Colors.LinearGradient,
};


const buttonStyle = {
  padding: '2px 8px',
  margin: '0 2px',
  backgroundColor: '#f0f0f0',
  border: '1px solid #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
  color:"black"
};