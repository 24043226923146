import React, { useState, useEffect } from 'react';
import { Colors } from '../../Colors';
import { BASE_URL } from '../../Service/service';


const routes = [
  "Ambalamukku", "Anayara", "Attukal", "Aakkulam", "AyurvedaCollege", "BakeryJunction",
  "BapujiNagar", "Chacka", "Eanchakkal", "GeneralHospital", "Kaithamukku", "Kannamoola",
  "Karamana", "Keshavadasapuram", "Kowdiar", "Kumarapuram", "Kunnukuzhy", "Kuravankonam",
  "Manacaud", "MedicalCollege", "Museum", "Muttada", "Nalanchara", "Overbridge", "Palayam",
  "Palkulangara", "Parattokonnam", "Patom", "Pattor", "Perrurkada", "Pettah", "Pmg",
  "Pongummoodu", "Poojapura", "Pothancode", "Shasthamangalam", "Statue", "Thampanoor", "Ulloor"
];

const DeliveryCustomerDashboard = () => {
  const [deliveryUsers, setDeliveryUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [newUser, setNewUser] = useState({ 
    delivery_user: '', 
    user_status: true, 
    route_name: { route: [] }
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchDeliveryUsers();
    fetchAdminUsers();
  }, []);

  const fetchDeliveryUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/delivery-users`);
      const data = await response.json();
      setDeliveryUsers(data);
    } catch (error) {
      console.error('Error fetching delivery users:', error);
      setError('Failed to fetch delivery users. Please try again.');
    }
  };

  const fetchAdminUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users`);
      const data = await response.json();
      const filteredAdmins = data.filter(user => user.user_info === "Admin");
      setAdminUsers(filteredAdmins);
    } catch (error) {
      console.error('Error fetching admin users:', error);
      setError('Failed to fetch admin users. Please try again.');
    }
  };

  const addDeliveryUser = async () => {
    if (!newUser.delivery_user) {
      setError('Delivery username is required.');
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/delivery-users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUser)
      });
      if (response.ok) {
        fetchDeliveryUsers();
        setNewUser({ delivery_user: '', user_status: true, route_name: { route: [] } });
        setError('');
      }
    } catch (error) {
      console.error('Error adding delivery user:', error);
      setError('Failed to add delivery user. Please try again.');
    }
  };

  const deleteDeliveryUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await fetch(`${BASE_URL}/delivery-users/${id}`, {
          method: 'DELETE'
        });
        if (response.ok) {
          fetchDeliveryUsers();
        }
      } catch (error) {
        console.error('Error deleting delivery user:', error);
        setError('Failed to delete delivery user. Please try again.');
      }
    }
  };

  const handleRouteChange = (route) => {
    setNewUser(prev => ({
      ...prev,
      route_name: { 
        route: prev.route_name.route.includes(route)
          ? prev.route_name.route.filter(r => r !== route)
          : [...prev.route_name.route, route]
      }
    }));
  };


  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '40px 20px',
      minHeight: '100vh',
    },
    header: {
        textAlign: "center",
        marginBottom: "20px",
    },
    card: {
      border: 'none',
      borderRadius: '12px',
      padding: '30px',
      marginBottom: '30px',
      backgroundColor: '#fff',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    },
    cardTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#34495e',
      borderBottom: '2px solid #2F2C5D',
      paddingBottom: '10px',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '20px',
      border: '2px solid #bdc3c7',
      borderRadius: '6px',
      fontSize: '16px',
      transition: 'border-color 0.3s',
    },
    routesContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gap: '10px',
      maxHeight: '300px',
      overflowY: 'auto',
      padding: '10px',
      border: '2px solid #bdc3c7',
      borderRadius: '6px',
      marginBottom: '20px',
    },
    routeBox: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      border: '1px solid #bdc3c7',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'all 0.3s',
    },
    routeBoxSelected: {
      backgroundColor: Colors.yellow10,
      color: 'black',
      border: '1px solid #3498db',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end', 
      marginTop: '20px', 
    },
    button: {
      width: '20%',
      padding: '12px',
      backgroundColor: Colors.yellow100,
      color: "black",
      border: 'none',
      borderRadius: '6px',
      fontSize: '18px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    deleteButton: {
      padding: '5px 10px',
      borderRadius: '20px',
      backgroundColor: '#e74c3c',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    userCard: {
      border: 'none',
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    },
    userHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '15px',
    },
    userName: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#2c3e50',
    },
    userStatus: {
      fontWeight: 'bold',
      padding: '5px 10px',
      borderRadius: '20px',
    },
    activeStatus: {
      backgroundColor: '#2ecc71',
      color: 'white',
    },
    inactiveStatus: {
      backgroundColor: '#e74c3c',
      color: 'white',
    },
    userRoutes: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '15px',
    },
    userRoute: {
      padding: '5px 10px',
      backgroundColor: '#ecf0f1',
      borderRadius: '20px',
      fontSize: '14px',
    },
    errorMessage: {
      color: '#e74c3c',
      marginBottom: '15px',
      fontWeight: 'bold',
    },
    select: {
      width: '100%',
      padding: '12px',
      marginBottom: '20px',
      border: '2px solid #bdc3c7',
      borderRadius: '6px',
      fontSize: '16px',
      transition: 'border-color 0.3s',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Delivery User Dashboard</h1>
      
      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Add New Dlivery User</h2>
        {error && <p style={styles.errorMessage}>{error}</p>}
        <select
          value={newUser.delivery_user}
          onChange={(e) => setNewUser({ ...newUser, delivery_user: e.target.value })}
          style={styles.select}
          required
        >
          <option value="">Select an delivery user uid</option>
          {adminUsers.map(user => (
            <option key={user.id} value={user.uid}>
              {user.uid}
            </option>
          ))}
        </select>
        <div style={styles.routesContainer}>
          {routes.map(route => (
            <div
              key={route}
              onClick={() => handleRouteChange(route)}
              style={{
                ...styles.routeBox,
                ...(newUser.route_name.route.includes(route) ? styles.routeBoxSelected : {})
              }}
            >
              {route}
            </div>
          ))}
        </div>
        <div style={styles.buttonWrapper}>
        <button onClick={addDeliveryUser} style={styles.button}>
          Add Delivery User
        </button>
      </div>
      </div>

      <h2 style={styles.cardTitle}>Delivery Users</h2>
      {deliveryUsers.map(user => (
        <div key={user.id} style={styles.userCard}>
          <div style={styles.userHeader}>
            <span style={styles.userName}>{user.delivery_user}</span>
            <div>
              <span style={{
                ...styles.userStatus,
                ...(user.user_status ? styles.activeStatus : styles.inactiveStatus)
              }}>
                {user.user_status ? 'Active' : 'Inactive'}
              </span>
              <button 
                onClick={() => deleteDeliveryUser(user.id)} 
                style={{...styles.deleteButton, marginLeft: '10px'}}
              >
                Delete
              </button>
            </div>
          </div>
          <div style={styles.userRoutes}>
            {Array.isArray(user.route_name.route) && user.route_name.route.map(route => (
              <span key={route} style={styles.userRoute}>{route}</span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeliveryCustomerDashboard;