import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { Colors } from '../../Colors'

function SearchContainer({search,handleChange}) {
    const styles = {
        searchOuterContainer: {
            flexDirection: 'row',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `1px solid ${Colors.yellow100}`,
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            width: '100%',
            height:'2.5rem'
        },


        searchIconContainer: {
            margin: '0 0.5rem 0 0.5rem',
            width: '5%'
        },

        searchInput: {
            height: '2.4rem',
            boxSizing: 'border-box',
            paddingLeft: '1.5rem',
            border: 'none',
            borderRadius: '0.5rem 0 0 0.5rem',
            outlineStyle: 'none',
            width: '99%'

        },
    }
    // const [search, setSearch] = useState('')
    const [active, setActive] = useState(false)
    // const handleChange = (e) => {
    //     setSearch(e.target.value)

    // }
    const focusHandler = () => {
        setActive(true)
    }
    return (
        <div style={{ ...styles.searchOuterContainer, ...{ backgroundColor: active ? Colors.yellow100 : 'white' } }}>
            <div style={{ width: '95%' }}>
                <input type="text" placeholder='search...' style={styles.searchInput} value={search} onChange={handleChange} onFocus={focusHandler} onBlur={() => setActive(false)} />
            </div>
            <div style={styles.searchIconContainer} >
                <BsSearch />
            </div>
        </div>



    )
}

export default SearchContainer