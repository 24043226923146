import React from "react";
import "./Activeorders.css";
import Regular from "./../../SVG/Regular";
import Healthy from "./../../SVG/Healthy";
import Diabetic from "./../../SVG/Diabetic";
function ActiveCard({ mealTime, count, data }) {
  var basicCount = 0;
  var regularCount = 0;
  var healthyCount = 0;
  var regularItem = "";
  var basicItem = "";
  var healthyItem = "";

  data?.forEach((order) => {
    if (order.menutype === "Regular") {
      regularCount += order.numberOfMeals;
      regularItem = order.item;
    }
    if (order.menutype === "Basic") {
      basicCount += order.numberOfMeals;
      basicItem = order.item;
    }
    if (order.menutype === "Healthy") {
      healthyCount += order.numberOfMeals;
      healthyItem = order.item;
    }
  });

  return (
    <div className="active_card">
      <div className="active_card1">
        <div style={{ fontWeight: 700 }}>{mealTime}</div>
        <div className="text_bold">{count}</div>
      </div>
      <hr></hr>
      <div className="cardMainContainer">
        <div>
          <div className="active_category">
            <div>
              <Regular
                styles={{ height: "15px ", width: "15px", marginRight: "6px" }}
              />
            </div>
            <div>Basic</div>
          </div>
          <div className="active_item">
            {basicItem !== "" ? (
              <div>{basicItem}</div>
            ) : (
              <div className="noOrder">No Orders for Today</div>
            )}
          </div>
        </div>
        <div className="active_card3">
          {basicCount !== 0 ? (
            <div className="text_bold">{basicCount}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <hr></hr>
      <div className="cardMainContainer">
        <div>
          <div className="active_category">
            <div>
              <Healthy
                styles={{ height: "15px ", width: "15px", marginRight: "6px" }}
              />
            </div>
            <div>Regular</div>
          </div>
          <div className="active_item">
            {regularItem !== "" ? (
              <div>{regularItem}</div>
            ) : (
              <div className="noOrder">No Orders for Today</div>
            )}
          </div>
        </div>
        <div className="active_card3">
          {regularCount !== 0 ? (
            <div className="text_bold">{regularCount}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <hr></hr>
      <div className="cardMainContainer">
        <div>
          <div className="active_category">
            <div>
              <Diabetic
                styles={{ height: "15px ", width: "15px", marginRight: "6px" }}
              />
            </div>
            <div>Healthy</div>
          </div>
          <div className="active_item">
            {healthyItem !== "" ? (
              <div>{healthyItem}</div>
            ) : (
              <div className="noOrder">No Orders for Today</div>
            )}
          </div>
        </div>
        <div className="active_card3">
          {healthyCount !== 0 ? (
            <div className="text_bold">{healthyCount}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ActiveCard;
