import React from 'react'
import { useTable,useSortBy } from "react-table";
import data from './UsersData.json';
import columns from './Columns';
import TableImg from '../../SVG/TableImg';

function Table()
{
   const ReactTable =  useTable({
    columns,
    data,
    initialState: {
        sortBy: [
            {
                id: 'name',
                desc: false
            }
        ]
    }
  },useSortBy)

       const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
      } = ReactTable;


      return (
<div className='table_cont'>



       <table className='users-table' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted
                      ? column.isSortedDesc
                        ? <TableImg/>
                        : <span>▲</span>
                      : ""
                  }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
      )
}

export default Table;


