import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Colors } from '../../Colors'
import AddonsActiveToday from '../Tables/Addons/AddonsActiveToday'
import AddonsActiveTomorrow from '../Tables/Addons/AddonsTomorrowActive'
import ActiveAddonsTomorrow from './ActiveAddonsTomorrow'
import AddonsTab from './AddonsTab'



function ActiveAddons() {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        },
        innerContainer: {
            display: 'flex',
            border: `1px solid ${Colors.yellow100}`,
            color: '#2F2C5D',
            borderRadius: '1rem',


        },
        itemStyle: {
            // padding: '0.5rem',
            padding: '1rem 4rem 1rem 4rem',
            flex: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
        },
        itemText: {
            fontSize: "70%",
            fontWeight: 700
        },
        outerContainer: {
            padding: '2rem'
        }



    }

    const [active, setActive] = useState(0)
    const [borderRadius, setBorderRadius] = useState('')
    const SelectorItems = ['Today', 'Tomorrow']
    const clickHandler = (index) => {

        for (var i = 0; i < SelectorItems.length; i++) {
            if (i === index) {
                setActive(i)
            }
        }
    }
    useEffect(() => {
        if (active === 0) {
            setBorderRadius('1rem 0 0 1rem')

        }
        if (active === 1) {
            setBorderRadius('0 1rem 1rem 0')

        }
    }, [active])
    const content = () => {
        if (active === 0) {
            return <AddonsActiveToday />
        }
        if (active === 1) {
            return <ActiveAddonsTomorrow />
        }
    }
    return (
        <div>
            <AddonsTab />
            <div style={styles.outerContainer}>
                <div style={styles.container}>
                    <div style={styles.innerContainer}>
                        {SelectorItems.map((item, index) => (
                            <div
                                key={index}
                                style={{ ...styles.itemStyle, ...{ background: active === index ? Colors.LinearGradient : "", borderRadius: borderRadius } }}
                                onClick={() => clickHandler(index)}>
                                <span style={styles.itemText}>
                                    {item}
                                </span>
                            </div>
                        ))}
                    </div>

                </div>
                {content()}
            </div>
        </div>
    )
}

export default ActiveAddons