import React from 'react'
import { NavLink, } from 'react-router-dom'
import { Colors } from '../../../Colors'

function Customer() {
    const styles = {
        customer_main_container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: 0,
            zIndex: 999,
            width: '100%',
            backgroundColor: Colors.yellow2,
        },

        customer_header_container: {
            flex: 1,
            borderBottom: '1px solid rgb(230, 221, 221)'
        },

        headerText: {
            margin: '1.6rem',
        },

        customer_body_container: {
            flex: 4,

        },

        customer_header_item: {
            display: 'flex',
            width: '40%',
            justifyContent: 'space-around',
            marginBottom: "0.5rem",
        },
        active_class: {
            textDecoration: 'none',
            color: '#2F2C5D',
            background: `linear-gradient(#FAC105, #FFD814)`,
            borderRadius: '0.2rem',
            padding: '0.5rem 1rem 0.5rem  1rem',
            fontWeight: 500,
        },

        non_active_class: {
            textDecoration: 'none',
            color: '#2F2C5D',
            fontWeight: 500
        }
    }
    return (
        <div style={styles.customer_main_container}>
            <div style={styles.customer_header_container}>
                <h3 style={styles.headerText}>Customers</h3>
                <div style={styles.customer_header_item}>
                    {/* <div><NavLink style={({ isActive }) => isActive ? styles.active_class : styles.non_active_class} to="/customer/overview">Overview</NavLink></div>
                    <div><NavLink style={({ isActive }) => isActive ? styles.active_class : styles.non_active_class} to="/customer/cancelled-expired">Cancelled/Expired</NavLink></div>
                    <div><NavLink style={({ isActive }) => isActive ? styles.active_class : styles.non_active_class} to="/customer/new">New</NavLink></div>
                    <div><NavLink style={({ isActive }) => isActive ? styles.active_class : styles.non_active_class} to="/customer/all">All</NavLink></div> */}
                </div>
            </div>
            <div style={styles.customer_body_container}></div>
        </div>
    )
}

export default Customer