import React from 'react';

const SkeletonLoader = () => {
  return (
    <div style={styles.cardContainer}>
      <div style={styles.imagePlaceholder}></div>
      <div style={styles.textContainer}>
        <div style={styles.mealTypeRow}>
          <div style={styles.mealTypeHeadPlaceholder}></div>
          <div style={styles.mealPricePlaceholder}></div>
        </div>
        <div style={styles.mealTypePlaceholder}></div>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
    height: '450px',
    border: '1px solid #e0e0e0',
  },
  imagePlaceholder: {
    width: '100%',
    height: '300px',
    borderRadius: '15px',
    marginBottom: '20px',
    backgroundColor: '#f0f0f0',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mealTypeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  mealTypeHeadPlaceholder: {
    width: '60%',
    height: '24px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  mealPricePlaceholder: {
    width: '20%',
    height: '24px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  mealTypePlaceholder: {
    width: '80%',
    height: '18px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    animation: 'pulse 1.5s infinite ease-in-out',
  },
  '@keyframes pulse': {
    '0%': { opacity: 0.6 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.6 },
  },
};

export default SkeletonLoader;