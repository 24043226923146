import React from "react";
import Sidebar from "./../Components/Sidebar/Sidebar";
import Activeorders from './../Components/Active orders/Activeorders';

const DashboardHome = ({leftPanel,rightPanel}) => {
  const containerStyle = {
    display: "grid",
    gridTemplateColumns: "15% 85%",
    height: "100vh",
  };

  const leftPanelStyle = {
    // backgroundColor: "red",
    height: "100%",
    overflowY: "auto", 
  };

  const rightPanelStyle = {
    // backgroundColor: "yellow",
    height: "100%",
    overflowY: "auto", 
  };

  return (
    <div style={containerStyle}>
      <div style={leftPanelStyle}>
      {leftPanel}
        {/* <Sidebar /> */}
      </div>
      <div style={rightPanelStyle}>
        {/* <Activeorders /> */}
        {rightPanel}
      </div>
    </div>
  );
};

export default DashboardHome;


// import React from "react";
// import HomeTab from './HomeTab';
// import Activeorders from './../Components/Active orders/Activeorders';
// import Sidebar from "./../Components/Sidebar/Sidebar";


// const DashboardHome= () => {
//   return (
//     <HomeTab
//       leftPanel={<Sidebar/>} 
//       rightPanel={<Activeorders />} 
//     />
//   );
// };

// export default DashboardHome;
