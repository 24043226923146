import React from 'react';

const CustomConfirmDialog = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div style={styles.dialogContainer}>
      <div style={styles.dialog}>
        <h2>{title}</h2>
        <p>{message}</p>
        <div style={styles.buttons}>
          <button style={styles.confirmButton} onClick={onConfirm}>Yes</button>
          <button style={styles.cancelButton} onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  dialogContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    backgroundColor: 'red',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
    transition: 'background-color 0.3s ease',
  },
  cancelButton: {
    backgroundColor: 'green',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default CustomConfirmDialog;
