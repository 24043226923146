import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { AiOutlineSortAscending } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Colors } from '../../../Colors'
import CheckBoxSort from '../../../SVG/CheckBoxSort'

function ExpireInThreeDays() {
  const styles = {
    container: {

    },
    table: {
      border: '1px solid #E9E9E9',
      color: Colors.deepBlue100
    },
    tableHead: {
      backgroundColor: Colors.yellow25,
    },
    tableBody: {
      backgroundColor: 'white',
    },
    icon: {
      cursor: "pointer"
    },
    checkBoxContainer: {
      width: '250px',
      boxShadow: '0px 2px 2px #9E9E9E',
      fontSize: '12px',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-around',
      position: 'absolute',
      backgroundColor: '#FFFCEB',
    }
  }
  const tableData = useSelector(state => state.customer.customerExpireInThreeDays)
  const CATEGORIES = ["Regular", 'Diabetic', 'Healthy']
  const [data, setData] = useState([...tableData])
  const [page, setPage] = useState(1);
  const [Cmodal, setCModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [sort, setSort] = useState(false)
  const [state, setState] = useState({
    productsItem: data,
    filters: new Set(),
  })
  const checkedHandler = () => {
    setChecked(!checked)
    const newData = data.map((item) =>
      item ? { ...item, checked: !checked } : item
    )
    setData(newData)
  }
  const checkedOneHandler = (id) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item)
    setData(newData)
  }
  const sortNameHandler = () => {
    setSort(!sort)
  }
  useEffect(() => {
    const sortedArray = data.sort(function (a, b) {
      const x = a.name;
      const y = b.name;
      return sort ? (x > y ? 1 : -1) : (x < y ? 1 : -1)
    }).map(prod => {
      return prod
    })
    setData(sortedArray)
  }, [sort])
  const handleFilterChange = useCallback(event => {
    setState(previousState => {
      let filters = new Set(previousState.filters)
      let productsItem = data

      if (event.target.checked) {
        filters.add(event.target.value)

      } else {
        filters.delete(event.target.value)
      }

      if (filters.size) {
        productsItem = data.filter(product => {
          return product.plans.some((tag) => filters.has(tag))
        })
      }

      return {
        filters,
        productsItem,
      }
    })

  }, [setState])
  const checkBoxModalHandler = () => {
    setCModal(!Cmodal)
  }
  useEffect(() => {
    setData(state.productsItem)
  }, [state.filters, state.productsItem])
  return (
    <div style={styles.container}>
      <Table style={styles.table}>
        <thead style={styles.tableHead}>
          <tr>
            <th>
              <input type="checkbox" checked={checked} onChange={checkedHandler} />
            </th>
            <th>Phone
            </th>
            <th>Name
              <AiOutlineSortAscending style={styles.icon} onClick={sortNameHandler} />
            </th>
            <th>Plans
              <CheckBoxSort style={styles.icon} onClick={() => checkBoxModalHandler()} />
              {Cmodal && <div style={styles.checkBoxContainer}>
                {CATEGORIES.map(category => (
                  <label>
                    <input
                      onChange={handleFilterChange}
                      type="checkbox"
                      value={category} />
                    {category}
                  </label>
                ))}
              </div>}
            </th>
            <th>Join Date</th>
            <th>Expired Date</th>
          </tr>
        </thead>
        <tbody style={styles.tableBody}>
          {data.map((item, id) => (
            <tr key={id}>
              <td><input type="checkbox" checked={item.checked} onChange={() => checkedOneHandler(id)} /></td>
              <td>{item.phone}</td>
              <td>{item.name}</td>
              <td>{item.plans.map(u => <><span>{u}</span><br /></>)}</td>
              <td>{item.joinDate}</td>
              <td>{item.expireDate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ExpireInThreeDays