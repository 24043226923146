import React from 'react'

function Archive() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.41333 3.33333L3.95333 2.66667H11.9533L12.58 3.33333M8 11.6667L4.33333 8H6.66667V6.66667H9.33333V8H11.6667L8 11.6667ZM13.6933 3.48667L12.7667 2.36667C12.5867 2.14 12.3133 2 12 2H4C3.68667 2 3.41333 2.14 3.22667 2.36667L2.30667 3.48667C2.11333 3.71333 2 4 2 4.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V4.33333C14 4 13.8867 3.71333 13.6933 3.48667Z" fill="#A3A3A3" />
        </svg>

    )
}

export default Archive