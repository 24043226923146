


import React from 'react'
import Home from './Home/Home'
import NavigationBar from './NavigationBar'
// import Sidebar from './Components/Sidebar/Sidebar';

const Main = () => {
  return (
    <div>
      <Home/>
    </div>
  )
}

export default Main