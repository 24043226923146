// import React from "react";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   Image,
// } from "@react-pdf/renderer";

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     backgroundColor: "#ffffff",
//     padding: 30,
//   },
//   header: {
//     fontSize: 40,
//     marginBottom: 20,
//     textAlign: "right",
//     color: "#000000",
//   },
//   companyDetails: {
//     fontSize: 10,
//     marginBottom: 20,
//   },
//   invoiceInfo: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginBottom: 20,
//   },
//   addressBlock: {
//     marginBottom: 20,
//   },
//   addressTitle: {
//     fontSize: 12,
//     fontWeight: "bold",
//     marginBottom: 5,
//   },
//   addressText: {
//     fontSize: 10,
//   },
//   table: {
//     display: "table",
//     width: "auto",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderRightWidth: 0,
//     borderBottomWidth: 0,
//   },
//   tableRow: {
//     margin: "auto",
//     flexDirection: "row",
//   },
//   tableColHeader: {
//     width: "25%",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//     backgroundColor: "#f0f0f0",
//   },
//   tableCol: {
//     width: "25%",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//   },
//   tableCell: {
//     margin: "auto",
//     marginTop: 5,
//     marginBottom: 5,
//     fontSize: 10,
//   },
//   totals: {
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     marginTop: 20,
//   },
//   totalsLabel: {
//     width: "25%",
//     textAlign: "right",
//     paddingRight: 8,
//     fontSize: 10,
//   },
//   totalsValue: {
//     width: "25%",
//     textAlign: "right",
//     fontSize: 10,
//   },
//   footer: {
//     position: "absolute",
//     bottom: 30,
//     left: 30,
//     right: 30,
//     textAlign: "center",
//     fontSize: 10,
//     color: "#4a90e2",
//   },
//   totalsContainer: {
//     marginTop: 30,
//     borderTop: 1,
//     borderColor: '#000000',
//   },
//   totalsRow: {
//     flexDirection: 'row',
//     justifyContent: 'flex-end',
//     marginTop: 5,
//   },
//   totalsLabel: {
//     width: '25%',
//     textAlign: 'right',
//     paddingRight: 8,
//     fontSize: 10,
//     fontWeight: 'bold',
//   },
//   totalsValue: {
//     width: '25%',
//     textAlign: 'right',
//     fontSize: 10,
//   },
//   grandTotal: {
//     marginTop: 10,
//     borderTop: 1,
//     borderBottom: 1,
//     borderColor: '#000000',
//     paddingTop: 5,
//     paddingBottom: 5,
//   },
//   grandTotalLabel: {
//     width: '25%',
//     textAlign: 'right',
//     paddingRight: 8,
//     fontSize: 12,
//     fontWeight: 'bold',
//   },
//   grandTotalValue: {
//     width: '25%',
//     textAlign: 'right',
//     fontSize: 12,
//     fontWeight: 'bold',
//   },
//   footer: {
//     position: "absolute",
//     bottom: 30,
//     left: 30,
//     right: 30,
//     textAlign: "center",
//     fontSize: 10,
//     color: "#4a90e2",
//   },
// });

// const InvoicePDF = ({ invoiceData }) => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.header}>
//         <Text>INVOICE</Text>
//       </View>
      
//       <View style={styles.companyDetails}>
//         <Text>My Lalas</Text>
//         <Text>Address: Nallumuku, Pettah, Thiruvananthapuram, Kerala 695024</Text>
//         <Text>Phone: +91 7994544423</Text>
//         <Text>Email: LalasKitchenTVM@gmail.com</Text>
//       </View>

//       <View style={styles.invoiceInfo}>
//         <Text>Invoice No: {invoiceData.orderid}</Text>
//         <Text>Order Date: {invoiceData.currenttime}</Text>
//       </View>

//       <View style={styles.addressBlock}>
//         <Text style={styles.addressTitle}>Bill to</Text>
//         <Text style={styles.addressText}>{invoiceData.userdata.first_name}</Text>
//         <Text style={styles.addressText}>Phone: {invoiceData.userdata.phone_number}</Text>
//       </View>

//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <View style={styles.tableColHeader}>
//             <Text style={styles.tableCell}>Item Name</Text>
//           </View>
//           <View style={styles.tableColHeader}>
//             <Text style={styles.tableCell}>Qty</Text>
//           </View>
//           <View style={styles.tableColHeader}>
//             <Text style={styles.tableCell}>Unit Price</Text>
//           </View>
//           <View style={styles.tableColHeader}>
//             <Text style={styles.tableCell}>Total</Text>
//           </View>
//         </View>
//         {invoiceData.types.map((item, index) => (
//           <View style={styles.tableRow} key={index}>
//             <View style={styles.tableCol}>
//               <Text style={styles.tableCell}>{item.name}</Text>
//             </View>
//             <View style={styles.tableCol}>
//               <Text style={styles.tableCell}>{item.quantity}</Text>
//             </View>
//             <View style={styles.tableCol}>
//               <Text style={styles.tableCell}>₹{item.price}</Text>
//             </View>
//             <View style={styles.tableCol}>
//               <Text style={styles.tableCell}>₹{item.quantity * item.price}</Text>
//             </View>
//           </View>
//         ))}
//       </View>

//       <View style={styles.totalsContainer}>
//         <View style={styles.totalsRow}>
//           <Text style={styles.totalsLabel}>Subtotal:</Text>
//           <Text style={styles.totalsValue}>₹{invoiceData.subtotal}</Text>
//         </View>
//         <View style={styles.totalsRow}>
//           <Text style={styles.totalsLabel}>Delivery Charge:</Text>
//           <Text style={styles.totalsValue}>₹{invoiceData.deliverycharge}</Text>
//         </View>
//         <View style={styles.totalsRow}>
//           <Text style={styles.totalsLabel}>GST (5%):</Text>
//           <Text style={styles.totalsValue}>₹{invoiceData.gst}</Text>
//         </View>
//         <View style={[styles.totalsRow, styles.grandTotal]}>
//           <Text style={styles.grandTotalLabel}>Total:</Text>
//           <Text style={styles.grandTotalValue}>₹{invoiceData.totalpricepaid}</Text>
//         </View>
//       </View>

//       <View style={styles.footer}>
//         <Text>Thank You for Your Business!</Text>
//         <Text>{new Date().toLocaleDateString()}</Text>
//       </View>
//     </Page>
//   </Document>
// );

// export default InvoicePDF;





import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 30,
  },
  header: {
    fontSize: 24,
    textAlign: "center",
    color: "#000000",
    marginTop:20,
    textDecoration: 'underline',
  },
  companyDetails: {
    fontSize: 10,
    marginBottom: 16,
    textAlign: "center",
  },
  invoiceInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    fontSize: 12,
  },
  addressBlock: {
    marginBottom: 16,
  },
  addressTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  addressText: {
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#7e1378",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
 tableCellHeader: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    color:"white"
  },
  totalsContainer: {
    marginTop: 30,
    borderTop: 1,
    borderColor: '#000000',
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
  totalsLabel: {
    width: '25%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 10,
    fontWeight: 'bold',
  },
  totalsValue: {
    width: '25%',
    textAlign: 'right',
    fontSize: 10,
  },
  grandTotal: {
    marginTop: 10,
    borderTop: 1,
    borderBottom: 1,
    borderColor: '#000000',
    paddingTop: 5,
    paddingBottom: 5,
  },
  grandTotalLabel: {
    width: '25%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 12,
    fontWeight: 'bold',
  },
  grandTotalValue: {
    width: '25%',
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 'bold',
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#7e1378",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
    height: 100,
  },
  companyName: {
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom:8
},
authorizedSignatory: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 100,
    fontSize: 10,
    fontWeight: 'bold',
  },

});

const InvoicePDF = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.image} src="https://i.postimg.cc/ZqchknWV/logorazorpay.jpg" />
      <View style={styles.companyDetails}>
      <Text style={styles.companyName}>Lala's Kitchen</Text>
        <Text>Fssai 1132101000848, Pettah, Trivandrum</Text>
        <Text>Phone no.: 9895757524, 7994544423</Text>
        <Text>Email: lalaskitchen.in@gmail.com</Text>
        <View style={styles.header}>
        <Text>INVOICE</Text>
      </View>
      </View>

      <View style={styles.invoiceInfo}>
        <View>
          <Text>Invoice No: {invoiceData.orderid}</Text>
        </View>
        <View>
          <Text>Order Date: {invoiceData.currenttime}</Text>
        </View>
      </View>

      <View style={styles.addressBlock}>
        <Text style={styles.addressTitle}>Bill to</Text>
        <Text style={styles.addressText}>Name : {invoiceData.userdata.first_name}</Text>
        <Text style={styles.addressText}>Contact No : {invoiceData.userdata.phone_number}</Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Item Name</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Qty</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Unit Price</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Total</Text>
          </View>
        </View>
        {invoiceData.types.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.quantity}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.price}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.quantity * item.price}</Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.totalsContainer}>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>Subtotal:</Text>
          <Text style={styles.totalsValue}>{invoiceData.subtotal}</Text>
        </View>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>Delivery Charge:</Text>
          <Text style={styles.totalsValue}>{invoiceData.deliverycharge}</Text>
        </View>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsLabel}>GST (5%):</Text>
          <Text style={styles.totalsValue}>{invoiceData.gst}</Text>
        </View>
        <View style={[styles.totalsRow, styles.grandTotal]}>
          <Text style={styles.grandTotalLabel}>Total:</Text>
          <Text style={styles.grandTotalValue}>{invoiceData.totalpricepaid}/-</Text>
        </View>
      </View>
      <View style={styles.authorizedSignatory}>
        <Text>Authorized Signatory</Text>
      </View>
      <View style={styles.footer}>
        <Text>Thank You for Your Business!</Text>
        <Text>{new Date().toLocaleDateString()}</Text>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;
