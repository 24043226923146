import React, { useState, useEffect } from "react";
import axios from "axios";
import { Printer } from "lucide-react";
import { BASE_URL } from "../../Service/service";



const AddonContainerPayment = () => {
  const [addonData, setAddonData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchAddonData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/delivery`);
        setAddonData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchAddonData();
  }, []);

  const filteredData = addonData.filter((order) => {
    if (!startDate && !endDate) return true;

    const orderDate = new Date(order.deliverydata.currenttime);
    if (startDate && !endDate) return orderDate >= startDate;
    if (!startDate && endDate) return orderDate <= endDate;
    return orderDate >= startDate && orderDate <= endDate;
  });

  const totalAmount = filteredData.reduce(
    (sum, order) => sum + parseFloat(order.deliverydata.totalpricepaid),
    0
  );

  const handlePrint = () => {
    window.print();
  };

  const handleStartDateChange = (e) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    setStartDate(date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    setEndDate(date);
  };

  if (loading)
    return <div style={styles.loadingText}>Loading addon data...</div>;
  if (error)
    return (
      <div style={styles.errorText}>Error loading addon data: {error}</div>
    );

  return (
    <div style={styles.container}>
      {/* <h1 style={styles.mainHeader}>Addons</h1> */}
      <div style={styles.controlsContainer} className="no-print">
        <div style={styles.dateCard}>
          <h3 style={styles.dateHeader}>Start Date</h3>
          <input
            type="date"
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={handleStartDateChange}
            style={styles.dateInput}
          />
        </div>

        <div style={styles.dateCard}>
          <h3 style={styles.dateHeader}>End Date </h3>
          <input
            type="date"
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={handleEndDateChange}
            style={styles.dateInput}
          />
        </div>

        <button onClick={handlePrint} style={styles.printButton}>
          <Printer style={styles.printIcon} />
          Print Report
        </button>
      </div>

      <div style={styles.tableContainer} id="printTable">
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.tableHeader}>Order ID</th>
              <th style={styles.tableHeader}>Order Date</th>
              <th style={styles.tableHeader}>Customer Name</th>
              <th style={styles.tableHeader}>Phone Number</th>
              <th style={styles.tableHeader}>Delivery Time</th>
              <th style={styles.tableHeader}>Items</th>
              <th style={styles.tableHeader}>Delivery Charge</th>
              <th style={styles.tableHeader}>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((order) => (
              <tr key={order.deliverydata.orderid} style={styles.tableRow}>
                <td style={styles.tableCell}>{order.deliverydata.orderid}</td>
                <td style={styles.tableCell}>
                  {new Date(
                    order.deliverydata.currenttime
                  ).toLocaleDateString()}
                </td>
                <td style={styles.tableCell}>
                  {order.deliverydata.userdata.first_name}
                </td>
                <td style={styles.tableCell}>
                  {order.deliverydata.userdata.phone_number}
                </td>
                <td style={styles.tableCell}>
                  {order.deliverydata.deliverytime}
                </td>
                <td style={styles.tableCell}>
                  <ul style={styles.itemList}>
                    {order.deliverydata.types.map((item, index) => (
                      <li key={index} style={styles.itemListItem}>
                        {item.name} x{item.quantity} - ₹{item.price}
                      </li>
                    ))}
                  </ul>
                </td>
                <td style={styles.tableCell}>
                  ₹{order.deliverydata.deliverycharge}
                </td>
                <td style={styles.tableCell}>
                  ₹{order.deliverydata.totalpricepaid}
                </td>
              </tr>
            ))}
            <tr style={styles.totalRow}>
              <td colSpan="6" style={styles.totalCell}>
              Total Amount with GST:
              </td>
              <td style={styles.totalCell}>₹{totalAmount}/-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>{`
        @media print {
          .no-print {
            display: none !important;
          }
          body {
            padding: 0;
            margin: 0;
          }
          #printTable {
            width: 100%;
            margin: 0;
            padding: 0;
          }
          @page {
            size: landscape;
            margin: 20mm;
          }
        }
      `}</style>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#ffffff",
  },
  controlsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    marginBottom: "24px",
  },
  dateCard: {
    padding: "16px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  dateHeader: {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "8px",
  },
  mainHeader: {
    // fontSize: "14px",
    fontWeight: "500",
    marginBottom: "8px",
  },
  dateInput: {
    padding: "8px",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
  },
  printButton: {
    height: "40px",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FAC105",
    color: "black",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
  },
  printIcon: {
    marginRight: "8px",
    width: "16px",
    height: "16px",
  },
  tableContainer: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#ffffff",
  },
  tableHeader: {
    backgroundColor: "#FAC105",
    color: "black",
    padding: "12px",
    textAlign: "left",
    fontWeight: "500",
  },
  tableRow: {
    borderBottom: "1px solid #e2e8f0",
  },
  tableCell: {
    padding: "12px",
    borderBottom: "1px solid #e2e8f0",
  },
  itemList: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  itemListItem: {
    marginBottom: "4px",
  },
  totalRow: {
    backgroundColor: "#f8fafc",
  },
  totalCell: {
    padding: "12px",
    fontWeight: "bold",
  },
  loadingText: {
    padding: "20px",
    textAlign: "center",
    fontSize: "16px",
    color: "#666",
  },
  errorText: {
    padding: "20px",
    textAlign: "center",
    color: "#ef4444",
    fontSize: "16px",
  },
};

export default AddonContainerPayment;
